import { lazy } from 'react'

export { default as Login } from './Login'
export { default as RecoverPassword } from './RecoverPassword'
export { default as ResetPassword } from './ResetPassword'

export const Home = lazy(() => import('./Home'))
export const Commits =  lazy(() => import('./Commits'))
export const Repos = lazy(() => import('./Repos'))
export const Members = lazy(() => import('./Members'))
export const MemberDetails = lazy(() => import('./MemberDetails'))
export const NewMember = lazy(() => import('./NewMember'))
export const Projects = lazy(() => import('./Projects'))
export const NewProject = lazy(() => import('./NewProject'))
export const ProjectDetails = lazy(() => import('./ProjectDetails'))
export const Orgs = lazy(() => import('./Orgs'))
export const OrgDetails = lazy(() => import('./OrgDetails'))
export const Ranking  =lazy(() => import('./Ranking'))
export const SyncedProfiles = lazy(() => import('./SyncedProfiles'))
export const ProductOwnerDetails = lazy(() => import('./ProductOwnerDetails'))
