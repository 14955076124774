import { AiFillHome as HomeIcon } from 'react-icons/ai'
// import { IoGitCommitSharp  as CommitsIcons } from 'react-icons/io5'
import { RiGitRepositoryCommitsFill as ReposIcon } from 'react-icons/ri'
import { IoIosPeople as MembersIcon } from 'react-icons/io'
import { GiRank3 as RankIcon } from 'react-icons/gi'
import {
    GrProjects as ProjectsIcon,
    GrOrganization as OrgsIcon,
    GrConfigure as ConfigsIcon,
    GrUserAdmin as AdminIcon
} from 'react-icons/gr'

import * as S from './styles'

export function Navigation () {
    return (
        <S.Navigation>
            <S.NavItemsWrapper>
                <S.NavItem>
                    <S.NavLink to='/'>
                        <HomeIcon size={20} /> Home
                    </S.NavLink>
                </S.NavItem>
                {/* <S.NavItem>
                    <S.NavLink to='/commits'><CommitsIcons size={20} /> Commits</S.NavLink>
                    </S.NavItem>
                */}
                <S.NavItem>
                    <S.NavLink to='/repos'><ReposIcon size={20} />Repositórios</S.NavLink>
                </S.NavItem>
                <S.NavItem>
                    <S.NavLink to='/members'><MembersIcon size={20} />Membros</S.NavLink>
                </S.NavItem>
                <S.NavItem>
                    <S.NavLink to='projects'><ProjectsIcon />Projetos</S.NavLink>
                </S.NavItem>
                <S.NavItem>
                    <S.NavLink to='orgs'><OrgsIcon size={19} />Organizações</S.NavLink>
                </S.NavItem>
                <S.NavItem>
                    <S.NavLink to='/config'><ConfigsIcon size={20}/>Perfis Sincronizados</S.NavLink>
                </S.NavItem>
                <S.NavItem>
                    <S.NavLink to='/ranking'><RankIcon size={20} />Ranking</S.NavLink>
                </S.NavItem>
                <S.NavItem>
                    <S.NavLink to='/admin'><AdminIcon size={20} />Administrador</S.NavLink>
                </S.NavItem>
            </S.NavItemsWrapper>
        </S.Navigation>
    )
}
