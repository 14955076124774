import  styled  from 'styled-components'

import { transparentize } from 'polished'

import { NavLink as NavLinkRR } from 'react-router-dom'

export const Navigation = styled.nav``

export const NavItemsWrapper = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
`

export const NavItem = styled.li``

export const NavLink = styled(NavLinkRR)`
    width: 100%;
    border-radius: 14px;
    padding: 1.2rem;
    font-weight: ${({ theme }) => theme.font.weights.medium};
    display: flex;
    gap: 1.2rem;
    align-items: center;
    font-size: 1.4rem;
    text-decoration: none;
    color: ${({ theme }) => transparentize(0.2, theme.colors.black)};

    transition: 400ms color, 400ms background-color, 400ms stroke;
    will-change: color, background-color, stroke;

    &:hover {
        transition: 400ms color, 400ms background-color, 400ms stroke;
        background-color: ${({ theme }) => (
            transparentize(0.95, theme.colors.brand.blue)
        )};

        color: ${({ theme }) => theme.colors.black};

        svg {
            transition: color 400ms;
            color: ${({ theme }) => theme.colors.brand.blue};
        }

        path {
            transition: 400ms stroke;
            stroke: ${({ theme }) => theme.colors.brand.blue};
        }
    }

    &.active {
        background-color: ${({ theme }) => (
            transparentize(0.93, theme.colors.brand.blue)
        )};

        color: ${({ theme }) => theme.colors.black};

        svg {
            color: ${({ theme }) => theme.colors.brand.blue};
        }

        path {
            stroke: ${({ theme }) => theme.colors.brand.blue};
        }
    }
`


