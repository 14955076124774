import api from "@/api";

import { SortFieldsMembers, SortFieldsProjects, SortFieldsRepos } from "@/hooks";

import {
    CreateProjectData,
    PaginationResponseData,
    Project,
    UpdateProjectData,
    MemberProjects,
    PercentsPropsResponseData,
} from "@/resources/types";

type GetProjectsResponseData = {
    projects: Project[];
} & PaginationResponseData;

export const ProjectsService = {
    async getProjects(
        name = "",
        order: SortFieldsProjects = { updated_at: 'desc' },
        page = 1,
        limit = 10,
    ): Promise<GetProjectsResponseData> {
        const nameParam = name ? `&name=${name}` : "";
        const orderParam = `&order=${JSON.stringify(order)}`;

        const {
            data: { data, ...pagination },
        } = await api.get(
            `/projects?page=${page}&limit=${limit}${nameParam}${orderParam}`,
        );

        return { projects: data, ...pagination };
    },

    async getProjectById(
        id: string,
        orderMembers: SortFieldsMembers = { active: 'asc' },
        orderRepos: SortFieldsRepos = { pushed_at: "asc" },
    ): Promise<Project> {
        const {
            data: { percents, ...data },
        } = await api.get(`/projects/${id}`, {
            params: {
                orderMembers,
                orderRepos: {
                    repositories: orderRepos,
                },
            },
        });

        return {
            ...data,
            repositories: data.repositories.map(
                (repo: PercentsPropsResponseData) => ({
                    ...repo,
                    percents: {
                        feat: repo.percentFeat ?? 0,
                        fix: repo.percentFix ?? 0,
                        refactor: repo.percentRefactor ?? 0,
                        chore: repo.percentChore ?? 0,
                        conventional: repo.percentConv ?? 0,
                        others: repo.percentOther ?? 0,
                    },
                }),
            ),
            percents: {
                feat: percents.percentFeat ?? 0,
                fix: percents.percentFix ?? 0,
                refactor: percents.percentRefactor ?? 0,
                chore: percents.percentChore ?? 0,
                conventional: percents.percentConv ?? 0,
                others: percents.percentOther ?? 0,
            },
        };
    },

    async createProject(data: CreateProjectData) {
        await api.post("/projects", { ...data, description: "" });
    },

    async updateProject(data: UpdateProjectData): Promise<void> {
        const { id, ...body } = data;
        await api.patch(`/projects/${id}`, body);
    },

    async getProjectsByOrgId(
        orgId: string,
        page: number,
        limit: number,
        order: SortFieldsProjects = { updated_at: 'desc' }
    ): Promise<{ projects: Project[]; pagination: PaginationResponseData }> {
        const {
            data: { data, ...pagination },
        } = await api.get("/projects", {
            params: {
                organizationId: orgId,
                page,
                limit,
                order
            },
        });

        return { projects: data, pagination };
    },

    async getProjectsByMemberId(id: string): Promise<MemberProjects[]> {
        const { data } = await api.get(`/projects/project/${id}`);

        return data;
    },
};
