import { styled, css } from "@/lib"

import { CardProps } from "."

export const Container = styled('article')`
    width: 100%;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 3.2rem;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    position: relative;
    border: 1px solid ${({ theme }) => theme.colors.gray[100]};
`

export const Header = styled('header')``

type ContentProps = Pick<CardProps, 'contentScrollX'>

export const Content = styled('div')<ContentProps>`
    height: 100%;

    ${({ contentScrollX }) => css`
        ${!!contentScrollX && css`
            overflow-y: auto;
        `}
    `}
`

export const Footer = styled('footer')`
    display: flex;
    align-items: end;
    justify-content: end;
    flex: 1;
`
