import { useAuth, useSidebarDrawer } from "@/contexts";

import { AiOutlineMenu as MenuIcon } from 'react-icons/ai'

import ContentLoader from 'react-content-loader'

import { LogoV2, Container, Button, ProfileName } from "@/components";

import * as S from './styles'

function UserNameContentLoader() {
    return (
        <ContentLoader
            speed={2}
            width={100}
            height={30}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="3" y="1" rx="3" ry="3" width="100" height="30" />
        </ContentLoader>
    )
}

export function Header() {
    const { handleOpen } = useSidebarDrawer()

    const { user, logout } = useAuth()

    const handleLogout = async () => {
        await logout()
    }

    return (
        <S.HeaderWrapper>
            <Container>
                <S.ContainerContent>
                    <S.BoxLeft>
                        <S.MenuIconWrapper onClick={handleOpen}>
                            <MenuIcon size={24} />
                        </S.MenuIconWrapper>
                            <LogoV2 />

                        {/* <S.LogoWrapper>
                            <LogoV2 />
                        </S.LogoWrapper> */}
                    </S.BoxLeft>

                    <S.ActionsWrapper>
                        {user
                            ? (
                                <ProfileName name={user.name} />
                            )
                            : <UserNameContentLoader />
                        }
                        <S.ButtonWrapper>
                            <Button size='small' fullWidth onClick={handleLogout}>Sair</Button>
                        </S.ButtonWrapper>
                    </S.ActionsWrapper>
                </S.ContainerContent>
            </Container>
        </S.HeaderWrapper>
    )
}
