import { Button } from '@/components'

import { To, useNavigate } from 'react-router-dom'

import { IoChevronBack} from 'react-icons/io5'

type BackButtonProps = {
    to?: To
    title?: string
}

export function BackButton ({ to, ...props }: BackButtonProps) {
    const navigate = useNavigate()

    const handleBack = () => {
        if (typeof to === 'undefined') {
            navigate(-1)
            return
        }
        navigate(to)
    }

    return (
        <Button {...props} onClick={handleBack} size='small'>
            <IoChevronBack size={16} />
        </Button>
    )
}
