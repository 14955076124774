import { ChangeEvent, useState } from "react"

import { useQuery } from "@tanstack/react-query"

import { useDebounce } from "@/hooks"

import { MembersService } from "@/services"

export function useSearchProductOwnerByName () {
    const [productOwnerName, setProductOwnerName] = useState('')
    const productOwnerNameDebounced = useDebounce(productOwnerName)

    const result = useQuery(
        ['searchedProductOwner', productOwnerNameDebounced],
        async () => MembersService.getProductOwners(productOwnerNameDebounced),
        {
            enabled: !!productOwnerNameDebounced,
            refetchOnWindowFocus: false
        }
    )

    const handleProductOwnerNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setProductOwnerName(e.target.value)
    }

    const cleanProductOwnerName = () => setProductOwnerName('')

    return {
        isLoadingSearchProductOwner: result.isLoading && !!productOwnerNameDebounced,
        resultProductOwners: result.data?.productOwners,
        productOwnerName,
        handleProductOwnerNameChange,
        cleanProductOwnerName
    }
}
