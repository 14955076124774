import { useTheme } from 'styled-components'

import { useConfig } from '@/hooks'

import {
    Button,
    Typography,
    Box,
    Stack,
    DataTable,
    EmptyState,
    Spinner,
    Alert,
} from '@/components'

import { BsGithub as GithubIcon} from 'react-icons/bs'
import { IoLogoBitbucket as BitBucketIcon } from 'react-icons/io'
import { AiFillGitlab as GitLabIcon } from 'react-icons/ai'
// import { SiAzuredevops as AzureIcon } from 'react-icons/si'

import { getProfileColumns } from './columns'
import { ExpandedComponentProfile } from './ExpandedComponentProfile'

export default function SyncedProfiles () {
    const {
        isLoading,
        isSynchronizeGithubLoading,
        isSynchronizeBitBucketLoading,
        isSynchronizeGitLabLoading,
        // isSynchronizeAzureLoading,
        isSynchronizeSuccess,
        isTogglingStatus,
        errorMessage,
        profiles,
        pagination,
        handlePageChange,
        handleRowsPerPage,
        handleCloseAlert,
        profileSelectedId,
        handleToggleStatus,
        handleRequestAuthorizeGithub,
        handleRequestAuthorizeBitBucket,
        handleRequestGitLabAuthorize,
        // handleRequestAzureAuthorize,
        loadingStartPayloadInitial,
        handleStartPayloadInitial
    } = useConfig()

    const { colors } = useTheme()

    const profileColumns = getProfileColumns({
        isTogglingStatus,
        profileSelectedId,
        onToggleStatus: handleToggleStatus,
        loadingStartPayloadInitial,
        onStartPayloadInitial: handleStartPayloadInitial
    })

    return (
        <Box bgcolor='white' p={6} borderRadius='8px' border="1px solid" borderColor='gray.100'>
            <Stack flexDirection={['column', 'row']} justifyContent='space-between' mb={8} gap={8}>
                <Typography tag='h3' fontSize={18}>Perfis sincronizados</Typography>
                <Stack direction='row' gap={2} maxWidth='100%' overflow='scroll' pb={2} alignItems='center'>
                    <Typography fontWeight='medium' whiteSpace='nowrap'>Sincronizar com: </Typography>
                    <Button
                        backgroundColor={colors.gray[900]}
                        onClick={handleRequestAuthorizeGithub}
                        loading={isSynchronizeGithubLoading}
                        disabled={isSynchronizeGithubLoading}
                    >
                        Github <GithubIcon size={22} />
                    </Button>
                    <Button
                        title='Sincronizar com o BitBucket'
                        onClick={handleRequestAuthorizeBitBucket}
                        loading={isSynchronizeBitBucketLoading}
                        disabled={isSynchronizeBitBucketLoading}
                    >
                        Bitbucket <BitBucketIcon size={22} />
                    </Button>
                    <Button
                        title='Sincronizar com o GitLab'
                        onClick={handleRequestGitLabAuthorize}
                        backgroundColor={colors.brand.orangeRed}
                        loading={isSynchronizeGitLabLoading}
                        disabled={isSynchronizeGitLabLoading}
                    >
                        GitLab<GitLabIcon size={20} />
                    </Button>

                    {/* <Button
                        title='Sincronizar com o AzureDevOps'
                        loading={isSynchronizeAzureLoading}
                        disabled={isSynchronizeAzureLoading}
                        onClick={handleRequestAzureAuthorize}
                    >
                        <AzureIcon /> AzureDevOps
                    </Button> */}
                </Stack>
            </Stack>

            <Box mb={4}>
                <Alert variant='error' show={!!errorMessage} onClose={handleCloseAlert}>{errorMessage}</Alert>
                <Alert variant='success' show={isSynchronizeSuccess} onClose={handleCloseAlert}>
                    Perfil sincronizado com sucesso!
                </Alert>
            </Box>

             <DataTable
                columns={profileColumns}
                data={profiles || []}
                progressPending={isLoading}
                pagination
                paginationPerPage={pagination?.perPage}
                paginationTotalRows={pagination?.count}
                onChangePage={page => handlePageChange({ selected: page})}
                onChangeRowsPerPage={handleRowsPerPage}
                noDataComponent={<EmptyState description='Não há perfis sincronizados... ainda' />}
                progressComponent={<Spinner size='4.6rem' />}
                expandableRows
                expandableRowsComponent={ExpandedComponentProfile}
            />
        </Box>
    )
}
