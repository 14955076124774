import { useLocation, useNavigate } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { useTheme } from "styled-components";

import { useForm } from "react-hook-form";

import * as z from 'zod'

import { zodResolver } from '@hookform/resolvers/zod'

import { AuthService } from "@/services";

import { toast } from "react-toastify";

import {
    Logo,
    Button,
    Input,
    Typography,
    Stack,
    Box
} from "@/components";

const schema = z.object({
    password: z.string().min(6, { message: 'Senha deve conter no mínimo 6 caracteres.'}),
    confirmPassword: z.string().min(1, { message: 'A senha de confirmação é obrigatória.'})
}).refine((data) => data.password === data.confirmPassword, {
    message: 'As senhas não correspondem.',
    path: ['confirmPassword']
})

type FormData = z.infer<typeof schema>

export default function ResetPassword() {
    const { container } = useTheme()

    const navigate = useNavigate()

    const { search } = useLocation()

    const querys = new URLSearchParams(search)

    const token = querys.get('token')

    const mutation = useMutation(['@reset-password'], AuthService.resetPassword, {
        onError() {
            toast.error('Código Inválido.')
        },
        onSuccess(){
            navigate('/login')
            toast.success('Senha alterada com sucesso.')
        }
    })

    const { register, handleSubmit, formState: { errors } } = useForm<FormData>({
        resolver: zodResolver(schema)
    })

    const resetPasswordSubmit = async (values: FormData) => {
        if(!token) {
            return toast.error('Código Inválido')
        }
        await mutation.mutateAsync({ token, password: values.password })
    }

    return (
        <Stack alignItems='center' justifyContent='center'>
            <Box maxWidth={container} width='100%'>
                <Stack
                    alignItems='center'
                    justifyContent={['space-evenly', 'space-around']}
                    direction={{ xs: 'column', md: 'row' }}
                    height='100vh'
                    p={6}
                >
                    <Stack alignItems='center' justifyContent='center'>
                        <Logo size='huge' />
                    </Stack>
                    <Stack
                        onSubmit={handleSubmit(resetPasswordSubmit)}
                        component='form'
                        justifyContent='space-evenly'
                        gap={8}
                        maxWidth={420}
                        width='100%'
                        bgcolor='white'
                        p={8}
                        borderRadius='10px'
                    >
                        <Typography tag='h3' fontSize='2xl'>Recuperar Senha</Typography>

                        <Stack gap={4}>
                            <Input type='password' placeholder="Nova senha" {...register('password')} />
                            <Typography component='p'fontSize='xs' color='orangeRed'>
                                {errors.password?.message}
                            </Typography>

                            <Input type='password' placeholder="Confirmar senha" {...register('confirmPassword')} />
                            <Typography component='p'fontSize='xs' color='orangeRed'>
                                {errors.confirmPassword?.message}
                            </Typography>
                        </Stack>
                        <Stack alignItems='center'>
                            <Button
                                fullWidth
                                type="submit"
                                size='medium'
                                loading={mutation.isLoading}
                                disabled={mutation.isLoading}
                            >
                                Alterar senha
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        </Stack>
    );
}
