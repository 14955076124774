import { ReactNode } from 'react'

import ReactModal from 'react-modal'

ReactModal.setAppElement('#root')

export type ModalBaseProps = {
    children: ReactNode
    isOpen: boolean
    onRequestClose: () => void
}

export function ModalBase ({ children, isOpen, onRequestClose }: ModalBaseProps) {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName='react-modal-overlay'
            className='react-modal-content'
        >
            {children}
        </ReactModal>
    )
}
