import api from "@/api";

import { UserLogin } from "@/resources";

import { AxiosError } from "axios";

import { AuthError, makeDebounce } from "@/utils";

type LoginResponseData = {
    token: string;
};

type ResetPasswordProps = {
    token: string;
    password: string;
};

const debounce = makeDebounce(300);

export const AuthService = {
    async login({ email, password }: UserLogin): Promise<LoginResponseData> {
        const res = await api.post("/auth/login", { email, password });
        const token = res.data.access_token;

        return { token };
    },

    async linkedInLogin(code: string): Promise<LoginResponseData> {
        return debounce(async () => {
            const res = await api.post(`/auth/linkedin`, { code });
            const token = res.data.access_token;

            return { token };
        });
    },

    async logout(): Promise<void> {
        localStorage.removeItem("tokenUser");
    },

    async forgotPassword(email: string) {
        let sent = "";

        try {
            const { data } = await api.post("/forgot-password", { email });
            sent = data.sent;
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response?.status === 400) {
                    const message = error.response?.data.message;
                    throw new AuthError(message);
                }

                throw new Error("");
            }
        }

        return { sent };
    },

    async resetPassword({ token, password }: ResetPasswordProps) {
        await api.patch(
            "/reset-password",
            { password },
            {
                params: { token },
            },
        );
    },
};
