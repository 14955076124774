import type { RankingOrder } from "@/hooks/use-ranking";

import api from "@/api";

import type {
    CalendarDays,
    PaginationResponseData,
    PercentsPropsResponseData,
    RankingMemberData,
    RankingProjectData,
    SelectedProjectDetailsData,
    SortFieldsRankingMembers,
    SortFieldsRankingProjects,
} from "@/resources/types";

export type RankingMembersResponseData = {
    ranking: RankingMemberData[];
    pagination: PaginationResponseData;
};

export type RankingProjectsResponseData = {
    ranking: RankingProjectData[];
    pagination: PaginationResponseData;
};

const getTransformedPercentsData = <
    T extends RankingMemberData | RankingProjectData,
>(
    data: PercentsPropsResponseData[],
) => {
    const transformedData = data.map(item => ({
        ...item,
        percents: {
            feat: item.percentFeat ?? 0,
            fix: item.percentFix ?? 0,
            refactor: item.percentRefactor ?? 0,
            chore: item.percentChore ?? 0,
            conventional: item.percentConv ?? 0,
            others: item.percentOther ?? 0,
        },
    }));

    return transformedData as T[];
};

export const RankingService = {
    async getRankingDevs(
        page: number,
        limit = 10,
        name = "",
        days: CalendarDays = "21",
        order: SortFieldsRankingMembers = { rank: "asc" },
    ): Promise<RankingMembersResponseData> {
        const {
            data: { data, ...pagination },
        } = await api.get("/ranking", {
            params: { page, limit, name, days, order: JSON.stringify(order) },
        });

        const rankingDevData =
            getTransformedPercentsData<RankingMemberData>(data);

        return { ranking: rankingDevData, pagination };
    },

    async getRankingDevOrder(
        order: RankingOrder,
        days: CalendarDays = "21",
    ): Promise<RankingMemberData[]> {
        const {
            data: { data },
        } = await api.get("/ranking/ranking_order", {
            params: { type: order, days },
        });

        return getTransformedPercentsData<RankingMemberData>(data);
    },

    async getRankingProjectsOrder(
        order: RankingOrder,
        days: CalendarDays = "21",
    ): Promise<RankingProjectData[]> {
        const {
            data: { data },
        } = await api.get("/ranking-projects/ranking_order", {
            params: { type: order, days },
        });

        return getTransformedPercentsData<RankingProjectData>(data);
    },

    async getRankingProjects(
        page: number,
        name = "",
        days: CalendarDays = "21",
        order: SortFieldsRankingProjects = { rank: "asc" },
    ): Promise<RankingProjectsResponseData> {
        const {
            data: { data, ...pagination },
        } = await api.get("/ranking-projects", {
            params: { page, name, days, order: JSON.stringify(order) },
        });

        const projects = data.map((project: PercentsPropsResponseData) => {
            return {
                ...project,
                totalMembers: 0,
                percents: {
                    feat: project.percentFeat ?? 0,
                    fix: project.percentFix ?? 0,
                    refactor: project.percentRefactor ?? 0,
                    chore: project.percentChore ?? 0,
                    conventional: project.percentConv ?? 0,
                    others: project.percentOther ?? 0,
                },
            };
        });

        return { ranking: projects, pagination };
    },

    async getRankingProjetDetails(
        id: string,
    ): Promise<SelectedProjectDetailsData> {
        const {
            data: { developers, PO },
        } = await api.get(`/ranking-projects/${id}`);

        return {
            totalMembers: developers.length + PO.length,
            developers: developers.map((dev: PercentsPropsResponseData) => ({
                ...dev,
                percents: {
                    feat: dev.percentFeat ?? 0,
                    fix: dev.percentFix ?? 0,
                    refactor: dev.percentRefactor ?? 0,
                    chore: dev.percentChore ?? 0,
                    conventional: dev.percentConv ?? 0,
                    others: dev.percentOther ?? 0,
                },
            })),
            pos: PO.map((po: PercentsPropsResponseData) => ({
                ...po,
                percents: {
                    feat: po.percentFeat ?? 0,
                    fix: po.percentFix ?? 0,
                    refactor: po.percentRefactor ?? 0,
                    chore: po.percentChore ?? 0,
                    conventional: po.percentConv ?? 0,
                    others: po.percentOther ?? 0,
                },
            })),
        };
    },

    async getRankingPOsOrder(
        order: RankingOrder,
        days: CalendarDays = "21",
    ): Promise<RankingMemberData[]> {
        const {
            data: { data },
        } = await api.get("/ranking-pos/ranking_order", {
            params: { type: order, days },
        });

        return getTransformedPercentsData<RankingMemberData>(data);
    },

    async getRankingPOs(
        page: number,
        name = "",
        days: CalendarDays = "21",
        order: SortFieldsRankingMembers = { rank: "asc" },
    ): Promise<RankingMembersResponseData> {
        const {
            data: { data, ...pagination },
        } = await api.get("/ranking-pos", {
            params: { page, name, days, order: JSON.stringify(order) },
        });

        const rankingProductOwnersData =
            getTransformedPercentsData<RankingMemberData>(data);

        return { ranking: rankingProductOwnersData, pagination };
    },
};
