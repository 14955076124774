import { styled, css } from '@/lib'

import type { AvatarProps } from '.'

type AvatarContainerProps = Pick<AvatarProps, 'size'>

const AvatarContainerModifiers = {
    small: () => css`
        width: 4.8rem;
        height: 4.8rem;
    `,

    medium: () => css`
        width: 6.4rem;
        height: 6.4rem;
    `,

    large: () => css`
        width: 9.6rem;
        height: 9.6rem;
    `,
}

export const AvatarContainer = styled('span')<AvatarContainerProps>`
    ${({ theme, size }) => css`
        border-radius: 9999px;
        background-color: ${theme.colors.brand.blue};
        color: ${theme.colors.black};
        font-weight: ${theme.font.weights.medium};

        display: flex;
        justify-content: center;
        align-items: center;

        ${!!size && AvatarContainerModifiers[size]()};

        overflow: hidden;

        img {
            display: block;
            max-width: 100%;
            object-fit: cover;
        }
    `}
`
