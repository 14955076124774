import { useNavigate, useParams } from "react-router-dom";

import { useSelectedOrgs, useSearchOrgByName } from "@/hooks";

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import { CommitsService, MembersService, ProjectsService } from "@/services";

import { OnUpdateMemberSubmitEvent } from "@/resources";
import { AxiosError } from "axios";

import { toast } from "react-toastify";

export function useMemberDetails () {
    const { id } = useParams<{ id :string }>()

    const { orgs, setOrgsData, handleSelectOrg, handleRemoveSelectedOrg } = useSelectedOrgs()

    const navigate = useNavigate()

    const queryClient = useQueryClient()

    const { data, status, fetchStatus } = useQuery(['member', id], async () => {
        if(!id) return

        const result = await MembersService.getMemberById(id)
        return result
    }, {
        onSuccess(data) {
            if(data?.organizations) {
                setOrgsData(data.organizations)
            }
        },
    })

    const { data: projects, isLoading: isMemberProjectsLoading } = useQuery(['memberProjects'], async () => {
            if(!id) return
            return ProjectsService.getProjectsByMemberId(id)
        }
    )

    const { data: commits, isLoading: isMemberCommitsLoading } = useQuery(['memberCommits'], async () => {
            if(!id) return
            return CommitsService.getCommitsByMemberId(id)
        },
        { enabled: data?.position === 'Desenvolvedor' }
    )

    const {
        orgName,
        researchedOrgData,
        handleOrgNameChange,
        isLoadingSearchOrg
    } = useSearchOrgByName()

    const { mutateAsync: updateMemberMutation, isLoading: isUpdating } = useMutation(
        MembersService.updateMember,
        {onSuccess: () => {
            queryClient.invalidateQueries(['member', id])
            toast.success('Membro atualizado com sucesso.')
        },
        onError(error) {
            if (error instanceof AxiosError) {
                toast.error(error.response?.data.message)
            }
        }}
    )

    const { mutateAsync: deleteMemberMutation, isLoading: isDeleting } = useMutation(
        MembersService.deleteMember,
        {onSuccess: () => {
            queryClient.invalidateQueries(['members'])
            navigate('/members')
            toast.success('Membro Deletado com sucesso.')
        },
        onError(error) {
            if (error instanceof AxiosError) {
                toast.error(error.response?.data.message)
            }
        }}
    )

    const handleDeleteMember = (id: string) => async () => {
        await deleteMemberMutation(id)
    }

    const handleUpdateMemberSubmit = async (e: OnUpdateMemberSubmitEvent) => {
        e.preventDefault()

        if(!id || !data) return

        const organizationIds = orgs.map(({ id }) => id)

        if(organizationIds.length === 0) {
            toast.error('Adicione ao menos uma organização')
            return
        }

        const { name, email, position, active } = e.currentTarget

        await updateMemberMutation({
            id,
            name: name.value,
            email: email.value,
            position: position.value,
            active: active.checked,
            login: data?.login,
            organizationIds
        })
    }

    return {
        status,
        fetchStatus,
        member: data,
        orgs,
        isUpdating,
        handleUpdateMemberSubmit,
        isDeleting,
        handleDeleteMember,
        handleSelectOrg,
        handleRemoveSelectedOrg,
        orgName,
        researchedOrgData,
        handleOrgNameChange,
        isLoadingSearchOrg,
        projects,
        isMemberProjectsLoading,
        commits,
        isMemberCommitsLoading
    }
}
