import { Fragment, ReactElement } from "react";

export function renderElements (count: number, element: ReactElement) {
    return new Array(count)
        .fill(null)
        .map((_, index) => `element-item-${index}` )
        .map((item) => (
            <Fragment key={item}>
                {element}
            </Fragment>
        ))
}
