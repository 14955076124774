import { styled } from "@/lib";

export const Wrapper = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
`

export const InputItem = styled('input')`
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.gray[100]};
    font-size: 14px;
    padding: 12px;
    border-radius: 12px;
    width: 100%;
    max-width: 100%;
`;

export const HideShowPassword = styled('div')`
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    margin-left: -3.8rem;
`
