import { forwardRef, HTMLAttributes } from 'react'

import { Avatar } from '@/components'

import * as S from './styles'

type ProfileNameProps = Omit<HTMLAttributes<HTMLButtonElement>, 'className'> & {
    name: string
}

export const ProfileName = forwardRef<HTMLButtonElement, ProfileNameProps>(({ name, ...props}, ref) => (
    <S.Wrapper {...props} ref={ref} title={name}>
        <Avatar name={name} />
        <S.Name>{name}</S.Name>
    </S.Wrapper>
))
