import { styled, css, keyframes } from '@/lib'

export const Overlay = styled('div')`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 30;

    display: flex;
    align-items: center;
    justify-content: center;
`

const loadingAnimate = keyframes`
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
`

export const Loading = styled('div')`
    ${({ theme }) => css`
        border: 6px solid ${theme.colors.gray[100]};
        border-radius: 50%;
        border-top: 6px solid ${theme.colors.brand.blue};
        width: 100px;
        height: 100px;
        animation: ${loadingAnimate} 0.8s linear infinite;
    `}
`
