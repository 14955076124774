import { useState, useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { usePagination, useSortCommits } from '@/hooks'
import { CommitsService } from '@/services'

export function useCommitsByRepoId (initialRepoId: string | undefined) {
    const {
        currentPage,
        rowsPerPage,
        handlePageChange,
        handleRowsPerPage
    } = usePagination(1)

    const [repoId, setRepoId] = useState(initialRepoId)

    const { sort, handleSort } = useSortCommits()

    const { data, status } = useQuery(['commitsRepo', repoId, currentPage, rowsPerPage, sort],
        async () => CommitsService.getCommitsByRepoId(repoId, currentPage, rowsPerPage, sort), {
        refetchOnWindowFocus: false,
        enabled: Boolean(repoId) || !!initialRepoId
    })

    const handleSelectRepoId = useCallback((repoId: string) => () => setRepoId(repoId) , [])

    return {
        statusCommits: status,
        commits: data?.commits,
        commitsPagination: data?.pagination,
        currentPage,
        handlePageChange,
        handleRowsPerPage,
        handleSelectRepoId,
        handleSort
    }
}
