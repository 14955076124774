import { theme } from '@/lib/theme'

import { CommitKeyPercent } from '@/resources/types/commit'

export const commitColor: Record<CommitKeyPercent, string> = {
    'feat': theme.colors.brand.blue,
    'refactor': theme.colors.brand.yellowMedium,
    'fix': theme.colors.brand.orangeRed,
    'chore': theme.colors.silver,
    'conventional': theme.colors.brand.green,
    'others': theme.colors.gray[700]
}
