import styled, { css } from 'styled-components'

import * as Primitive from '@radix-ui/react-switch'

type SwitchProps = {
    $isLoading?: boolean
}

const SwitchModifiers = {
    isLoading: () => css`
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.colors.gray[200]};
    `
}

export const Switch = styled(Primitive.Root)<SwitchProps>`
    ${({ theme, $isLoading }) => css`
        all: 'unset';
        border: 0;
        outline: 0;
        width: 5rem;
        height: 2.5rem;
        border-radius: 999px;
        position: relative;
        box-shadow: 0 2x 10px rgba(0, 0, 0, 0.14);

        &:focus {
            box-shadow: 0 0 2px ${theme.colors.black};
        }

        &[data-state="checked"]{
            background-color: ${theme.colors.brand.green};
            ${$isLoading && SwitchModifiers.isLoading()};
        }

        &[data-state="unchecked"]{
            background-color: ${theme.colors.brand.orangeRed};
            ${$isLoading && SwitchModifiers.isLoading()};
        }
    `}
`

export const Thumb = styled(Primitive.Thumb)`
    display: block;
    width: 21px;
    height: 21px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 100px;
    transition: transform 100ms;
    transform: translateX(2px);
    will-change: transform;

    &[data-state="checked"] {
        transform: translateX(26px);
    }
`


// import { styled, css } from '@/lib'

// import { SwitchProps } from '.'

// type SwitchContainerProps = Pick<SwitchProps, 'width' | 'height'>

// const SwitchModifiers = {
//     width: (value: number) => css`
//         width: ${`${value/10}rem`};
//     `,

//     height: (value: number) => css`
//         height: ${`${value/10}rem`};
//     `
// }

// export const Container = styled('label')<SwitchContainerProps>`
//     ${({ theme, width, height }) => css`
//         background-color: ${theme.colors.gray[50]};
//         border: 1px solid ${theme.colors.gray[200]};
//         border-radius: 100px;

//         width: 60px;
//         height: 30px;

//         ${!!width && SwitchModifiers.width(width)};
//         ${!!height && SwitchModifiers.height(height)};
//     `}

//     cursor: pointer;
//     display: inline-block;

//     overflow: hidden;

//     input {
//         display: none
//     }
// `

// type CircleProps = {
//     status: boolean
// }

// export const Circle = styled('span')<CircleProps>`
//     ${({ status, theme }) => css`
//         height: 100%;
//         display: flex;
//         align-items: center;

//         background-color: ${status ? theme.colors.brand.green : theme.colors.brand.orangeRed};
//         color: ${theme.colors.white};

//         transition: all 0.3s ease-in;

//         position: relative;

//         &::after {
//             content: '';
//             display: block;
//             width: 50%;
//             height: 100%;
//             position: absolute;
//             left: ${status ? '50%' : '0'};
//             background-color: white;
//             transition: all 0.3s ease-in;
//             border-radius: 9999px;
//         }
//     `}
// `
