import ContentLoader from 'react-content-loader'

import { Stack, Box } from '@/components'

import { defaultProps } from '../default-props'

type PaginationContentLoaderProps = {
    align?: 'left' | 'right'
}

export function PaginationContentLoader ({ align = 'left' }: PaginationContentLoaderProps) {
    return (
        <Stack
            width='100%'
            alignItems={align === 'left' ? 'flex-end' : 'flex-start'}
        >
            <Box width={470} height={30} mt={3}>
                <ContentLoader {...defaultProps} height={30}>
                    <rect x="0" y="0" rx="3" ry="3" width="470" height="30" />
                </ContentLoader>
            </Box>
        </Stack>
    )

}
