import { Stack, Box, Typography } from '@/components'
import { CommitKeyPercent } from '@/resources/types'

import {
    BsStars as FeatIcon,
    BsRecycle as RefactorIcon,
    BsHammer as ChoreIcon,
    BsBugFill as FixIcon,
    BsCheckLg as ConventionalIcon,
} from 'react-icons/bs'

import { RiCodeSSlashFill as OthersIcon } from 'react-icons/ri'

export type CardCommitProps = {
    name: CommitKeyPercent
    percent: number
    commits?: number
}

const commitIcon = {
    conventional: <ConventionalIcon size={24} />,
    feat: <FeatIcon size={24} />,
    refactor:  <RefactorIcon size={24} />,
    fix: <FixIcon size={24} />,
    chore: <ChoreIcon size={24} />,
    others: <OthersIcon size={24} />
}

const commitColor = {
    conventional: 'brand.green',
    feat: 'brand.blue',
    refactor: 'brand.yellowMedium',
    fix: 'brand.orangeRed',
    chore: 'silver',
    others: 'gray.700'
}

export function CardCommit ({ name, percent, commits }: CardCommitProps) {
    return (
        <Stack
            flex={1}
            padding={8}
            borderRadius={1}
            bgcolor={commitColor[name]}
            color='white'
            textAlign='center'
            fontWeight='medium'
            fontSize='md'
            justifyContent='center'
        >
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                gap={6}
                alignItems='center'
                justifyContent='center'
                textTransform='capitalize'
            >
                {commitIcon[name]}
                <Box>
                    <Typography tag='p'>{name}: {percent?.toFixed(2)}%</Typography>
                    {(commits || commits === 0) && <Typography tag='p'>Commits: {commits}</Typography>}
                </Box>
            </Stack>
        </Stack>
    )
}
