import { ChangeEvent, useState, useCallback } from "react"

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import {
    useDebounce,
    useUpdateEffect,
    usePagination,
    useFilterPlatform
} from "@/hooks"

import { OrgsService } from "@/services/orgs-service"

import { toast } from "react-toastify"

type Direction = 'asc' | 'desc'

type FieldName = {
    name: Direction
}

type FieldActive = {
    active: Direction
}

export type SortFieldsOrgs = FieldName | FieldActive

export function useOrgs () {
    const [isSearching, setIsSearching] = useState(false)

    const { currentPage, handlePageChange } = usePagination(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const [sort, setSort] = useState<SortFieldsOrgs>({ active: 'desc' })

    const [inputSearch, setInputSearch] = useState('')
    const debouncedInputSearch = useDebounce(inputSearch)

    const { platforms, handleFilterPlatform } = useFilterPlatform()

    const queryClient = useQueryClient()

    const { status, data } = useQuery(
        ['orgs', platforms, currentPage, rowsPerPage, sort, debouncedInputSearch],
        async () => OrgsService.getOrgs(debouncedInputSearch, sort, currentPage, rowsPerPage, platforms),
        {
            refetchOnWindowFocus: false,
            onError() {
                toast.error('Ocorreu um erro ao buscar as Organizações')
            }
        }
    )

    const { mutateAsync: updateOrgsMutation, isLoading: isUpdatingOrgs } = useMutation(
        OrgsService.updateOrgs,
        {onSuccess() {
            queryClient.invalidateQueries(['orgs'])
            toast.success('Organizações atualizadas com sucesso.')
        }}
    )

    useUpdateEffect(() => {
        if (!debouncedInputSearch || debouncedInputSearch.length === 0) {
            setIsSearching(false)
            return
        }

        setIsSearching(true)
        handlePageChange({ selected: 1 })
    }, [debouncedInputSearch])

    const onInputSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputSearch(e.target.value)
    }

    const updateOrgs = async () => {
        await updateOrgsMutation()
    }

    const resetInputSearch = () => {
        setInputSearch('')
    }

    const handleSort = useCallback((field: string | undefined, direction: Direction) => {
        if (!field) return;

        if (field === 'name') return setSort({ name: direction })

        setSort({ active: direction })
    },[])

    const handlePerRowsChange = useCallback((newPerPage: number, page: number) => {
        setRowsPerPage(newPerPage)
        handlePageChange({ selected: page })
	}, [handlePageChange])

    return {
        status,
        isSearching,
        result: data,
        platforms,
        currentPage,
        handlePageChange,
        inputSearch,
        isUpdatingOrgs,
        updateOrgs,
        resetInputSearch,
        onInputSearchChange,
        handlePerRowsChange,
        handleSort,
        handleFilterPlatform
    }
}
