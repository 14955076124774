import { useMediaQuery } from "@/hooks"

import { useAuth, useSidebarDrawer } from "@/contexts"

import { Navigation, Drawer } from "@/components"

import * as S from "./styles"

export function SidebarNav() {
    const { logout } = useAuth()

    const { isOpen, handleClose } = useSidebarDrawer()

    const matches = useMediaQuery('(min-width: 768px)')

    const handleLogout = async () => {
        await logout()
    }

    return (
        matches
            ? (
                <S.Wrapper>
                    <Navigation />
                </S.Wrapper>
            ) : (
                <Drawer
                    isOpen={isOpen}
                    onClose={handleClose}
                    onLogout={handleLogout}
                />
            )
    )
}
