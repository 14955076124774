import { Box } from "@/components"

import ContentLoader from "react-content-loader"

import { defaultProps } from "../default-props"

type RectangleContentLoaderProps = {
    width?: number | string
    height?: number
}

export function RectangleContentLoader ({ width = '100%', height = 100 }: RectangleContentLoaderProps) {
    return (
        <Box width={width} height={height}>
            <ContentLoader {...defaultProps} height={height}>
                <rect x="0" y="0" rx="5" ry="5" width={width} height={height}  />
            </ContentLoader>
        </Box>
    )
}
