import { SVGAttributes } from 'react'
import * as S from './styles'

export type SpinnerProps = {
    color?: string
    bgColor?: string
    size?: `${number}rem`
} & SVGAttributes<SVGElement>

export function Spinner ({ color, bgColor, size = '3.2rem', ...props }: SpinnerProps) {
    return (
        <S.SVGWrapper viewBox="0 0 50 50" size={size} {...props}>
            <span />
            <S.Circle
                color={color}
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="6"
            />
            <S.CircleBg
                bgColor={bgColor}
                strokeLinecap="round"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="6"
            />
        </S.SVGWrapper>
    )
}
