import { useState } from 'react'

import { useParams } from 'react-router-dom'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { usePagination, useSortMembers, useSortProjects, useSortRepos } from '@/hooks'

import { OrgNotFound } from '@/utils'

import { OrgsService, MembersService, ReposService } from '@/services'

import { Org } from '@/resources'

import { toast } from 'react-toastify'

export function useOrgDetails () {
    const { id: orgId } = useParams<{id: string}>()

    const {
        currentPage: currentPageMembers,
        rowsPerPage: rowsPerPageMembers,
        handlePageChange: handlePageMembersChange,
        handleRowsPerPage: handleRowsPerPageMembers
    } = usePagination(1)

    const {
        currentPage: currentPageRepos,
        rowsPerPage: rowsPerPageRepos,
        handlePageChange: handlePageReposChange,
        handleRowsPerPage: handleRowsPerPageRepos
    } = usePagination(1)

    const {
        currentPage: currentPageProjects,
        rowsPerPage: rowsPerPageProjects,
        handlePageChange: handlePageProjectsChange,
        handleRowsPerPage: handleRowsPerPageProjects
    } = usePagination(1)

    type AccordionItem = 'Projects' | 'Members' | 'Repos'

    const [accordionItemSelected, setAccordionItemSelected] = useState<AccordionItem | null>(null)

    const queryClient = useQueryClient()

    const { data, status, error }  = useQuery(
        ['org', orgId],
        async () => {
            if (orgId) return OrgsService.getOrgById(orgId)
        },
        {
            refetchOnWindowFocus: false,
        }
    )

    const { sort: sortMembers, handleSort: handleSortMembers } = useSortMembers()

    const { data: members, status: statusMembers } = useQuery(
        ['orgs/members', currentPageMembers, rowsPerPageMembers, sortMembers, orgId],
        async () => {
            if (!orgId) return
            return MembersService.getMembersByOrgId(orgId, currentPageMembers, rowsPerPageMembers, sortMembers)
        },
        {
            refetchOnWindowFocus: false,
            enabled: accordionItemSelected === 'Members'
        }
    )

    const { sort: sortRepos, handleSort: handleSortRepos } = useSortRepos()

    const { data: repos, status: statusRepos } = useQuery(
        ['orgs/repositories', currentPageRepos, rowsPerPageRepos, sortRepos, orgId],
        async () => {
            if (!orgId) return
            return ReposService.getReposByOrgId(orgId, currentPageRepos, rowsPerPageRepos, sortRepos)
        },
        {
            refetchOnWindowFocus:  false,
            enabled: accordionItemSelected === 'Repos'
        }
    )

    const { sort: sortProjects, handleSort: handleSortProjects } = useSortProjects()

    const { data: projects, status: statusProjects } = useQuery(
        ['orgs/projects', currentPageProjects, rowsPerPageProjects, sortProjects, orgId],
        async () => {
            if (!orgId) return
            return OrgsService.getProjectsByOrgId(orgId, currentPageProjects, rowsPerPageProjects)
        },
        {
            refetchOnWindowFocus: false,
            enabled: accordionItemSelected === 'Projects'
        }
    )

    const mutation = useMutation(
        OrgsService.updateStatusOrg,
        {
            onSuccess(_, variables) {
                queryClient.setQueryData<Org>(['org', orgId], oldData => {
                    if(!oldData) return oldData

                    return {
                        ...oldData,
                        active: !!variables.active
                    }
                })

                toast.success('Status da organização alterado com sucesso.')
            },
            onError() {
                toast.error('Ocorreu um erro inesperado ao alterar o status da Org.')
            }
        }
    )

    const handleToggleStatus = (id: string) => async () => {
        await mutation.mutateAsync({ id, active: !data?.active })
    }

    const handleAccordionItemSelect = (item: AccordionItem) => () => setAccordionItemSelected(item)

    return {
        status,
        error: error instanceof OrgNotFound ? error.message : 'Unexpected error',
        org: data,
        handleToggleStatus,
        isChangingStatus: mutation.isLoading,
        statusMembers,
        members: members?.members,
        paginationMembers: members?.pagination,
        handlePageMembersChange,
        repos: repos?.repos,
        statusRepos,
        paginationRepos: repos?.pagination,
        handlePageReposChange,
        projects: projects?.projects,
        statusProjects,
        paginationProjects: projects?.pagination,
        handlePageProjectsChange,
        handleRowsPerPageMembers,
        handleRowsPerPageRepos,
        handleRowsPerPageProjects,
        handleAccordionItemSelect,
        handleSortRepos,
        handleSortProjects,
        handleSortMembers
    }
}
