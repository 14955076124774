import api from '@/api'

import { SortFieldsRepos } from '@/hooks/use-sort-repos'

import { filterParameters } from '@/utils'

import type { PaginationResponseData, Platform, Repo } from '@/resources/types'

type ReposResponseData =  {
    repos: Repo[]
    pagination: PaginationResponseData
}

export const ReposService = {
    async getRepos (
        platforms: Platform[],
        search = '',
        date = '',
        order: SortFieldsRepos = { pushed_at: 'desc' },
        page = 1,
        limit = 10
    ): Promise<ReposResponseData> {
        const params = filterParameters({ platforms: platforms.join(','), page, limit, search, date,  order })
        const { data: { data,...pagination } } = await api.get(`/repositories`, { params })

        return { repos: data, pagination }
    },

    async getReposWithoutProject(name = ''): Promise<Repo[] | null> {
        const searchParam = name ? `search=${name}` : ''

        const { data: { data } } = await api.get(`/repositories/withoutproject?${searchParam}`)

        return data.length === 0 ? null : data
    },

    async updateRepos(): Promise<ReposResponseData> {
        await api.post('/repositories')

        const { data: { data, ...pagination }} =
            await api.get('/repositories')

        return {
            data: data.users,
            ...pagination
        }
    },

    async getReposByOrgId (
        orgId: string,
        page: number,
        limit: number,
        order: SortFieldsRepos = { pushed_at: 'asc' }
    ): Promise<ReposResponseData> {
        const { data: { data, ...pagination } } = await api.get('/repositories', { params: {
            organizationId: orgId,
            page,
            limit,
            order
        }})

        return { repos: data, pagination }
    }
}
