import { useState, FormEvent } from 'react'

import { Link, useNavigate } from 'react-router-dom'

import { useTheme } from 'styled-components'

import { useMutation } from '@tanstack/react-query'

import { AuthService } from '@/services'

import { AuthError } from '@/utils'

import { toast } from 'react-toastify'

import { HiOutlineMail as EmailIcon }  from 'react-icons/hi'

import {
    Logo,
    Button,
    Input,
    LinkButton,
    Typography,
    Stack,
    Box
} from '@/components'

type FormEventSubmit = FormEvent<HTMLFormElement> & {
    currentTarget: {
        email: HTMLInputElement
    }
}

export default function RecoverPassword() {
    const { colors, container } = useTheme()

    const navigate = useNavigate()

    const [email, setEmail] = useState('')

    const mutation = useMutation(['@forgot-password'], AuthService.forgotPassword, {
        onError(error) {
            if (error instanceof AuthError) {
                return toast.error(error.message)
            }

            toast.error("Ocorreu um erro inesperado.")
        }
    })

    const handleSubmit = async (e: FormEventSubmit) => {
        e.preventDefault()

        const { email: { value } } = e.currentTarget

        await mutation.mutateAsync(value)

        setEmail(value)
    }

    return (
        <Stack alignItems='center' justifyContent='center'>
            <Box maxWidth={container} width='100%'>
                <Stack
                    width='100%'
                    direction={{ xs: 'column', md: 'row'}}
                    alignItems='center'
                    justifyContent={['space-evenly', 'space-around']}
                    height='100vh'
                    p={6}
                >
                    <Stack alignItems='center' justifyContent='center'>
                        <Logo size='huge' />
                    </Stack>
                    {(mutation.isIdle || mutation.isError || !mutation.data) && (
                        <Stack
                            onSubmit={handleSubmit}
                            component='form'
                            gap={8}
                            justifyContent='space-evenly'
                            bgcolor='white'
                            maxWidth={420}
                            width='100%'
                            p={8}
                            borderRadius='10px'
                        >
                            <Typography tag='h3' fontSize='2xl'>Recuperar Senha</Typography>

                            <Input name='email' type='email' placeholder="Email utilizado no login" />
                            <Stack alignItems='center' gap={6}>
                                <Box width='100%'>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        size='medium'
                                        loading={mutation.isLoading}
                                        disabled={mutation.isLoading}
                                    >
                                        Enviar código
                                    </Button>
                                </Box>
                                <Stack direction='row' alignItems='baseline' gap={2}>
                                    <Link to='/login'>
                                        <LinkButton
                                            weight='bold'
                                            type="button"
                                            size='xs'
                                            color={colors.brand.orangeRed}
                                        >
                                            Voltar para o Login
                                        </LinkButton>
                                    </Link>
                                </Stack>
                            </Stack>
                        </Stack>
                    )}

                   {mutation.isSuccess && mutation.data.sent && (
                        <Stack
                            alignItems='center'
                            textAlign='center'
                            gap={6}
                            maxWidth={500}
                            bgcolor='white'
                            borderRadius='10px'
                            p={8}
                        >
                            <Stack
                                alignItems='center'
                                justifyContent='center'
                                p={3}
                                bgcolor='brand.blue'
                                borderRadius='50%'
                                width={80}
                                height={80}
                            >
                                <EmailIcon color={colors.white} size={42} />
                            </Stack>

                            <Typography tag='h2' fontWeight='medium' fontSize='3xl'>
                                Verifique seu e-mail
                            </Typography>
                            <Typography tag='p' fontSize='md' fontWeight='medium'>
                                Enviamos um link de redefinição de senha para {" "}
                                <Typography tag='span' fontWeight='medium' color='brand.blue'>
                                    {email}
                                </Typography>
                            </Typography>

                            <Box mt={4}>
                                <Button onClick={() => navigate('/login')}>Voltar para o Login</Button>
                            </Box>
                        </Stack>
                   )}
                </Stack>
            </Box>
        </Stack>
    );
}
