import { ChangeEvent, useState, useCallback } from "react"

import { useQuery } from "@tanstack/react-query"

import { usePagination, useDebounce, useUpdateEffect, useSortProjects } from "@/hooks"

import { ProjectsService } from "@/services"

export function useProjects () {
    const { currentPage, rowsPerPage, handlePageChange, handleRowsPerPage } = usePagination(1)

    const [isSearching, setIsSearching] = useState(false)

    const [projectName, setProjectName] = useState('')
    const projectNameDebounced = useDebounce(projectName)

    const { sort, handleSort } = useSortProjects()

    const { status, data } = useQuery(
        ['projects', { currentPage, rowsPerPage, projectNameDebounced, sort }],
        async () => ProjectsService.getProjects(projectNameDebounced, sort, currentPage, rowsPerPage),
        { refetchOnWindowFocus: false }
    )

    useUpdateEffect(() => {
        if (projectNameDebounced) {
            setIsSearching(true)
            handlePageChange({ selected: 1 })
            return
        }

        setIsSearching(false)
    }, [projectNameDebounced])

    const handleProjectNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setProjectName(e.target.value)
    }, [])

    const handleResetProjectNameSearch = useCallback(() => setProjectName(''), [])

    const handlePerRowsChange = useCallback((newPerPage: number, page: number) => {
        handleRowsPerPage(newPerPage)
        handlePageChange({ selected: page })
	}, [handlePageChange, handleRowsPerPage])

    return {
        status,
        isSearching,
        result: data,
        handlePageChange,
        projectName,
        handleProjectNameChange,
        handleResetProjectNameSearch,
        handlePerRowsChange,
        handleSort
    }
}
