import styled, { css } from 'styled-components'

export const Name = styled.p`
    ${({ theme }) => css`
        color: ${theme.colors.black};
        font-weight: ${theme.font.weights.medium};
        will-change: color;
        font-size: 1.4rem;
    `}
`

export const Wrapper = styled.button`
    border:0;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 0.6rem;

    cursor: pointer;

    transition: 400ms transform;

    will-change: color, transform;

    &:hover {
        transition: 400ms transform, 400ms color;
        transform: scale(1.05);
        will-change: color;

        ${Name} {
            transition: 400ms color;
            color: ${({ theme }) => theme.colors.brand.blue};
        }
    }
`
