import { useState } from 'react'

import { Org } from '@/resources'

import { toast } from 'react-toastify'

export function useSelectedOrgs () {
    const [orgs, setOrgs] = useState<Org[]>([])

    function setOrgsData (orgs: Org[]) {
        setOrgs(orgs)
    }

    const handleSelectOrg = (org: Org) => () => {
        const existsOrg = orgs.find(item => item.id === org.id)

        if(existsOrg) {
            toast.error('Essa organização já foi selecionada.')
            return
        }

        setOrgs(old => old.concat(org))
    }

    const handleRemoveSelectedOrg = (id: string) => () => {
        setOrgs(old => old.filter(org => org.id !== id))
    }

    return {
        orgs,
        setOrgsData,
        handleSelectOrg,
        handleRemoveSelectedOrg
    }
}
