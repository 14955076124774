import { useState, useCallback } from 'react'

type Direction = 'asc' | 'desc'

type FieldRepoName = {
    name: Direction
}

type FieldRepoLastCommit = {
    pushed_at: Direction
}

type FieldTotalCommits = {
    total: Direction
}

export type SortFieldsRepos =
    | FieldRepoName
    | FieldRepoLastCommit
    | FieldTotalCommits

export function useSortRepos () {
     const [sort, setSort] = useState<SortFieldsRepos>({ pushed_at: 'desc' })

     const handleSort = useCallback((field: string | undefined, direction: Direction) => {
        if (!field) return

        switch (field) {
            case 'repoName':
                setSort({ name: direction })
                break
            case 'lastCommit':
                setSort({ pushed_at: direction })
                break
            case 'totalCommits':
                setSort({ total: direction })
                break
            default:
                throw new Error('')
        }
    }, [])

    return { sort, handleSort }
}
