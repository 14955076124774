import DataTableComponent, { TableProps, TableStyles } from 'react-data-table-component'

import { AiOutlineArrowDown as ArrowIcon } from 'react-icons/ai'

import { theme } from '@/lib/theme'

export type { TableColumn } from 'react-data-table-component'

const customStyles: TableStyles = {
    table: {
        style: {
            fontFamily: theme.font.family,
        }
    },
    headRow: {
        style: {
            background: '#edf9ff',
            color: 'rgba(0, 0, 0, 0.7)',
            fontWeight: theme.font.weights.medium,
            fontSize: '14px',
            minHeight: '62px',
        },
        denseStyle: {
			minHeight: '32px',
		},
    },
    rows: {
        style: {
            fontSize: '14px',
    },
        highlightOnHoverStyle: {
           transitionDuration: '0.3s',
			transitionProperty: 'background-color',
            background: '#edf9ff',
            color: 'rgba(0, 0, 0, 0.7)'
        }
    },
    cells: {
        style: {
            paddingTop: '12px',
            paddingBottom: '12px'
        }
    }
}

export function DataTable <T>(props: TableProps<T>) {
    return (
        <DataTableComponent
            {...props}
            customStyles={customStyles}
            highlightOnHover
            sortIcon={<ArrowIcon />}
            responsive
        />
    )
}
