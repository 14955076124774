import { useCallback, useState } from 'react'

type UseModal = (initialState?: boolean) => [
    state: boolean,
    toogleModal: () => void,
    openModal: () => void,
    close: () => void
]

export const useModal: UseModal = (initialState = false) => {
    const [isOpen, setIsOpen] = useState(initialState)

    const toggleModal = useCallback(() => setIsOpen(state => !state), [])

    const openModal = useCallback(() => setIsOpen(true), [])

    const closeModal = useCallback(() => setIsOpen(false), [])

    return [
        isOpen,
        toggleModal,
        openModal,
        closeModal
    ]
}
