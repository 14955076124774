import {Box, BoxProps} from "@mui/system";

type CircleProps = BoxProps

export function Circle ({ width = 10, height = 10, ...props }: CircleProps) {
    return <Box
        width={width}
        height={height}
        borderRadius='50%'
        display='flex'
        alignItems='center'
        justifyContent='center'
        {...props}
    />
}
