import styled,  { css }  from 'styled-components'

import { transparentize } from 'polished';

import { ButtonProps, Button } from '.';

const ButtonModifiers = {
    xsmall: () => css`
        font-size: 1.2rem;
        height: 2.4rem;
        padding-inline: 0.8rem;
    `,

    small: () => css`
        font-size: 1.4rem;
        height: 3.2rem;
        padding-inline: 1.4rem;
    `,

    medium: () => css`
        font-size: 1.6rem;
        height: 4rem;
        padding-inline: 1.6rem;
    `,

    large: () => css`
        font-size: 1.6rem;
        height: 4.8rem;
        padding-inline: 2.4rem;
    `,

    fullWidth: () => css`
        width: 100%;
    `,

    rounded: () => css`
        border-radius: 50%;
    `,

    outline: (color: string) => css`
        color: #000;
        background-color: transparent;
        border: 1px solid ${color};

        &:hover{
            background-color: ${transparentize(0.7, color)};
        }
    `
}

export const ButtonStyled = styled(Button).withConfig({
    shouldForwardProp: (prop) => !['backgroundColor', 'fullWidth'].includes(prop)
})<ButtonProps>`
    font-weight: 700;
    border-radius: 8px;
    border: 0px solid;
    white-space: nowrap;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    text-decoration: none;

    outline: none;

    transition: opacity 0.25s ease-in, background-color 0.25s ease-in;

    ${({ theme, fullWidth, backgroundColor, textColor, size = 'small', rounded, variant }) => css`
        color: ${textColor || theme.colors.white};
        background-color: ${backgroundColor || theme.colors.brand.blue};

        ${!!size && ButtonModifiers[size]()};
        ${!!fullWidth && ButtonModifiers.fullWidth()};
        ${!!rounded && ButtonModifiers.rounded()};
        ${!!variant && ButtonModifiers[variant](backgroundColor || theme.colors.brand.blue)};
    `}

    will-change: opacity, background-color;

    &:hover {
        transition: opacity 0.25s ease-in, background-color 0.25s ease-in;
        opacity: 0.6;
    }
`;
