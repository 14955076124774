import {ReactNode} from 'react'
import * as S from './styles'


export interface BadgeProps {
    children: ReactNode
    color?: string
    bgcolor: string
}

export function Badge (props: BadgeProps) {
    return <S.Wrapper {...props} />
}
