import { forwardRef } from 'react';

import { AccordionTriggerProps, AccordionContentProps  } from '@radix-ui/react-accordion'

import {
    StyledAccordion,
    StyledItem,
    StyledHeader,
    StyledTrigger,
    StyledChevron,
    StyledContent
 } from './styles'

export const Accordion = StyledAccordion;
export const AccordionItem = StyledItem;

export const AccordionChevron = () => <StyledChevron aria-hidden size={26} />

type TriggerProps = AccordionTriggerProps & {
    custom?: boolean
}

export const AccordionTrigger = forwardRef<HTMLButtonElement, TriggerProps>(({
    children, custom = true, ...props
}, ref) => (
  <StyledHeader>
    <StyledTrigger {...props} $custom={custom} ref={ref} asChild>
        {children}
    </StyledTrigger>
  </StyledHeader>
));

export const AccordionContent = forwardRef<HTMLDivElement, AccordionContentProps>(({
    children, ...props
}, ref) => (
    <StyledContent {...props} ref={ref}>
        {children}
    </StyledContent>
));
