import { ElementType, ReactNode } from 'react'
import * as S from './styles'

export type ContainerProps = {
    children: ReactNode
    maxWidth?: number
    as?: ElementType
}

export function Container ({ children, maxWidth, as = 'div'}: ContainerProps) {
    return (
        <S.Wrapper maxWidth={maxWidth} as={as}>{children}</S.Wrapper>
    )
}
