import { useState, useCallback } from 'react'

import type { SortFieldsRankingProjects, SortDirection } from '@/resources/types'

export function useSortRankingProjects (sortDefault: SortFieldsRankingProjects = { rank: 'asc' }) {
    const [sortRankingProjects, setSortRankingProjects] = useState<SortFieldsRankingProjects>(sortDefault)

    const handleSortRankingProjects = useCallback((field: string | undefined, dir: SortDirection) => {
        if (!field) return;

        switch (field) {
            case 'rankPosition':
                setSortRankingProjects({ rank: dir })
                break
            case 'projectName':
                setSortRankingProjects({ project: { name: dir } })
                break
            case 'totalMembers':
                setSortRankingProjects({ totalMembers: dir })
                break
            case 'totalCommits':
                setSortRankingProjects({ total: dir })
                break
            case 'conventional':
                setSortRankingProjects({ percentConv: dir })
                break
            case 'commitFeat':
                setSortRankingProjects({ percentFeat: dir })
                break
            case 'commitRefactor':
                setSortRankingProjects({ percentRefactor: dir })
                break
            case 'commitFix':
                setSortRankingProjects({ percentFix: dir })
                break
            case 'commitChore':
                setSortRankingProjects({ percentChore: dir })
                break
            case 'commitsOthers':
                setSortRankingProjects({ percentOther: dir })
                break
            case 'commitMedian':
                setSortRankingProjects({ commitMedian: dir })
                break
            case 'comportamentalMedian':
                setSortRankingProjects({ comportamentalMedian: dir })
                break
            case 'score':
                setSortRankingProjects({ score: dir })
                break
            default:
                return null;
        }
    }, [])

    return {
        sortRankingProjects,
        handleSortRankingProjects
    }
}
