/* eslint-disable @typescript-eslint/no-explicit-any */
export type Intervals =
    | '24hrs'
    | '48hrs'
    | '72hrs'
    | '7days'

export type ChartProps = {
    title?: string
    data: any[]
    categories?: any[]
}

export type IntervalHours = {
    type: 'hours'
    hour: number
    count: number
}

export type IntervalDays = {
    type: 'days',
    day: number
    count: number
}

// export type IntervalMonths = {
//     month: number
//     count: string
// }
//
// export type IntervalYears = {
//     year: number
//     count: string
// }

// export type TransformChartDataFnArg<T> = (item: T) => {
//     item: string,
//     category: string | number
// }
//
// export function transformChartData <T>(data: T[], fn: TransformChartDataFnArg<T>): ChartProps {
//     return data.reduce<ChartProps>((acc, current) => {
//         const { item, category } = fn(current)
//
//         return {
//             ...acc,
//             data: acc.data.concat(item),
//             // categories: acc.categories.concat(category)
//         }
//      }, {data: [], categories: []})
//  }

export function isIntervalHours (data: unknown[]): data is IntervalHours[] {
    return (data[0] as IntervalHours).type === 'hours'
}

export function isIntervalDays (data: unknown[]): data is IntervalDays[] {
    return (data[0] as IntervalDays).type === 'days'
}

// export function isIntervalMonths (data: unknown[]): data is IntervalMonths[] {
//     return (data[0] as IntervalMonths).month !== undefined
// }

export const intervalsTitle = {
    '24hrs': { title: 'Commits nas últimas 24 horas' },
    '48hrs': { title: 'Commits nas últimas 48 horas' },
    '72hrs': { title: 'Commits nas últimas 72 horas' },
    '7days': { title: 'Commits nos últimos 7 dias (week).' },
}
