import api from '@/api'

import { SortFieldsOrgs } from '@/hooks'

import type { Org, PaginationResponseData, Platform, Project } from '@/resources/types'

import { OrgNotFound } from '@/utils'

interface OrgsResponseData extends PaginationResponseData {
    data: Org[]
}

interface GetAllOrgsReponse {
    orgs: Org[]
}

interface GetProjectsOrgResponseData {
    projects: Project[]
    pagination: PaginationResponseData
}

export const OrgsService = {
    async getOrgs (
        search = '',
        sort: SortFieldsOrgs = {active: 'desc'},
        page = 1,
        limit = 10,
        platforms: Platform[] = []
    ): Promise<OrgsResponseData> {
        const { data: { data, ...rest }} = await api.get(`/organizations`, { params: {
            page,
            limit,
            search,
            order: JSON.stringify(sort),
            platforms: platforms.join(',')
        }})

        return { data, ...rest }
    },

    async getOrgByName (name = ''): Promise<Org[] | null> {
        const { data: { data } }=
            await api.get<OrgsResponseData>(
                `/organizations?page=1&limit=10&search=${name}`
            )

        return data.length === 0 ? null : data
    },

    async getAllOrgs (): Promise<GetAllOrgsReponse> {
        const { data } =
            await api.get('/organizations/dropDown'
        )

        return { orgs: data  }
    },

    async updateOrgs(): Promise<void> {
        await api.post('/organizations')
    },

    async updateStatusOrg({ id = '', active = false }) {
        await api.patch(`/organizations/${id}`,{ active })
    },

    async getOrgById (id: string): Promise<Org> {
        const { data } = await api.get<Org>(`/organizations/${id}`)

        if (!('name' in data)) {
            throw new OrgNotFound('Organização não encontrada.')
        }

        return data
    },

    async getProjectsByOrgId (
        orgId: string,
        page = 1,
        limit = 10,
    ): Promise<GetProjectsOrgResponseData> {
        const { data: { data, ...pagination }} = await api.get(`/organizations/${orgId}/projects`, { params: {
            page,
            limit,
        }})

        return { projects: data, pagination }
    }
}

