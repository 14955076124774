import { styled, css } from '@/lib'

import { EmptyStateProps } from '.'

export const Wrapper = styled('div')`
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.6rem;
    padding: 1.6rem;
`

type ImgWrapperProps = Pick<EmptyStateProps, 'size'>

export const Description = styled('p')`
    font-weight: ${({ theme }) => theme.font.weights.bold};
    font-size: 1.4rem;
`

const ImgWrapperModifiers = {
    small: () => css`
        width: 100px;
        & ~ p {
            font-size: 14px;
        }
    `,
    medium: () => css`
        width: 150px;
        & ~ p {
            font-size: 16px;
        }
    `,
    large: () => css`
        width: 200px;
    `,
}

export const ImgWrapper = styled('div')<ImgWrapperProps>`
    transform: translateX(-5%);

    ${({ size = 'large' }) => css`
        ${size && ImgWrapperModifiers[size]};
    `}
`
