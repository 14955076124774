import { useState, ChangeEvent, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import { usePagination, useDebounce, useUpdateEffect, useFilterPlatform, useSortMembers } from '@/hooks'

import { MembersService } from "@/services"

import { OnSelectChangeEvent, Position } from '@/resources'

import { toast } from "react-toastify"

const selectedPosition = {
    'Administrator': 0,
    'Desenvolvedor': 1,
    'Product Owner': 2
}

const selectedIndexPosition: Position[] = ['Administrator', 'Desenvolvedor', 'Product Owner']

export function useMembers () {
    const { currentPage, rowsPerPage, handlePageChange, handleRowsPerPage } = usePagination(1)

    const [isSearching, setIsSearching] = useState(false)
    const [memberNameInput, setMemberNameInput] = useState('')
    const [position, setPosition] = useState<Position>('Administrator')

    const memberNameInputDebounced = useDebounce(memberNameInput)

    const { platforms, handleFilterPlatform } = useFilterPlatform()

    const { sort, handleSort } = useSortMembers()

    const { data: resultAdmins, isLoading: isLoadingAdmins } = useQuery(
        ['admins', { currentPage, memberNameInputDebounced, sort, rowsPerPage }],
        async () => MembersService.getAdministrators(
            memberNameInputDebounced,
            currentPage,
            rowsPerPage,
            sort
        ),
        {
            enabled: position === 'Administrator',
            onError() { toast.error('Ocorreu um erro ao buscar os Membros') },
            refetchOnWindowFocus: false
        },
    )

    const { data: resultDevs, isLoading } = useQuery(
        ['devs', { currentPage, memberNameInputDebounced, sort, rowsPerPage }],
        async () => MembersService.getMembers(
            position,
            platforms,
            memberNameInputDebounced,
            currentPage,
            rowsPerPage,
            sort
        ),
        {
            enabled: position === 'Desenvolvedor',
            onError() { toast.error('Ocorreu um erro ao buscar os Membros') },
            refetchOnWindowFocus: false
        },
    )

    const { data: resultProductOwners, isLoading: isProductOwnersLoading } = useQuery(
        ['product-owners', { currentPage, rowsPerPage, memberNameInputDebounced, sort }],
        async () => MembersService.getProductOwners(
            memberNameInputDebounced,
            currentPage,
            rowsPerPage,
            sort
        ),
        {
            enabled: position === 'Product Owner',
            onError() { toast.error('Ocorreu um erro ao buscar os Product Owners') },
            refetchOnWindowFocus: false
        },
    )

    useUpdateEffect(() => {
        if (memberNameInputDebounced) {
            setIsSearching(true)
            handlePageChange({ selected: 1 })
            return
        }

        setIsSearching(false)
    }, [memberNameInputDebounced])

    const handlePosition = useCallback((index: number) => {
        setPosition(selectedIndexPosition[index])
        handleRowsPerPage(10)
    }, [handleRowsPerPage])

    const handleSelectChange = useCallback((e: OnSelectChangeEvent) => {
        setPosition(e.target.value)
    }, [])

    const handleMemberNameInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setMemberNameInput(e.target.value)
    }, [])

    const handleResetMemberSearched = useCallback(() => {
        handlePageChange({ selected: 1 })
        setMemberNameInput('')
    }, [handlePageChange])

    const handlePerRowsChange = useCallback((newPerPage: number, page: number) => {
        handleRowsPerPage(newPerPage)
        handlePageChange({ selected: page })
	}, [handlePageChange, handleRowsPerPage])

    return {
        isLoading,
        isSearching,
        isLoadingAdmins,
        isProductOwnersLoading,
        resultAdmins,
        resultDevs,
        resultProductOwners,
        platforms,
        handlePageChange,
        memberNameInput,
        handleMemberNameInputChange,
        handleResetMemberSearched,
        position,
        selectedPositionIndex: selectedPosition[position],
        handlePosition,
        handleSelectChange,
        handleSort,
        handlePerRowsChange,
        handleFilterPlatform
    }
}
