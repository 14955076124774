import styled from 'styled-components'

export const Wrapper = styled.aside`
    width: 29.5rem;
    background-color: white;
    padding: 2rem 1.6rem;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.gray[100]};

    display: none;
    overflow: hidden;

    position: sticky;
    align-self: flex-start;
    top: 3.2rem;
    z-index: 0;

    @media only screen and (min-width: 768px) {
        display: block;
    }
`
