import { styled } from '@/lib'

import { Link } from 'react-router-dom'

export const Wrapper = styled(Link)`
    max-width: max-content;
    text-decoration: none;
    color: currentColor;

    padding-inline: 0.8rem;

    display: flex;
    align-items: center;
    gap: 1.2rem;
`
