import { ChangeEvent, useState, useCallback } from "react"

import { usePagination, useDebounce, useUpdateEffect, useFilterPlatform, useSortCommits } from "@/hooks"

import { useQuery } from "@tanstack/react-query"

import { CommitsService } from "@/services"

type FiltersCommits = {
    member: string
    shaCommit: string
    dateCommit?: Date
}

export function useCommits () {
    const [isSearching, setIsSearching] = useState(false)
    const { currentPage, rowsPerPage, handlePageChange, handleRowsPerPage } = usePagination(1)

    const [filtersValues, setFiltersValues] = useState<FiltersCommits>({ member: '', shaCommit: '' })

    const filtersValuesDebounced = useDebounce(filtersValues)

    const { platforms, handleFilterPlatform } = useFilterPlatform()

    const { sort, handleSort } = useSortCommits()

    const { status, data } = useQuery(['commits', platforms, currentPage, rowsPerPage, filtersValuesDebounced, sort],
        async () => CommitsService.getCommits(
            platforms,
            filtersValuesDebounced.member,
            filtersValuesDebounced.dateCommit
                ? filtersValuesDebounced.dateCommit.toISOString() : '',
            filtersValuesDebounced.shaCommit,
            sort,
            currentPage,
            rowsPerPage,
        ),
        { refetchOnWindowFocus: false, enabled: false }
    )

    useUpdateEffect(() => {
        if (!!filtersValuesDebounced.dateCommit ||
            filtersValuesDebounced.member ||
            filtersValuesDebounced.shaCommit
        ) {
            setIsSearching(true)
            handlePageChange({ selected: 1 })
            return
        }

        setIsSearching(false)
    }, [filtersValuesDebounced])

    const handleFilterValuesInputsChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFiltersValues(oldState => ({
            ...oldState,
            [e.target.name]: e.target.value
        }))
    }

    const handleFilterDatePickerChange = (value: Date) => {
        setFiltersValues(oldState => ({ ...oldState, dateCommit: value }))
    }

    const handleResetFilterField = (field: 'member' | 'shaCommit' | 'dateCommit') => {
        return () => {
            setFiltersValues(oldState => ({ ...oldState, [field]: '' }))
        }
    }

    const handlePerRowsChange = useCallback((newPerPage: number, page: number) => {
        handleRowsPerPage(newPerPage)
        handlePageChange({ selected: page })
	}, [handlePageChange, handleRowsPerPage])

    return {
        status,
        result: data,
        isSearching,
        filtersValuesDebounced,
        platforms,
        currentPage,
        rowsPerPage,
        handlePerRowsChange,
        handlePageChange,
        filtersValues,
        handleFilterValuesInputsChange,
        handleFilterDatePickerChange,
        handleResetFilterField,
        sort,
        handleSort,
        handleFilterPlatform
    }
}
