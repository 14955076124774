import { useCallback, useMemo, useState } from 'react'

import type  { OnPageChangeEvent } from '@/resources/types'

export const usePagination = (currentPage = 0, rowsPerPage = 10) => {
    const [state, setState] = useState(currentPage)
    const [rowsPerPageState, setRowsPerPageState] = useState(rowsPerPage)

    const handlePageChange: OnPageChangeEvent = useCallback(({ selected }) => {
        setState(selected)
    }, [])

    const handleRowsPerPage = useCallback((limit: number) => setRowsPerPageState(limit), [])

    return useMemo(() => ({
        currentPage: state,
        rowsPerPage: rowsPerPageState,
        handlePageChange,
        handleRowsPerPage
    }), [state, rowsPerPageState, handlePageChange, handleRowsPerPage])
};
