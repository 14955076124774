import { styled } from '@/lib'

export const HeaderWrapper = styled('div')`
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    top: 0;
    box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.colors.gray[200]};
    position: relative;
    padding: 1.2rem 0;
`

export const ContainerContent = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const BoxLeft = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
`

export const LogoWrapper = styled('div')`
    @media (min-width: 768px){
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
`

export const ActionsWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.6rem;
`;

export const ButtonWrapper = styled('div')`
    display: none;
    @media only screen and (min-width: 768px) {
        min-width: 8.5rem;
        display: block;
    }
`

export const MenuIconWrapper = styled('button')`
    background-color: transparent;
    cursor: pointer;
    border: 0;
    outline: 0;

    @media only screen and (min-width: 768px) {
        display: none;
    }
`
