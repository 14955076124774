import { Platform } from '@/resources/types'

import { theme } from '@/lib/theme'

import { Stack, Button } from '@/components'

import { BsGithub as GithubIcon} from 'react-icons/bs'
import { IoLogoBitbucket as BitBucketIcon } from 'react-icons/io'
import { AiFillGitlab as GitLabIcon } from 'react-icons/ai'
// import { SiAzuredevops as AzureIcon } from 'react-icons/si'

type PlatformFilterButtonsProps = {
    platforms: Platform[]
    onFilterPlatform: (platform: Platform) => () => void
}

export function PlatformFilterButtons ({ platforms, onFilterPlatform }: PlatformFilterButtonsProps) {
    const { colors } = theme

    return (
        <Stack direction='row' gap={2}>
            <Button
                backgroundColor={platforms.includes('Github') ? colors.gray[900] : colors.silver}
                onClick={onFilterPlatform('Github')}
            >
                <GithubIcon /> Github
            </Button>
            <Button
                backgroundColor={platforms.includes('Bitbucket') ? colors.brand.blue : colors.silver}
                onClick={onFilterPlatform('Bitbucket')}
            >
                <BitBucketIcon /> Bitbucket
            </Button>
            <Button
                backgroundColor={platforms.includes('GitLab') ? colors.brand.orangeRed : colors.silver}
                onClick={onFilterPlatform('GitLab')}
            >
                <GitLabIcon size={18} /> GitLab
            </Button>
            {/*
                 <Button
                    backgroundColor={platform === 'AzureDevOps' ? colors.brand.blue : colors.silver}
                    onClick={onFilterPlatform('AzureDevOps')}
                >
                    <AzureIcon /> Azure Devops
                </Button>
            */}
        </Stack>
    )
}
