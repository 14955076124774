import styled, { css } from 'styled-components'

import { BadgeProps } from '.'

export const Wrapper = styled.span<Omit<BadgeProps, 'children'>>`
    ${({ color = '#fff', bgcolor }) => css`
        display: block;
        padding: 6px;
        border-radius: 5px;
        color: ${color};
        background-color: ${bgcolor};
        font-weight: bold;
    `}
`
