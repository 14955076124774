import { ChangeEvent, useState } from "react"

import { useQuery } from "@tanstack/react-query"

import { useDebounce } from "@/hooks"

import { MembersService } from "@/services"

export function useSearchMemberByName () {
    const [memberName, setMemberName] = useState('')
    const memberNameDebounced = useDebounce(memberName)

    const result = useQuery(
        ['searchedMember', memberNameDebounced],
        async () => MembersService.getMemberByName(memberNameDebounced),
        {
            enabled: !!memberNameDebounced,
            refetchOnWindowFocus: false
        }
    )

    const handleMemberNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMemberName(e.target.value)
    }

    const cleanMemberName = () => {
        setMemberName('')
    }

    return {
        result: result?.data,
        isLoadingMemberSearch: result.isLoading && !!memberNameDebounced,
        memberSearchError: !result.data && result.status === 'success'
            ? 'Membro não encontrado' : '',
        memberName,
        handleMemberNameChange,
        cleanMemberName
    }
}
