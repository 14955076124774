import { forwardRef } from 'react'

import { commitColor } from '@/resources/commit'

import * as S from './styles'

export type CommitsBarProps = {
    commit: 'feat' | 'refactor' | 'fix' | 'chore'
    percent: number
}

export const CommitsBar = forwardRef<HTMLDivElement, CommitsBarProps>(({ commit, percent, ...props }, ref) => (
    <S.CommitsBarContainer {...props} ref={ref} percent={percent}>
        <S.CommitsBarWrapper color={commitColor[commit]}>
            <p>{Number(percent.toFixed(2))}%</p>
        </S.CommitsBarWrapper>
    </S.CommitsBarContainer>
))
