import { SortFieldsHistory } from "@/hooks"

import {
    Intervals,
    IntervalHours,
    IntervalDays,
    Member,
    PaginationResponseData,
    isIntervalHours,
    Project,
    History,
    MemberWithAverageCommits,
    SortFieldsLatestProjects,
    SortFieldsAverageCommitsOfDevs,
    SortFieldsAdmins
} from '@/resources'

import api from "@/api"

type GetAveragesCommitsOfMembersDevsResponse = {
    devs: MemberWithAverageCommits[]
    pagination: PaginationResponseData
}

type GetAdminsResponse = {
    data: Member[]
    pagination: PaginationResponseData
}

type LatestUpdatedProjectsResponse = {
    projects: Project[]
    pagination: PaginationResponseData
}

export type ChartResponseData =
    | IntervalHours[]
    | IntervalDays[]

type ChartData = number[][]

type HistoryResponseData = {
    history: History[]
    pagination: PaginationResponseData
}

type HistoryResponse = Omit<History, 'requests'> & {
    requests: string
}

export const DashboardService = {
    async chart(interval: Intervals = '24hrs'): Promise<ChartData | null> {
        const { data } = await api.get<ChartResponseData>('/commits/chart',
            { params: { interval }}
        )

        if(data.length === 0) return null

        if(isIntervalHours(data)) {
            return data.map(item => [item.hour, item.count])
        }

        return data.map(item => [item.day, item.count])
    },

    async getAdmins(order: SortFieldsAdmins = { active: 'asc' }, page = 1, limit = 10): Promise<GetAdminsResponse> {
        const orderParam = `&order=${JSON.stringify(order)}`

        const { data: { data, ...pagination } }
            = await api.get(`/members?page=${page}&limit=${limit}&position=administrador${orderParam}`)

        return { data, pagination }
    },

    async getActiveMembers(): Promise<{count: number}> {
        const { data } = await api.get('/members/count')

        return {
            count: data
        }
    },

    async getLatestUpdatedProjects(
        order: SortFieldsLatestProjects = { updated_at: 'desc' },
        page = 1,
        limit = 10
    ): Promise<LatestUpdatedProjectsResponse> {
        const orderParam = JSON.stringify(order)

        const { data: { data, ...rest } } =
            await api.get(`/projects/recently?page=${page}&limit=${limit}&order=${orderParam}`)

        return { projects: data, pagination: rest }
    },

    async updateProjectsLast24Hours (): Promise<void> {
        await api.post('/repositories')
    },

    async getHistory (page = 1, limit = 6,  order: SortFieldsHistory = { created_at: 'asc' }): Promise<HistoryResponseData> {
        const { data: { data, ...pagination } } = await api.get('/history', {
            params: { page, limit, order: JSON.stringify(order) }
        })

        const transformedData = data.map((item: HistoryResponse) => {
            if(!item.requests) return { ...item, requests: undefined }

            return {
                ...item,
                requests: JSON.parse(item.requests)
            }
        })

        return { history: transformedData, pagination }
    },

    async getAverageCommits(): Promise<{ count: number }> {
        const { data } = await api.get('/commits/average')

        return data
    },

    async getAverageCommitsOfMembersDevs(
        page = 1,
        limit = 10,
        order: SortFieldsAverageCommitsOfDevs = { average: 'desc' },
        member = ''
    ): Promise<GetAveragesCommitsOfMembersDevsResponse> {
        const { data: { data, ...pagination } } = await api.get('/commits/average/members', {
            params: { page, limit, order, member: member.toLowerCase() }
        })

        return { devs: data, pagination }
    }
}
