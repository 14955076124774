import styled, { css, keyframes } from 'styled-components'

import * as AccordionPrimitive from '@radix-ui/react-accordion'

import { RiArrowUpSLine as ArrowUpIcon } from 'react-icons/ri'

const slideDown = keyframes`
    from { height: 0 }
    to { height: var(--radix-accordion-content-height) }
`

const slideUp = keyframes`
    from { height: var(--radix-accordion-content-height) }
    to { height: 0 }
`

export const StyledAccordion = styled(AccordionPrimitive.Root)`
    border: 6px;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.175);
`

export const StyledItem = styled(AccordionPrimitive.Item)`
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[100]};

    overflow: hidden;

    &:first-child {
        margin-top: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    &:focus-within {
        position: relative;
        z-index: 1;
        box-shadow: 0 0 0 0.45px ${({ theme }) => theme.colors.brand.blue};
    }
`

export const StyledHeader = styled(AccordionPrimitive.Header)`
    all: unset;
    display: flex;
`

type TriggerProps = {
    $custom?: boolean
}

export const StyledTrigger = styled(AccordionPrimitive.Trigger)<TriggerProps>`
    ${({ theme, $custom }) => css`
        ${$custom && css`
            font-family: inherit;
            background-color: transparent;
            padding: 16px;
            flex: 1;
            font-size: 1.6rem;
            line-height: 1;
            color: ${theme.colors.black};
            box-shadow: 0 1px 0  ${theme.colors.gray[100]};

            &:hover {
                background-color: ${theme.colors.gray[50]};
            }

            &[data-state="open"] {
                background-color: white;
            }
        `}
    `}
`

export const StyledContent = styled(AccordionPrimitive.Content)`
    &[data-state="open"] {
        animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
     }

    &[data-state="closed"] {
        animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
`

export const StyledContentText = styled.div`
    padding: 15px 20px;
`

export const StyledChevron = styled(ArrowUpIcon)`
    ${({ theme }) => css`
        color: ${theme.colors.brand.blue};
        transition: transform 300ms;
        will-change: transform;
        transform: rotate(180deg);

        ${StyledHeader}[data-state=open] & {
            transition: transform 300ms;
            transform: rotate(360deg)
        }
    `}

`
