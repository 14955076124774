import styled from 'styled-components'

export const CloseIconWrapper = styled.button`
    background-color: transparent;
    cursor: pointer;
    border: 0;

    position: absolute;
    top: 0;
    right:0;
    margin: 1.6rem;
`

export const GoOutButtonWrapper = styled.div`
    width: 100%;
    margin-top: auto;
`
