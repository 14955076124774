import { ReactNode } from "react"

import { Typography } from "@/components/atoms"

import * as S from './styles'

export type CardProps = {
    children: ReactNode
    title?: string
    footer?: string
    contentScrollX?: boolean
}

export function Card ({ children, title, footer, contentScrollX }: CardProps) {
    return (
        <S.Container>
            <S.Header>
                <Typography tag='h2' fontSize={['xl', '2.2rem']}>
                    {title}
                </Typography>
            </S.Header>

            <S.Content contentScrollX={contentScrollX}>{children}</S.Content>

            {!!footer && (
                <S.Footer>
                    <Typography tag='p' fontWeight={300} fontSize="sm">
                        {footer}
                    </Typography>
                </S.Footer>
            )}
        </S.Container>
    )
}
