import ContentLoader from 'react-content-loader'

import { Box } from '@/components'

import { defaultProps } from "../default-props";

export function NameContentLoader ({ width = 200, height = 32 }) {
    return (
        <Box width={width} height={height}>
            <ContentLoader {...defaultProps}>
                <rect x="0" y="0" rx="3" ry="3" width={width} height={height} />
            </ContentLoader>
        </Box>
    )
}
