import { useCallback, useState } from 'react'

import { Platform } from '@/resources/types'

export function useFilterPlatform () {
    const [state, setState] = useState<Platform[]>([])

    const handleFilterPlatform = useCallback((platform: Platform) => () => {
        if(state.includes(platform)) {
            setState(state => state.filter(value => value !== platform))
            return
        }

        setState(state => [...state, platform])
    }, [state])

    return { platforms: state, handleFilterPlatform }
}
