import { styled, css } from '@/lib'

import { TableProps } from ".";

type TableWrapperProps = Pick<TableProps, 'maxWidth'  | 'maxHeight'>

export const TableWrapper = styled('div')<TableWrapperProps>`
    ${({ maxWidth, maxHeight }) => css`
        max-width: ${maxWidth ? `${maxWidth/10}rem` : '100%'};
        max-height: ${maxHeight ? `${maxHeight/10}rem` : '100%'};
    `}

    width:100%;
    overflow: auto;

    border: 1px solid ${({ theme }) => theme.colors.gray[100]};
    border-radius: 8px;
`

type TableStyledProps = Pick<TableProps, 'maxWidthTd'>

export const Table = styled('table')<TableStyledProps>`
    ${({ theme, maxWidthTd }) => css`
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        text-align: left;

        font-weight: ${theme.font.weights.medium};

        color: rgba(0, 0, 0, 0.6);

        & thead th {
            position: sticky;
            top: 0;
        }

        th {
            background: #edf9ff;
            color: rgba(0, 0, 0, 0.7);
            font-weight: ${theme.font.weights.medium};
        }

        th {
            padding: 18px 28px;
        }

        th, td {
            white-space: nowrap;
            max-width: ${maxWidthTd ? `${maxWidthTd/10}rem` : '13.5rem'};
            overflow: hidden;
            text-overflow: ellipsis;
        }

        tbody tr {
            transition: 0.5s background-color, 0.5s color;
            will-change: background-color, color;

            border-bottom: 1px solid ${theme.colors.gray[100]};

            &:hover {
                transition: 0.5s background-colo, 0.5s color;
                background: #edf9ff;
                color: rgba(0, 0, 0, 0.7);
            }

            td {
                padding: 24px 28px;
            }
        }
    `}

`
