import { ReactNode } from 'react'

import { Stack, Box, TableColumn, Switch, Circle, Typography  } from '@/components'
// import { Button } from '@/components'

import { theme } from '@/lib/theme'

import { Platform, ProfileSynced } from '@/resources/types'

import { BsGithub as GithubIcon } from 'react-icons/bs'
// import { BsFillPlayFill as PlayIcon } from 'react-icons/bs'
import { IoLogoBitbucket as BitBucketIcon } from 'react-icons/io'
import { AiFillGitlab as GitLabIcon } from 'react-icons/ai'
import { SiAzuredevops as AzureIcon } from 'react-icons/si'

type PlatformIconProps = {
    title: string
    icon: ReactNode
}

function PlatformIcon ({ title, icon }: PlatformIconProps) {
    return (
         <Stack flexDirection='row' alignItems='center' gap={2}>{icon} {title}</Stack>
    )
}

const platformIcons: Record<Platform, ReactNode> = {
    'Github': <PlatformIcon title='Github' icon={<GithubIcon size={24} />} />,
    'Bitbucket': <PlatformIcon title='Bitbucket' icon={<BitBucketIcon size={22} color={theme.colors.brand.blue} />} />,
    'GitLab': <PlatformIcon title='GitLab' icon={<GitLabIcon size={22} color={theme.colors.brand.orangeRed} />} />,
    'AzureDevOps': <PlatformIcon title='AzureDevOps' icon={<AzureIcon size={22} color={theme.colors.brand.blue} />} />,
}

const getDomainPlatform = (platform: Platform) => {
    return {
        'Github': 'https://github.com',
        'GitLab': 'https://gitlab.com',
        'Bitbucket': 'https://bitbucket.com',
        'AzureDevOps': 'https://dev.azure.com'
    }[platform]
}

type ProfileColumnsProps = {
    isTogglingStatus?: boolean
    profileSelectedId?: string
    loadingStartPayloadInitial: boolean
    onToggleStatus?: (id: string, active: boolean) => () => Promise<void>
    onStartPayloadInitial: (token: string) => () => Promise<void>
}

export const getProfileColumns = ({
    onToggleStatus,
    isTogglingStatus,
    // loadingStartPayloadInitial,
    profileSelectedId,
    // onStartPayloadInitial
}: ProfileColumnsProps) => {
    const columns: TableColumn<ProfileSynced>[] = [
        {
            name: 'Plataforma',
            cell: row => platformIcons[row.platform],
            sortable: true,
            sortField: 'platform'
        },
        {
            name: 'Status Payload Inicial',
            cell: row => <Typography tag='span' color={row.initialLoadCompleted ? 'brand.green' : 'brand.blue'}>
                {row.initialLoadCompleted ? 'Concluído' : 'Em andamento'}
            </Typography>
        },
        {
            name: 'Perfil da Plataforma',
            cell: row => (
                <Box
                    component='a'
                    href={`${getDomainPlatform(row.platform)}/${row.platform_login}`}
                    target='_blank'
                    rel="noreferrer noopener"
                    title={row.platform_login}
                    sx={{
                        textDecoration: 'none',
                        color: 'brand.blue',
                        "&:hover": {textDecoration: 'underline'}
                    }}
                >
                    {row.platform_name ? row.platform_name : row.platform_login}
                </Box>
            ),
            sortable: true,
            sortField: 'perfil',
        },
        {
            name: "Usuário do Sistema",
            cell: row => row.owner.name,
        },
        {
            name: 'Status',
            cell: row => <Circle bgcolor={row.active ? 'brand.green' : 'brand.orangeRed'} />,
            sortable: true,
            sortField: 'status',
            grow: 0.5
        },
        // {
        //     button: true,
        //     cell: row => (
        //         <Button
        //             onClick={onStartPayloadInitial(row.token)}
        //             loading={loadingStartPayloadInitial}
        //             disabled={loadingStartPayloadInitial}
        //         >
        //             <PlayIcon size={18}/>Carga Inicial
        //         </Button>
        //     ),
        // },
        {
            button: true,
            cell: row => (
                <Switch
                    checked={row.active}
                    onToggle={onToggleStatus && onToggleStatus(row.id, !row.active)}
                    loading={profileSelectedId === row.id && isTogglingStatus}
                />
            )
        }
    ]

    return columns
}

