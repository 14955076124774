import ContentLoader from 'react-content-loader'

import { Box, Stack } from '@/components';

import { renderElements } from '@/utils/renders';

import { defaultProps } from "../default-props";

export function InputItemContentLoader ({ fullWidth = false, width = 420, height = 42 }) {
    return (
        <Box
            width={['100%', fullWidth ? '100%' : `${width}px`]}
            height={`${height}px` }
        >
            <ContentLoader {...defaultProps} width={fullWidth ? '100%' : width}>
                <Box
                    component='rect'
                    x="0"
                    y="0"
                    rx="15"
                    ry="15"
                    width={['100%', fullWidth ? '100%' : `${width}px`]}
                    height={height}
                />
            </ContentLoader>
        </Box>
    )
}

type InputsContentLoaderProps = {
    items?: number
    inputWidth?: number
    inputFullWidth?: boolean
}

export function InputsContentLoader ({
    items = 3,
    inputWidth,
    inputFullWidth = false
}: InputsContentLoaderProps) {
    return (
        <Stack
            direction={{xs: 'column', md: 'row'}}
            width='100%'
            spacing={6}
            justifyContent={items > 2 ? 'space-between' : ''}
        >
            {renderElements(items, <InputItemContentLoader width={inputWidth} fullWidth={inputFullWidth} />)}
        </Stack>
    )
}
