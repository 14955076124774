import ContentLoader from 'react-content-loader'

import { Stack, Box } from '@/components'

import { defaultProps } from '../default-props'

export function CardContentLoader () {
    return (
        <Box height={365} width='100%'>
            <ContentLoader {...defaultProps} height={365}>
                <rect x="0" y="0" rx="15" ry="15" width="100%" height="365" />
            </ContentLoader>
        </Box>
    )
}

export function TopRankingContentLoader () {
    return (
        <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={4}
            height='max-content'
        >
            <CardContentLoader />
            <CardContentLoader />
            <CardContentLoader />
            <CardContentLoader />
            <CardContentLoader />
        </Stack>
    )
}
