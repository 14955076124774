import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";

import { MembersService } from "@/services";

import { zodResolver } from '@hookform/resolvers/zod'

import * as z from 'zod'

import { toast } from "react-toastify";

import { useSearchOrgByName, useSelectedOrgs } from '@/hooks';

const createMemberSchema = z.object({
    name: z.string().min(1, { message: 'Nome é obrigatório.' }),
    email: z.string()
        .min(1, { message: 'Email é obrigatório'})
        .email({ message: "Email inválido." }),
    password: z.string().min(6, { message: 'A senha precisa conter no minimo 6 caracteres.' }),
    confirmPassword: z.string().min(1, { message: 'Senha de confirmação é obrigatória.'}),
    position: z.string().min(1, {message: 'A escolha da função do membro é obrigatória.'}),
}).refine((data) => data.password === data.confirmPassword, {
    message: "As senhas não correspondem.",
    path: ['confirmPassword']
})

type FormData = z.infer<typeof createMemberSchema>

export function useCreateMember () {
    const { orgs, handleSelectOrg, handleRemoveSelectedOrg } = useSelectedOrgs()

    const queryClient = useQueryClient()

    const resultSearchOrg = useSearchOrgByName()

    const navigate = useNavigate()

    const { mutateAsync: createMemberMutation, isLoading: isCreating } = useMutation(
        MembersService.createMember,
        {onSuccess: () => {
            queryClient.invalidateQueries()
            toast.success('Membro criado com sucesso.')
            navigate('/members')
        },
        onError(error) {
            if (error instanceof Error) {
                toast.error(error.message)
            }
        }}
    )

    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>({
        resolver: zodResolver(createMemberSchema)
    })

    const createMemberSubmit = async (values: FormData) => {
        const organizationIds = orgs.map(({ id }) => id)

        if(organizationIds.length === 0 && values.position === 'Desenvolvedor') {
            toast.error('Adicione ao menos uma organização')
            return
        }

        await createMemberMutation({
            ...values,
            organizationIds
        })

        reset()
    }

    return {
        ...resultSearchOrg,
        isCreating,
        orgs,
        register,
        handleSubmit,
        errors,
        handleSelectOrg,
        handleRemoveSelectedOrg,
        createMemberSubmit
    }
}
