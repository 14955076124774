// import styled, { css } from 'styled-components'

// import { TypographyProps } from '.'

// type TypographyWrapperProps = Pick<
//     TypographyProps,
//     | 'size'
//     | 'color'
//     | 'weight'
//     | 'maxWidth'
//     | 'ellipsis'
// >

// const TypographyModifiers = {
//     ellipsis: () => css`
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//     `,

//     maxWidth: (width: number) => css`
//         max-width: ${`${width/10}rem`};
//     `
// }

// export const TypographyWrapper = styled.h1<TypographyWrapperProps>`
//     ${({
//         theme,
//         size = 'medium',
//         color,
//         weight = 'regular',
//         maxWidth,
//         ellipsis
//     }) => css`
//         color: ${color || theme.colors.black};
//         font-weight: ${theme.font.weights[weight]};
//         font-size: ${theme.font.sizes[size]};

//         ${!!maxWidth && TypographyModifiers.maxWidth(maxWidth)};
//         ${ellipsis && TypographyModifiers.ellipsis()};
//     `}
// `

import { styled } from '@/lib'

import {
    typography,
    maxWidth,
    width,
    spacing,
    palette,
    margin,
    display,
} from '@mui/system'

import { TypographyProps } from '.'

export const TypographyWrapper = styled('h2',{
    shouldForwardProp: (prop) => prop === 'children',
    name: 'Typography',
    slot: 'Root',
    overridesResolver: (props, styles) => [
        props.ellipsis && styles.ellipsis,
    ],
})<TypographyProps>`
    ${palette}
    ${typography}
    ${width}
    ${maxWidth}
    ${spacing}
    ${margin}
    ${display}
`;

