import api from "@/api"

import { User } from "@/resources"

export const UserService = {
    async getProfile(): Promise<User> {
        const { data: profile } = await api.get('/profile')

        return profile
    }
}
