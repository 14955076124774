import { ReactComponent as EmptyStateSvg  } from '@/assets/empty-state.svg'

import * as S from './styles'

export type EmptyStateProps = {
    description?: string
    size?: 'small' | 'medium' | 'large'
}

export function EmptyState ({ description, size }: EmptyStateProps) {
    return (
        <S.Wrapper>
            <S.ImgWrapper size={size}>
                <EmptyStateSvg />
            </S.ImgWrapper>
            <S.Description>{description}</S.Description>
        </S.Wrapper>
    )
}
