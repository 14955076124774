import { ReactNode } from "react";

import { useTheme } from "@/lib";

import { SidebarDrawerProvider } from "@/contexts";

import { Header, SidebarNav, Stack, Box } from "@/components";

type PrivateRoutesLayoutProps = {
    children: ReactNode;
};

export function PrivateRoutesLayout({ children }: PrivateRoutesLayoutProps) {
    const { container } = useTheme()

    return (
        <SidebarDrawerProvider>
            <Header />
            <Stack
                direction='row'
                flex={1}
                spacing={[0, 3]}
                width='100%'
                maxWidth={container}
                mx='auto'
                my={3}
                px={4}
            >
                <SidebarNav />
                <Box
                    flex={1}
                    maxWidth='100%'
                    sx={{
                        height: 'calc(100vh - (1.2rem * 2) - 76px)',
                        overflowY: { md: 'auto' },
                    }}
                >
                    {children}
                </Box>
            </Stack>
        </SidebarDrawerProvider>
    );
}
