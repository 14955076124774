import { FormEvent, useEffect, useState } from "react";

import { useAuth } from "@/contexts";

import { useTheme } from '@/lib'

import { Link, useLocation, useNavigate } from 'react-router-dom'

import {
    Logo,
    Container,
    Button,
    Input,
    LinkButton,
    Typography,
    Stack,
    Box
} from "@/components";

import { BsLinkedin as LinkedinIcon } from 'react-icons/bs'

export default function Login() {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const { isAuthenticating, linkedInLoginLoading, linkedInLogin, login } = useAuth();

    const { colors } = useTheme()

    const location = useLocation()
    const navigate = useNavigate()

    const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        await login({ email, password });
    }

    const handleLinkedInLogin = () => {
        const CLIENT_ID = process.env.REACT_APP_LINKEDIN_OAUTH_CLIENT_ID
        const REDIRECT = process.env.REACT_APP_LINKEDIN_OAUTH_REDIRECT
        const SCOPES = 'r_liteprofile%20r_emailaddress%20'
        const URL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT}&scope=${SCOPES}`
        window.location.href = URL
    }

    useEffect(() => {
        if(!location.search.includes('code')) return

        async function linkedInLinkedin () {
            const params = new URLSearchParams(location.search)
            const code = params.get('code')
            if(!code) return

            navigate({search: new URLSearchParams({}).toString()}, { replace: true })
            linkedInLogin(code)
        }

        linkedInLinkedin()
    }, [location, navigate, linkedInLogin])

    return (
        <Stack justifyContent='center' alignItems='center' height='100vh'>
            <Container>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems='center'
                    justifyContent={['space-evenly', 'space-around']}
                    height='100%'
                    p={6}
                >
                    <Stack justifyContent='center' alignItems='center'>
                        <Logo size='huge' />
                    </Stack>
                    <Stack
                        component='form'
                        onSubmit={handleLogin}
                        maxWidth={420}
                        width='100%'
                        bgcolor='white'
                        p={6}
                        justifyContent='space-evenly'
                        borderRadius='8px'
                        border="1px solid"
                        borderColor='gray.100'
                    >
                        <Box mb={8}>
                            <Typography tag='h3' fontSize={20}>Faça seu login</Typography>
                        </Box>
                        <Stack spacing={4}>
                            <Input
                                type='email'
                                placeholder="Email"
                                onChange={e => setEmail(e.target.value)}
                            />
                            <Input
                                placeholder="Password"
                                type="password"
                                onChange={e => setPassword(e.target.value)}
                            />
                        </Stack>
                        <Stack spacing={8} alignItems='center' mt={8}>
                            <Stack width='100%' gap={2}>
                                <Button
                                    fullWidth
                                    disabled={isAuthenticating}
                                    type="submit"
                                    loading={isAuthenticating}
                                >
                                    {isAuthenticating ? 'Autenticando...' : 'Entrar'}
                                </Button>
                                <Button
                                    type='button'
                                    fullWidth
                                    onClick={handleLinkedInLogin}
                                    loading={linkedInLoginLoading}
                                    disabled={linkedInLoginLoading}
                                >
                                    Entrar com <LinkedinIcon size={20} />
                                </Button>
                            </Stack>
                            <Stack direction='row' alignItems='baseline' spacing={2}>
                                <Typography tag='p' fontSize="xs" fontWeight='medium'>
                                    Esqueceu sua senha ?
                                </Typography>
                                <Link to='/recover-password'>
                                    <LinkButton
                                        weight='bold'
                                        type="button"
                                        size='xs'
                                        color={colors.brand.orangeRed}
                                    >
                                        Clique aqui
                                    </LinkButton>
                                </Link>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
}
