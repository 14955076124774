import { createTheme, createStyled } from '@mui/system'

import { colors, font  } from './foundations'

export {
    ThemeProvider,
    useTheme,
    css,
    keyframes,
} from '@mui/system'

const defaultTheme = {
    spacing: 4,
    container: '192rem',
    palette: { ...colors },
    typography: {
        ...font.fontSizes,
        ...font.weights
    },
    font,
    colors,
}

export const theme = createTheme({
    spacing: 4,
    container: '192rem',
    palette: { ...colors },
    typography: {
        ...font.fontSizes,
        ...font.weights
    },
    font,
    colors,
    components: {
        Typography: {
          styleOverrides: {
            root: {},
          },
          variants: [
            {
              props: { ellipsis: true },
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              },
            },
          ],
        },
      },
})

export const styled = createStyled({ defaultTheme: theme })

export type CustomTheme = typeof defaultTheme
