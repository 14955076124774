import { Spinner } from '@/components'

import * as S from './styles'

export type SwitchProps = {
    checked: boolean
    loading?: boolean
    onToggle?: () => void
}

export function Switch ({
    checked,
    loading,
    onToggle
}: SwitchProps) {
    return (
        <S.Switch
            checked={checked}
            $isLoading={loading}
            disabled={loading}
            onClick={onToggle}
        >
            {loading
                ? <Spinner size='1.5rem' color='black' />
                : <S.Thumb />
            }
        </S.Switch>
    )
}
