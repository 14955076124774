import { ReactNode } from 'react'
import { ReactTabsFunctionComponent, Tab, TabProps, } from 'react-tabs'

type CustomTabProps = TabProps & {
    icon?: ReactNode
}

export const CustomTab: ReactTabsFunctionComponent<CustomTabProps> = ({
    children,
    icon,
    ...props
}) => {
    return (
        <Tab {...props}>
            {!!icon && icon}
            {children}
        </Tab>
    )
}

CustomTab.tabsRole = 'Tab'
