import { max } from 'date-fns'

import { formattedHours, formattedDate } from '@/utils'

export const getLastDateOrHoursFormatted = <T>(
    data: T[],
    extractDate: (item: T) => string,
    type: 'date' | 'hour',
    msgError: string
): string => {

    try {
        const lastDate = max(data.map((item)  => new Date(extractDate(item))))?.toISOString()

        return type === 'hour' ? formattedHours(lastDate) : formattedDate(lastDate)
    } catch (error) {
        return msgError
    }
}
