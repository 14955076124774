import { ChangeEvent } from 'react'

import { Button, SearchPopover, Badge } from '@/components'

import { platformColors } from '@/lib'

import { Org } from '@/resources'

import { IoAddOutline as AddIcon } from 'react-icons/io5'

interface SearchOrgsProps {
    orgs: Org[] | null | undefined
    orgName: string
    isLoadingSearchOrg: boolean
    onOrgNameChange:  (e: ChangeEvent<HTMLInputElement>) => void
    onSelectOrg: (org: Org) => () => void
}

export function SearchOrgs({
    orgs,
    orgName,
    isLoadingSearchOrg,
    onOrgNameChange,
    onSelectOrg
}: SearchOrgsProps) {
    return (
        <SearchPopover
            trigger={
                <Button>
                    <AddIcon size={16} /> Adicionar
                </Button>
            }
            value={orgName}
            onChange={onOrgNameChange}
            loading={isLoadingSearchOrg}
            noData={!(orgs && orgs.length)}
            placeholder='Pesquisar organização(ões)'
        >
            {orgs &&
                orgs.map(org => (
                    <SearchPopover.Item onClick={onSelectOrg(org)} key={org.id}>
                        {org.name}
                        <Badge bgcolor={platformColors[org.platform]}>
                            {org.platform}
                        </Badge>
                    </SearchPopover.Item>
            ))}
        </SearchPopover>
    );
}
