import { ChangeEvent, useState, useCallback } from "react"

import { useQuery } from '@tanstack/react-query'

import {useDebounce, useFilterPlatform, usePagination, useSortRepos, useUpdateEffect } from "@/hooks"

import { ReposService } from "@/services"

import { toast } from "react-toastify"

type FiltersType = {
    repoName: string
    date?: Date
}

export function useRepos () {
    const [isSearching, setIsSearching] = useState(false)

    const { currentPage, rowsPerPage, handlePageChange, handleRowsPerPage } = usePagination(1)

    const [filters, setFilters] = useState<FiltersType>({ repoName: '' })

    const filtersDebounced = useDebounce(filters)

    const { platforms, handleFilterPlatform } = useFilterPlatform()

    const { sort, handleSort } = useSortRepos()

    const {status, data} = useQuery([
        'repos',
        platforms,
        currentPage,
        rowsPerPage,
        filtersDebounced,
        sort
    ],
        async () => ReposService.getRepos(
            platforms,
            filtersDebounced.repoName,
            filtersDebounced.date ? filtersDebounced.date.toISOString() : '',
            sort,
            currentPage,
            rowsPerPage
    ),{
        refetchOnWindowFocus: false,
        onError() {
            toast.error('Ocorreu um erro ao buscar os Repositórios')
        }
    })

    useUpdateEffect(() => {
        if (filters.repoName || !!filters.date) {
            setIsSearching(true)
            handlePageChange({ selected: 1 })
            return
        }

        setIsSearching(false)
    }, [filters])

    const handleFilterRepoNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setFilters(oldFilters => ({ ...oldFilters, repoName: e.target.value }))
    }, [])

    const handleFilterRepoDateChange = useCallback((value: Date) => {
        setFilters(oldFilters => ({ ...oldFilters, date: value }))
    }, [])

    const handleResetRepoName = useCallback(() => {
        setFilters(oldFilters => ({ ...oldFilters, repoName: '' }))
    }, [])

    const handlePerRowsChange = useCallback((newPerPage: number, page: number) => {
        handleRowsPerPage(newPerPage)
        handlePageChange({ selected: page })
	}, [handlePageChange, handleRowsPerPage])

    return {
        status,
        isSearching,
        result: data,
        platforms,
        currentPage,
        filters,
        handleFilterRepoNameChange,
        handleFilterRepoDateChange,
        handleResetRepoName,
        handlePageChange,
        handlePerRowsChange,
        handleSort,
        handleFilterPlatform
    }
}
