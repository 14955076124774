import { keyframes } from "styled-components"

export const progress = (value = 100) => keyframes`
    from {
        width: 0;
    }
    to {
        width: ${value}%
    }
`
