import { createGlobalStyle, css, keyframes } from 'styled-components'

import { colors } from './foundations/colors'

export const platformColors = {
    Github: colors.gray[900],
    Bitbucket: colors.brand.blue,
    GitLab: colors.brand.orangeRed,
    AzureDevOps: colors.brand.blue
}

const moveFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
`
export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &::-webkit-scrollbar {
            width: 0.5rem;
            height: 0.5rem;
            overflow: hidden;
        }

        &::-webkit-scrollbar-thumb {
            background: ${({ theme }) => theme.colors.brand.blue};
            border-radius: 0.4rem;
        }
    }

    *,
    *::after,
    *::before {
        box-sizing: border-box;
    }

    html {
        font-size: 62.5%;
    }

    .no-scroll {
        overflow: hidden;
        pointer-events: none;
    }

    #root {
        min-height: 100vh;
        width: 100%;

        display: flex;
        flex-direction: column;
    }

    ${({ theme }) => css`
        body {
            font-size: 1.6rem;
            background-color: ${theme.colors.bg};
        }

        body, input, textarea, button {
            font-family: ${theme.font.family};
        }

        #nprogress .bar {
            background: ${theme.colors.brand.blue} !important;
            border: 2px solid ${theme.colors.brand.blue} !important;

        }
        #nprogress .peg {
            box-shadow: 0 0 10px ${theme.colors.brand.blue}, 0 0 5px ${theme.colors.brand.blue} !important;
        }

        #nprogress .spinner-icon {
            border-top-color: ${theme.colors.brand.blue} !important;
            border-left-color: ${theme.colors.brand.blue} !important;
        }
    `}

    button {
        cursor: pointer;

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    div[data-js='overlays'] {
        width: 100%;
        height: 100%;
    }

    @keyframes modalAnimation {
        from {
            transform: translateY(100%);
            opacity: 0.5;
        }
        to {
            transform: translate(0);
            opacity: 1;
        }
    }

    .react-modal-overlay {
        background-color: rgba(0,0,0,0.4);

        position: fixed;
        inset: 0;

        display: flex;
        align-items: center;
        justify-content: center;

    }

    .react-modal-overlay-drawer {
        background-color: rgba(0,0,0,0.4);

        position: fixed;
        inset: 0;
        z-index: 100;

        animation: ${moveFromLeft} 0.45s ease-in-out;
    }

    .react-modal-content {
        width: 100%;
        max-width: 576px;
        background-color: ${({ theme }) => theme.colors.white};
        padding: 3.2rem;
        border-radius: 5px;

        position: relative;
        margin-inline: 1.6rem;

        outline: 0;
    }

    .react-modal-content-drawer {
        width: 32rem;
        height: 100vh;
        background-color: ${({ theme }) => theme.colors.white};
        position: relative;
        padding-inline: 2.4rem;
        padding-block: 5.6rem;

        display: flex;
        flex-direction: column;
    }

    .react-modal-close {
       display: none;
    }

    .react-tabs__tab-list {
        list-style: none;
        display: flex;
        gap: 1.8rem;

        margin-bottom: 2.4rem;

        @media (max-width: 600px){
            width: 100%;
            maxWidth: 400px;
            overflow-x: auto;
            padding-bottom: 0.8rem;
            white-space: nowrap;
        }
    }

    .react-tabs__tab {
        cursor: pointer;
        padding: 0.6rem;

        display: flex;
        align-items:center;
        gap: 0.6rem;

        transition: color 0.3s ease-in, border-bottom-color 0.2s
    }

    .react-tabs__tab--selected {
        outline: none;
        ${({ theme }) => css`
            border-bottom: 3px solid ${theme.colors.brand.blue};
            color: ${theme.colors.brand.blue};
            transition: color 0.3s ease-in, border-bottom-color 0.2s;
        `}
    }
`
