import { ButtonHTMLAttributes, ReactNode } from 'react'

import { IoIosClose as CloseIcon } from 'react-icons/io'

import * as S from './styles'

type TagButtonProps = {
    children: ReactNode
} & ButtonHTMLAttributes<HTMLButtonElement>

export function TagButton ({ children, ...props }: TagButtonProps) {
    return (
        <S.Wrapper {...props}>
            {children} <CloseIcon size={24} />
        </S.Wrapper>
    )
}
