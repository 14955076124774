import { styled, css } from '@/lib'

import { LinkButtonProps } from '.'

type LinkWrapperProps = Pick<
    LinkButtonProps,
    | 'color'
    | 'size'
    | 'weight'
>

export const LinkWrapper = styled('button')<LinkWrapperProps>`
    ${({ theme, color, size = 'sm', weight }) => css`
        color: ${color || theme.colors.black};
        font-weight: ${weight};
        border: 0;
        background-color: transparent;

        font-size: ${theme.font.fontSizes[size]};

        transition: opacity 0.25s ease-in;

        a {
            color: ${color || theme.colors.black};
            text-decoration: none;

            cursor: pointer;

            &:active {
                color: currentColor
            }
        }

        &:hover {
           opacity: 0.5
        }
    `}
`
