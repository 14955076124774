import { ChangeEvent, useState } from "react"

import { useQuery } from "@tanstack/react-query"

import { useDebounce } from "@/hooks"

import { OrgsService } from "@/services"

export function useSearchOrgByName () {
    const [orgName, setOrgName] = useState('')
    const orgNameDebounced = useDebounce(orgName)

    const result = useQuery(
        ['searchedMember', orgNameDebounced],
        async () => OrgsService.getOrgByName(orgNameDebounced),
        {
            enabled: !!orgNameDebounced,
            refetchOnWindowFocus: false
        }
    )

    const handleOrgNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setOrgName(e.target.value)
    }

    const cleanOrgName = () => setOrgName('')

    return {
        isLoadingSearchOrg: result.isLoading && !!orgNameDebounced,
        researchedOrgData: result.data,
        orgName,
        handleOrgNameChange,
        cleanOrgName
    }
}
