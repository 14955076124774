import { ButtonHTMLAttributes } from 'react'

import type { ThemeFontSizes, ThemeFontWeights } from '@/lib/theme'

import * as S from './styles'

type LinkButtonType =
    | ButtonHTMLAttributes<HTMLButtonElement>

export type LinkButtonProps = {
    color?: string
    size?: ThemeFontSizes
    weight?: ThemeFontWeights
} &  LinkButtonType

export function LinkButton ({ children, ...props}: LinkButtonProps) {
    return (
        <S.LinkWrapper {...props}>
            {children}
        </S.LinkWrapper>
    )
}
