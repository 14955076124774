import styled, { css } from 'styled-components'

import { progress } from '@/lib/theme/animations'

import type { CommitsBarProps } from '.'

export const CommitsBarContainer = styled.div<Omit<CommitsBarProps, 'commit'>>`
    ${({ percent }) => css`
        box-sizing: content-box;

        ${percent === 0 && css`
            width: 25px;
        `}

        ${percent > 0 && percent <= 15 && css`
            width: 50px;
        `}

        ${percent > 15 && css`
            width: ${percent}%;
        `}

        height: 100%;
    `}
`

export const CommitsBarWrapper = styled.div<Omit<CommitsBarProps, 'commit' | 'percent'>>`
    ${({ color }) => css`
        width: 100%;
        height: 100%;
        background-color: ${color};
        display: flex;
        justify-content: center;
        align-items: center;
        animation: ${progress()} 1.2s ease-in-out;
        font-weight: bold;
        white-space: nowrap;
    `}
`
