import { ReactNode, useEffect, useState } from 'react'

import { GoAlert as FailAlertIcon } from 'react-icons/go'
import { FaCheckCircle as SuccessAlertIcon } from 'react-icons/fa'
import { IoClose as CloseIcon } from 'react-icons/io5'

import { theme } from '@/lib/theme'

import * as S from './styles'

interface AlertProps {
    children: ReactNode
    show: boolean
    variant: 'success' | 'error'
    onClose?: () => void
}

export function Alert ({ children, show, variant, onClose }: AlertProps) {
    const [shouldShow, setShouldShow] = useState(show)

    useEffect(() => {
        setShouldShow(show)
    }, [show])

    if(!shouldShow) return null

    return (
        <S.Wrapper variant={variant}>
            {variant === 'success' && <SuccessAlertIcon size={24} color={theme.colors.green[500]}/>}
            {variant === 'error' && <FailAlertIcon size={24} color={theme.colors.red[500]} />}
            <S.CloseIcon onClick={onClose}>
                <CloseIcon size={18} color={theme.colors.gray[700]}/>
            </S.CloseIcon>
            {children}
        </S.Wrapper>
    )
}
