import { styled } from '@/lib'

import { ContainerProps } from '.'

type WrapperProps = Pick<ContainerProps, 'maxWidth'>

export const Wrapper = styled('div')<WrapperProps>`
    max-width: ${({ theme, maxWidth }) => maxWidth
        ? `${maxWidth/10}rem`
        : theme.container};

    width: 100%;
    height: 100%;

    margin: 0 auto;
    padding-inline: 1.2rem;
`
