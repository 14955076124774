import { PaginationContentLoader, Box, Stack } from '@/components'

import ContentLoader from 'react-content-loader'

import { defaultProps } from '../default-props'

export function TableLoader() {
    return (
        <Box>
            <ContentLoader {...defaultProps } height='500px'>
                <rect x="0" y="0" rx="15" ry="15" width="100%" height="500" />
            </ContentLoader>
        </Box>
    )
}

export function TableContentLoader () {
    return (
        <Box>
            <TableLoader />
            <Stack direction='row' justifyContent='flex-end'>
                <PaginationContentLoader />
            </Stack>
        </Box>
    )
}
