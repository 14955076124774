import { ChangeEvent, useCallback, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import {
    usePagination,
    useDebounce,
    useUpdateEffect,
    useSortRankingMembers,
    useSortRankingProjects,
} from "@/hooks";

import { RankingService } from "@/services";
import { CalendarDays, SortDirection } from "@/resources";

type FiltersRanking = {
    member: string;
    project: string;
};

type RankingType = "DEV" | "PO" | "PROJECT";

export type RankingOrder = "best" | "worst";

type OnChangeSelectDays = ChangeEvent<HTMLSelectElement> & {
    target: {
        value: CalendarDays;
    };
};

const rankingTypeMapping: RankingType[] = ["DEV", "PO", "PROJECT"];

const rankingTypeSelectedMapping: Record<RankingType, number> = {
    DEV: 0,
    PO: 1,
    PROJECT: 2,
};

export function useRanking() {
    const [rankingType, setRankingType] = useState<RankingType>("DEV");

    const [rankOrder, setRankOrder] = useState<RankingOrder>("best");

    const { currentPage, rowsPerPage, handlePageChange, handleRowsPerPage } =
        usePagination(1);

    const [isSearching, setIsSearching] = useState(false);

    const [filters, setFilters] = useState<FiltersRanking>({
        member: "",
        project: "",
    });

    const { member, project } = useDebounce(filters);

    const { sortRankingMembers, handleSortRankingMembers } =
        useSortRankingMembers();

    const { sortRankingProjects, handleSortRankingProjects } =
        useSortRankingProjects();

    const [sortDirection, setSortDirection] = useState<SortDirection>("asc");

    const [sortPerCommit, setSortPerCommit] = useState("");

    const [filterDays, setFilterDays] = useState<CalendarDays>("21");

    const rankingDevsOrder = useQuery(
        ["@ranking-devs-order", { rankOrder, filterDays }],
        async () => RankingService.getRankingDevOrder(rankOrder, filterDays),
        {
            enabled: rankingType === "DEV",
            refetchOnWindowFocus: false,
        },
    );

    const rankingDevs = useQuery(
        [
            "@ranking-devs",
            {
                currentPage,
                rowsPerPage,
                member,
                filterDays,
                sortRankingMembers,
            },
        ],
        async () =>
            RankingService.getRankingDevs(
                currentPage,
                rowsPerPage,
                member,
                filterDays,
                sortRankingMembers,
            ),
        {
            enabled: rankingType === "DEV",
            refetchOnWindowFocus: false,
        },
    );

    const rankingProjectsOrder = useQuery(
        ["@ranking-projects-order", { rankOrder, filterDays }],
        async () =>
            RankingService.getRankingProjectsOrder(rankOrder, filterDays),
        {
            enabled: rankingType === "PROJECT",
            refetchOnWindowFocus: false,
        },
    );

    const rankingProjects = useQuery(
        [
            "@ranking-projects",
            { currentPage, project, filterDays, sortRankingProjects },
        ],
        async () =>
            RankingService.getRankingProjects(
                currentPage,
                project,
                filterDays,
                sortRankingProjects,
            ),
        {
            enabled: rankingType === "PROJECT",
            refetchOnWindowFocus: false,
        },
    );

    const rankingPOsOrder = useQuery(
        ["@ranking-pos-order", { rankOrder, filterDays }],
        async () => RankingService.getRankingPOsOrder(rankOrder, filterDays),
        {
            enabled: rankingType === "PO",
            refetchOnWindowFocus: false,
        },
    );

    const rankingPOs = useQuery(
        [
            "@ranking-pos",
            { currentPage, member, filterDays, sortRankingMembers },
        ],
        async () =>
            RankingService.getRankingPOs(
                currentPage,
                member,
                filterDays,
                sortRankingMembers,
            ),
        {
            enabled: rankingType === "PO",
            refetchOnWindowFocus: false,
        },
    );

    useUpdateEffect(() => {
        if (member || project) {
            handlePageChange({ selected: 0 });
            setIsSearching(true);
            return;
        }

        setIsSearching(false);
    }, [member, project]);

    const handleRankingTypeChange = useCallback(
        (index: number) => {
            setRankingType(rankingTypeMapping[index]);
            setSortPerCommit("");
            setSortDirection("asc");

            setFilters({ member: "", project: "" });
            handlePageChange({ selected: 0 });
            setIsSearching(false);
            setFilterDays("21");

            handleSortRankingMembers("rankPosition", "asc");
            handleSortRankingProjects("rankPosition", "asc");
        },
        [handlePageChange, handleSortRankingMembers, handleSortRankingProjects],
    );

    const handleFiltersChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setFilters(oldState => ({
                ...oldState,
                [e.target.name]: e.target.value,
            }));
        },
        [],
    );

    const handleResetFilter = useCallback(
        (filter: "member" | "project") => () => {
            setFilters(oldState => ({ ...oldState, [filter]: "" }));
        },
        [],
    );

    const handleRankOrder = useCallback(
        (order: "best" | "worst") => () => setRankOrder(order),
        [],
    );

    const handleSortPerCommit = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            const commit = e.target.value;

            if (rankingType === "DEV" || rankingType === "PO") {
                handleSortRankingMembers(commit, sortDirection);
            }

            handleSortRankingProjects(commit, sortDirection);

            setSortPerCommit(commit);
        },
        [
            handleSortRankingMembers,
            handleSortRankingProjects,
            sortDirection,
            rankingType,
        ],
    );

    const handleSortDirection = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            const dir = e.target.value as SortDirection;

            if (rankingType === "DEV" || rankingType === "PO") {
                handleSortRankingMembers(sortPerCommit, dir);
            }

            handleSortRankingProjects(sortPerCommit, dir);

            setSortDirection(dir);
        },
        [
            rankingType,
            sortPerCommit,
            handleSortRankingMembers,
            handleSortRankingProjects,
        ],
    );

    const handleFilterDays = useCallback(
        (e: OnChangeSelectDays) => setFilterDays(e.target.value),
        [],
    );

    const isLoadingRankingMapping = {
        DEV: rankingDevs.isLoading,
        PO: rankingPOs.isLoading,
        PROJECT: rankingProjects.isLoading,
    };

    const isSuccessRankingMapping = {
        DEV: rankingDevs.isSuccess,
        PO: rankingPOs.isSuccess,
        PROJECT: rankingProjects.isSuccess,
    };

    const isLoadingRankingsOrder = {
        DEV: rankingDevsOrder.isLoading,
        PO: rankingPOsOrder.isLoading,
        PROJECT: rankingProjectsOrder.isLoading,
    };

    function getTopRankingOrder() {
        switch (rankingType) {
            case "DEV":
                return rankingDevsOrder.data;
            case "PO":
                return rankingPOsOrder.data;
            case "PROJECT":
                return rankingProjectsOrder.data;
            default:
                throw new Error("");
        }
    }

    return {
        topRankingOrder: getTopRankingOrder(),
        isLoading: isLoadingRankingMapping[rankingType],
        isSuccess: isSuccessRankingMapping[rankingType],
        isSearching,
        isLoadingRankingsOrder: isLoadingRankingsOrder[rankingType],
        rankingDevs: rankingDevs.data,
        rankingPOs: rankingPOs.data,
        rankingProjects: rankingProjects.data,
        filters,
        rankOrder,
        rankingType,
        rankingTypeSelected: rankingTypeSelectedMapping[rankingType],
        handleRankingTypeChange,
        currentPage,
        handlePageChange,
        handleRowsPerPage,
        handleFiltersChange,
        handleResetFilter,
        handleRankOrder,
        handleSortRankingMembers,
        handleSortRankingProjects,
        sortPerCommit,
        sortDirection,
        handleSortPerCommit,
        handleSortDirection,
        filterDays,
        handleFilterDays,
    };
}
