import { styled, css } from '@/lib'

export const MainWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 0.6rem
`

export const InputWrapper = styled('div')`
    display: flex;
    align-items: center;

    svg {
        color: ${({ theme }) => theme.colors.gray[200]};
    }

    svg.reset-icon {
        color: currentColor;
        cursor: pointer;
    }
`

export const IconWrapper = styled('div')`
    margin-left: -3.8rem;
    display: flex;
    align-items:center;
`

export const Message = styled('p')`
    font-size: 1.4rem;
`

export const ErrorMessage = styled(Message)`
    ${({ theme }) => css`
        color: ${theme.colors.brand.orangeRed};
        font-weight: ${theme.font.weights.medium};
    `}
`
