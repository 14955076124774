import { FormEvent, useState } from "react"

import { useNavigate } from "react-router-dom"

import { useMutation } from "@tanstack/react-query"

import {
    useMembersRepos,
    useSearchMemberByName,
    useSearchOrgByName,
    useSearchProductOwnerByName,
    useSearchRepoByName,
} from '@/hooks'

import { ProjectsService } from "@/services"

import { AxiosError } from "axios"

import { toast } from "react-toastify"
import {Org} from "@/resources"

type OnFormSubmitEvent = FormEvent<HTMLFormElement> & {
    currentTarget: {
        projectName: HTMLInputElement
    }
}

export function useCreateProject () {
    const navigate = useNavigate()

    const {
        productOwners,
        members,
        repos,
        productOwnersIds,
        membersIds,
        reposIds,
        handleAddProductOwner,
        handleRemoveProductOwner,
        handleAddMember,
        handleAddRepo,
        handleRemoveMember,
        handleRemoveRepo
    } = useMembersRepos()

    const [selectedOrg, setSelectedOrg] = useState<Org | null>(null)

    const resultSearchOrg = useSearchOrgByName()

    const resultProductOwners = useSearchProductOwnerByName()

    const { result: resultMembers, ...restMembersSearched } = useSearchMemberByName()

    const { result: resultRepos, ...restReposSearched } = useSearchRepoByName()

    const createProjectMutation = useMutation(
        ProjectsService.createProject,
        {
            onSuccess() {
                toast.success('Projeto criado com sucesso')
                navigate('/projects')
            },
            onError(e) {
                if (e instanceof AxiosError) {
                    toast.error(e.response?.data.message)
                }
            },
        }
    )

    const handleCreateProjectSubmit = async (e: OnFormSubmitEvent) => {
        e.preventDefault()

        const { projectName: { value: projectName } } = e.currentTarget

        if(!projectName) {
            toast.error('Campo nome do projeto é obrigatório')
            return
        }

        if(!selectedOrg) {
            toast.error('Adicione a Organização do Projeto')
            return
        }

        if (membersIds.length === 0) {
            toast.error('Adicione no mínimo um membro ao projeto.')
            return
        }

        await createProjectMutation.mutateAsync({
            name: projectName,
            productOwnersIds,
            membersIds,
            repositoriesIds: reposIds,
            organizationId: selectedOrg.id
        })
    }

    const handleSelectOrg = (org: Org) => () => {
        if(org.id === selectedOrg?.id) {
            toast.error('Essa organização já foi selecionada.')
            return
        }

        setSelectedOrg(org)
    }

    return {
        isCreatingProject: createProjectMutation.isLoading,
        ...resultProductOwners,
        resultMembers,
        ...restMembersSearched,
        members,
        handleAddMember,
        handleRemoveMember,
        repos,
        resultRepos,
        ...restReposSearched,
        handleAddRepo,
        handleRemoveRepo,
        handleCreateProjectSubmit,
        ...resultSearchOrg,
        selectedOrg,
        handleSelectOrg,
        productOwners,
        handleAddProductOwner,
        handleRemoveProductOwner
    }
}
