import { styled, css } from '@/lib'

import { ListProps } from '.'

type ListContainerProps = Pick<ListProps, 'maxWidth' | 'maxHeight'>

export const ListContainer = styled('ul')<ListContainerProps>`
    list-style: none;
    padding-inline: 0.6rem;

    ${({ maxWidth, maxHeight }) => css`
        max-height: ${maxHeight ? `${maxHeight/10}rem` : '100%'};
        max-width: ${
            maxWidth && typeof maxWidth === 'number' ? `${maxWidth/10}rem` : maxWidth
        };

        ${!!maxHeight && css`overflow: auto;`}
    `}

    @media only screen and (max-width: 928px) {
        max-width: 100%;
    }
`

export const ListItemContainer = styled('li')`
    width: 100%;
    border-radius: 14px;
    padding: 1.2rem 2rem;

    overflow-x: auto;

    &:not(:last-child) {
        margin-bottom: 1.2rem;
    }

    transition: 0.3s transform, 0.3s box-shadow;
    will-change: transform, box-shadow;

    &:hover {
        transition: 0.3s transform, 0.3s box-shadow;
        transform: translateY(-4px);
        box-shadow: 0px 4px 3px 0px rgba(0,0,0,0.125);
    }

    ${({ theme }) => css`
        border: 1px solid ${theme.colors.gray[200]};
        background-color: ${theme.colors.gray[50]};
    `}
`

export const ListItemBoxContainer = styled('div')`
    display: flex;
    gap: 0.8rem;
`

export const ListBoxLabel = styled('p')`
    font-weight: ${({ theme }) => theme.font.weights.medium};
`
