import { ButtonHTMLAttributes } from 'react'

import { AiFillEdit as EditIcon } from 'react-icons/ai'

import { Button } from '@/components'

type EditButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'>

export function EditButton (props: EditButtonProps) {
    return (
        <Button  {...props} title='Editar' type='button'size='small'>
            <EditIcon size={15} />
        </Button>
    )
}
