import { MdAutoFixHigh as FixIcon } from 'react-icons/md'
import {
    BsStars as FeatIcon,
    BsRecycle as RefactorIcon,
    BsHammer as ChoreIcon,
    BsCheckLg as ConventionalIcon,
} from 'react-icons/bs'

import { IoMdAlert as AlertIcon } from 'react-icons/io'

import { Avatar, Box, Stack, Tooltip } from '@/components'

import { theme } from '@/lib'

import * as S from './styles'

export interface RankCardProps {
    active: boolean
    classification: number,
    memberName: string
    score: number
    feat: number
    fix: number
    refactor: number
    chore: number
    percentConv: number
    totalCommits: number
}

export function RankCard ({
    active,
    classification,
    memberName,
    feat,
    fix,
    refactor,
    chore,
    percentConv,
    score,
    totalCommits
}: RankCardProps) {
    return (
        <S.Wrapper active={active}>
            {!active && (
                <Box alignSelf='flex-end'>
                    <Tooltip
                        trigger={<div><AlertIcon color={theme.colors.red['500']} size={24} /></div>}
                    >
                        <div>Não mais rankeado na próxima atualização.</div>
                    </Tooltip>
                </Box>
            )}
            <S.AvatarWrapper>
                <Avatar size='large' name={memberName} />
                <S.ClassificationBadge position={classification}>
                    <span>{classification}°</span>
                </S.ClassificationBadge>
            </S.AvatarWrapper>
            <S.Name>{memberName}</S.Name>
            <Stack gap={2} my={6}>
                <S.ScoreInfo>{score} Score</S.ScoreInfo>
                <S.ScoreInfo>{totalCommits} Total Commits</S.ScoreInfo>
            </Stack>
            <S.Separator />
            <S.Commits>
                <S.Commit>
                    <S.CommitTitle>Conventional</S.CommitTitle>
                    <S.IconWrapperCommit>
                        <ConventionalIcon size={20} />
                        <S.CommitAmount>{percentConv.toFixed(2)}%</S.CommitAmount>
                    </S.IconWrapperCommit>
                </S.Commit>
                <S.Commit>
                    <S.CommitTitle>Feat</S.CommitTitle>
                    <S.IconWrapperCommit>
                        <FeatIcon size={20} />
                        <S.CommitAmount>{feat}</S.CommitAmount>
                    </S.IconWrapperCommit>
                </S.Commit>
                <S.Commit>
                    <S.CommitTitle>Fix</S.CommitTitle>
                    <S.IconWrapperCommit>
                        <FixIcon size={20} />
                        <S.CommitAmount>{fix}</S.CommitAmount>
                    </S.IconWrapperCommit>
                </S.Commit>
                <S.Commit>
                    <S.CommitTitle>Refactor</S.CommitTitle>
                    <S.IconWrapperCommit>
                        <RefactorIcon size={20} />
                        <S.CommitAmount>{refactor}</S.CommitAmount>
                    </S.IconWrapperCommit>
                </S.Commit>
                <S.Commit>
                    <S.CommitTitle>Chore</S.CommitTitle>
                    <S.IconWrapperCommit>
                        <ChoreIcon size={20} />
                        <S.CommitAmount>{chore}</S.CommitAmount>
                    </S.IconWrapperCommit>
                </S.Commit>
            </S.Commits>
        </S.Wrapper>
    )
}
