import styled from 'styled-components'

import {
    Trigger as PrimitiveTrigger,
    Content as PrimitiveContent,
    Arrow as PrimitiveArrow
} from '@radix-ui/react-popover'

export const Trigger = styled(PrimitiveTrigger)`
    border: 0;
    outline: 0;
    background-color: transparent;
    cursor: pointer;
`

export const Content = styled(PrimitiveContent)`
    outline: none;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 1.6rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.brand.blue};
`

export const Arrow = styled(PrimitiveArrow)`
    fill: ${({ theme }) => theme.colors.brand.blue};
`
