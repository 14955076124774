import styled, { css } from 'styled-components'

import { transparentize } from 'polished'

import {
    Trigger as RadixTrigger,
    Content as RadixContent,
    Arrow as RadixArrow
} from '@radix-ui/react-tooltip'

export const Trigger = styled(RadixTrigger)``

export const Arrow = styled(RadixArrow)`
    fill: ${({ theme }) => theme.colors.brand.blue};
`

export const Content = styled(RadixContent)`
    ${({ theme }) => css`
        border: 0;
        background-color: ${theme.colors.white};
        border-radius: 5px;
        padding: 0.4rem 1.2rem;
        min-width: 85px;
        color: ${theme.colors.black};
        box-shadow: 0px 0px 0px 1px ${transparentize(0.5, theme.colors.brand.blue)};
    `}
`
