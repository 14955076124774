import * as S from './styles'

export type AvatarProps = {
    name?: string
    src?: string
    size?: 'small' | 'medium' | 'large'
}

function getInitials(name: string) {
    const separator = name.includes('-') ? '-' : ' '

    const [firstName, lastName] = name.split(separator)

    if(firstName && lastName) {
        return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
    }

    return name.charAt(0).toUpperCase()
}

export function Avatar ({name, src, size = 'small'}: AvatarProps) {
    return (
        <S.AvatarContainer size={size}>
            {name
                ?  <p>{getInitials(name)}</p>
                :  <img src={src} alt={name} />
            }
        </S.AvatarContainer>
    )
}

