import { Member, ProductOwner, Repo } from "@/resources"

type ActionTypes =
    | { type: 'ADD_MEMBER', member: Member }
    | { type: 'REMOVE_MEMBER', id: string }
    | { type: 'ADD_PRODUCT_OWNER', productOwner: ProductOwner }
    | { type: 'REMOVE_PRODUCT_OWNER', id: string }
    | { type: 'ADD_REPO', repo: Repo }
    | { type: 'REMOVE_REPO', id: string }
    | {type: 'SET_DATA', members: Member[], repos: Repo[] }


type State = {
    members: Member[]
    productOwners: ProductOwner[]
    membersIds: string[]
    productOwnersIds: string[]
    repos: Repo[]
    reposIds: string[]
}

export const initialState: State = {
    productOwners: [],
    productOwnersIds: [],
    members: [],
    membersIds: [],
    repos: [],
    reposIds: []
}

export function reducer (state: State, action: ActionTypes) {
    switch (action.type) {
        case 'SET_DATA':
            return {
                ...state,
                ...action,
                membersIds: action.members.map(({ id }) => id),
                reposIds: action.repos.map(({ id }) => id)
            }
        case 'ADD_MEMBER':
            return {
                ...state,
                members: state.members.concat(action.member),
                membersIds: state.membersIds.concat(action.member.id)

            }
        case 'REMOVE_MEMBER':
            return {
                ...state,
                members: state.members.filter(member => member.id !== action.id),
                membersIds: state.membersIds.filter(memberId => memberId !== action.id)
            }
        case 'ADD_PRODUCT_OWNER':
            return {
                ...state,
                productOwners: state.productOwners.concat(action.productOwner),
                productOwnersIds: state.productOwnersIds.concat(action.productOwner.id)
            }
        case 'REMOVE_PRODUCT_OWNER':
            return {
                ...state,
                productOwners: state.productOwners.filter(productOwner => productOwner.id !== action.id),
                productOwnersIds: state.productOwnersIds.filter(productOwnerId => productOwnerId !== action.id)
            }
        case 'ADD_REPO':
            return {
                ...state,
                repos: state.repos.concat(action.repo),
                reposIds: state.reposIds.concat(action.repo.id)
            }
        case 'REMOVE_REPO':
            return {
                ...state,
                repos: state.repos.filter(repo => repo.id !== action.id),
                reposIds: state.reposIds.filter(repoId => repoId !== action.id)
            }
        default:
            throw new Error('')
    }
}
