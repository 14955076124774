// import type { ThemeFontSize, ThemeFontWeights } from '@/styles/theme'

// import * as S from './styles'

// export type TypographyProps = {
//     as?: keyof JSX.IntrinsicElements
//     children: ReactNode
//     size?: ThemeFontSize
//     color?: string
//     weight?: ThemeFontWeights
//     maxWidth?: number
//     ellipsis?: boolean
// } & HTMLAttributes<HTMLElement>

// export function Typography ({
//     children,
//     ...props
//  }: TypographyProps) {
//     return (
//         <S.TypographyWrapper {...props}>
//             {children}
//         </S.TypographyWrapper>
//     )
// }

import { forwardRef, HTMLAttributes } from 'react'

import { BoxProps } from '@mui/system'

import * as S from './styles'

export type TypographyProps =  HTMLAttributes<
    | HTMLHeadingElement
    | HTMLSpanElement
    | HTMLParagraphElement
> & BoxProps & {
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'
    ellipsis?: boolean
}

export const Typography = forwardRef<HTMLHeadingElement, TypographyProps>(({
    children,
    tag = 'span',
    ...props
}, ref) => (
    <S.TypographyWrapper {...props} as={tag} ref={ref}>
        {children}
    </S.TypographyWrapper>
));
