import React from "react";

import { AppRoutes } from "@/routes";

import { ToastContainer } from 'react-toastify'

import { GlobalStyles } from "@/lib";

import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <>
            <GlobalStyles />
            <AppRoutes />
            <ToastContainer />
        </>
    );
}

export default App;
