import styled, { css, keyframes } from 'styled-components'

type WrapperProps = {
    variant: 'success' | 'error'
}

const fadeIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(8px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`

const WrapperModifiers = {
    success: () => css`
        background-color: ${({ theme }) => theme.colors.green[200]};
    `,
    error: () => css`
        background-color: ${({ theme }) => theme.colors.red[200]};
    `
}

export const Wrapper = styled.div<WrapperProps>`
    ${({ theme, variant }) => css`
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;
        color: ${theme.colors.gray[700]};
        padding: 12px;
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        font-size: 1.5rem;

        transition: 0.3s;

        will-change: transform;

        animation: ${fadeIn} 0.3s;

        &:hover {
            transform: translateY(-3px);
        }

        ${WrapperModifiers[variant]}
    `}
`

export const CloseIcon = styled.button`
    border: 0;
    background-color: transparent;
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`
