import { ChangeEvent, MouseEvent, forwardRef, useState } from 'react'
import { Box, Stack, Typography, TypographyProps } from '@/components'
import { FaPen as PenIcon } from 'react-icons/fa'
import { MdClose as CloseIcon } from 'react-icons/md'

interface TypographyEditableProps extends Omit<TypographyProps, 'children'> {
    text?: string
    onTextChange?: (value: string) => void
}

export const TypographyEditable = forwardRef<HTMLHeadingElement, TypographyEditableProps>(({
    tag = 'h2',
    text = '',
    onTextChange = () => null,
    ...props
}, ref) => {
    const [edit, setEdit] = useState(false)

    const [inputValue, setInputValue] = useState(text)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        e.stopPropagation()
        onTextChange(e.target.value)
        setInputValue(e.target.value)
    }

    const handleToggleEdit = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setEdit(edit => !edit)
    }

    return (
        <Stack
            direction='row'
            gap={4}
            alignItems='center'
            justifyContent='center'
        >
            {!edit && <Typography {...props} tag={tag} fontSize='xl' ref={ref}>{inputValue}</Typography>}
            {edit && (
                 <Box
                    component='input'
                    sx={{
                        outline: 0,
                        border: 0,
                        fontSize: '2rem',
                        fontWeight: 700,
                        backgroundColor: 'transparent',
                        width: '100%',
                    }}
                    autoFocus
                    value={inputValue}
                    onChange={handleChange}
                    size={inputValue.length}
                />
            )}
             <Stack
                 component='button'
                 border={0}
                 bgcolor={!edit ? 'brand.blue' : 'brand.orangeRed'}
                 borderRadius={1}
                 justifyContent='center'
                 alignItems='center'
                 onClick={handleToggleEdit}
                 p={1.5}
             >
                 {edit && <CloseIcon size={14} color='white' />}
                 {!edit &&  <PenIcon size={12} color='white' />}
            </Stack>
        </Stack>
    )
})
