import { useParams } from "react-router-dom"

import {
    useSearchMemberByName,
    useSearchOrgByName,
    useSearchProductOwnerByName,
    useSearchRepoByName,
    useSortMembers,
    useSortRepos
} from "@/hooks"

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { MemberWithCommits, Org, ProductOwner, Project, Repo, UpdateProjectData } from "@/resources"

import { ProjectsService } from "@/services"

import { toast } from "react-toastify"
import {useCallback} from "react"

export function useEditProject () {
    const { id: projectId } = useParams<{id: string}>()

    const queryClient = useQueryClient()

    const resultSearchOrg = useSearchOrgByName()

    const { sort: sortMembers, handleSort: handleSortMembers } = useSortMembers()

    const { sort: sortRepos, handleSort: handleSortRepos } = useSortRepos()

    const { data: project, status, refetch, isRefetching } = useQuery(['project', { projectId, sortMembers, sortRepos }], async () => {
        if(!projectId) return undefined

        return ProjectsService.getProjectById(projectId, sortMembers, sortRepos)
    },
    { refetchOnWindowFocus: false, enabled: !!projectId })

    const { mutateAsync, isLoading: isSubmitting } = useMutation(ProjectsService.updateProject, {
        async onSuccess(){
            refetch()
            toast.success('Projeto atualizado com sucesso: ')
        }
    })

   const resultProductOwners = useSearchProductOwnerByName()

    const { result: resultMemberSearch, ...memberSearchProps } = useSearchMemberByName()

    const { result: resultRepoSearch, ...repoSearchProps } = useSearchRepoByName()

    const handleAddProductOwner = (productOwner: ProductOwner) => () => {
        queryClient.setQueriesData<Project>(['project', { projectId }], oldData => {
            if (!oldData) return oldData

            const productOwnerId = productOwner.id

            const productOwnerAlreadyAdded = oldData.productOwners.find(item => item.id === productOwnerId)

            if(productOwnerAlreadyAdded) {
                toast.error('Product Owner selecionado já está adicionado ao projeto.')
                return oldData
            }

            return {
                ...oldData,
                productOwners: oldData?.productOwners.concat(productOwner)
            }
        })
    }

    const handleProjectNameChange = (newName: string) => {
         queryClient.setQueriesData<Project>(
          ['project', { projectId }],
              (oldData) => oldData ? {
                ...oldData,
                name: newName
              } : oldData
        )
    }

    const handleRemoveProductOwner = (id: string) => () => {
        queryClient.setQueriesData<Project>(['project', { projectId }], oldData => {
            if (!oldData) return oldData

            return {
                ...oldData,
                productOwners: oldData.productOwners.filter(productOwner => productOwner.id !== id)
            }
        })
    }

    const handleAddMember = (member: MemberWithCommits) => () => {
        queryClient.setQueriesData<Project>(['project', { projectId }], oldData => {
            if (!oldData) return oldData

            const memberId = member.id

            const memberAlreadyAdded = oldData.members.find(item => item.id === memberId)

            if(memberAlreadyAdded) {
                toast.error('Desenvolvedor selecionado já está adicionado ao projeto.')
                return oldData
            }

            return {
                ...oldData,
                members: oldData?.members.concat(member)
            }
        })
    }

    const handleRemoveMember = (id: string) => () => {
        queryClient.setQueriesData<Project>(['project', { projectId }], oldData => {
            if (!oldData) return oldData

            return {
                ...oldData,
                members: oldData.members.filter(member => member.id !== id)
            }
        })
    }

    const handleAddRepo = (repo: Repo) => () => {
        queryClient.setQueriesData<Project>(['project', { projectId }], oldData => {
            if (!oldData) return oldData

            const repoId = repo.id

            const repoAlreadyAdded = oldData.repositories.find(item => item.id === repoId)

            if(repoAlreadyAdded) {
                toast.error('Repositório selecionado já está adicionado ao projeto')
                return oldData
            }

            return {
                ...oldData,
                repositories: oldData?.repositories.concat(repo)
            }
        })
    }

    const handleRemoveRepo = (id: string) => () => {
        queryClient.setQueriesData<Project>(['project',  { projectId }], oldData => {
            if (!oldData) return oldData

            return {
                ...oldData,
                repositories: oldData.repositories.filter(repo => repo.id !== id)
            }
        })
    }

    const handleChangeOrganization = useCallback((organization: Org) => () => {
        queryClient.setQueriesData<Project>(['project', { projectId }], oldData => {
            if (!oldData) return oldData

            return { ...oldData, organization }
        })
    }, [queryClient, projectId])

    const submitEditProject = async (project: UpdateProjectData) => {
        await mutateAsync(project)
    }

    return {
        status,
        isSubmitting,
        isRefetching,
        project,
        handleAddMember,
        handleRemoveMember,
        handleAddRepo,
        handleRemoveRepo,
        memberSearchData: resultMemberSearch,
        ...memberSearchProps,
        repoSearchData: resultRepoSearch.data,
        ...repoSearchProps,
        submitEditProject,
        handleSortRepos,
        handleSortMembers,
        ...resultSearchOrg,
        handleChangeOrganization,
        ...resultProductOwners,
        handleAddProductOwner,
        handleRemoveProductOwner,
        handleProjectNameChange
    }
}
