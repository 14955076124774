import {DataTable, Typography, TableColumn } from "@/components";
import {theme} from "@/lib";
import { ProfileSynced, ProfileSyncedLogs } from "@/resources";
import {formattedDate, formattedHours} from "@/utils";
import {Stack} from "@mui/system";
import { ExpanderComponentProps } from "react-data-table-component";

const statusLogColors: Record<ProfileSyncedLogs['cron']['status'], string> = {
    'Agendado': theme.colors.brand.blue,
    'Em execução': theme.colors.brand.blue,
    'Finalizado': theme.colors.brand.green
}

const columns: TableColumn<ProfileSyncedLogs>[] = [
    {
        name: 'CronJob',
        cell: row => row.cron.cronName,
    },
    {
        name: "Data/Horário Agendada",
        cell: row => `${formattedDate(row.cron.cronTime)} - ${formattedHours(row.cron.cronTime, 'HH:mm:ss')}`,
        sortable: true,
        sortField: "date",
    },
    {
        name: "Status",
        cell: row => <Typography tag='span' color={statusLogColors[row.cron.status]}>
            {row.cron.status}
        </Typography>,
        sortable: true,
        sortField: "status",
    },
    {
        name: "Qtd. Repositórios Pendentes",
        cell: row => row.countPendingRepositories,
    }
]

export function ExpandedComponentProfile ({ data: profile }: ExpanderComponentProps<ProfileSynced>) {
    return (
        <Stack
            spacing={8}
            border={1}
            borderColor='gray.100'
            py={4}
            px={4}
            my={2}
            borderRadius={1}
        >
            <Typography tag='h2' fontSize='lg'>Logs - Payload inicial</Typography>

            <DataTable
                columns={columns}
                data={profile.logs}
                persistTableHead
            />

        </Stack>
    )
}

