import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'

import { useLocation } from 'react-router-dom'

type SidebarDrawerContextType = {
    isOpen: boolean
    handleOpen: () => void
    handleClose: () => void
}

type SidebarDrawerProviderType = {
    children: ReactNode
}

const SidebarDrawerContext = createContext<SidebarDrawerContextType | null>(null)

export function SidebarDrawerProvider ({ children }: SidebarDrawerProviderType) {
    const [state, setState] = useState(false)

    const location = useLocation()

    useEffect(() => {
        const $root = document.querySelector('#root')

        if (state) {
            $root?.classList.add('no-scroll')
            return
        }

        $root?.classList.remove('no-scroll')
    }, [state])

    useEffect(() => {
        setState(false)
    }, [location.pathname])

    const handleOpen = useCallback(() => setState(true), [])
    const handleClose = useCallback(() => setState(false), [])

    const value = useMemo(() => ({
        isOpen: state,
        handleOpen,
        handleClose,
    }), [state, handleOpen, handleClose])

    return (
        <SidebarDrawerContext.Provider value={value}>
            {children}
        </SidebarDrawerContext.Provider>
    )
}

export const useSidebarDrawer = () => {
    const context = useContext(SidebarDrawerContext)

    if(!context) {
        throw Error('Você precisa usar o <SidebarDrawerProvider />')
    }

    return context
}
