import { Routes, Route, Navigate } from 'react-router-dom'

import { useAuth } from '@/contexts'

import {
    Home,
    // Commits,
    Repos,
    Members,
    MemberDetails,
    NewMember,
    Projects,
    NewProject,
    ProjectDetails,
    Orgs,
    OrgDetails,
    Ranking,
    ProductOwnerDetails
} from '@/pages'

import SyncedProfiles from '@/pages/SyncedProfiles'
import { PrivateRoutesLayout } from './layout'

export function PrivateRoutes() {
    const { isAuthenticated } = useAuth()

    if (!isAuthenticated) {
        return <Navigate replace to='/login' />
    }

    return (
        <PrivateRoutesLayout>
            <Routes>
                <Route path='/' element={<Home />} />
                {/* <Route path='/commits' element={<Commits />} /> */}
                <Route path='/repos' element={<Repos />} />
                <Route path='/members' element={<Members />} />
                <Route path='/members/:id' element={<MemberDetails />} />
                <Route path='/new-member' element={<NewMember />} />
                <Route path='/projects' element={<Projects />} />
                <Route path='/projects/new-project' element={<NewProject />} />
                <Route path='/projects/:id' element={<ProjectDetails />} />
                <Route path='/orgs' element={<Orgs />} />
                <Route path='/orgs/:id' element={<OrgDetails />} />
                <Route path='/ranking' element={<Ranking />} />
                <Route path='/config' element={<SyncedProfiles />} />
                <Route path='/productowners/:productOwnerId' element={<ProductOwnerDetails />} />
            </Routes>
        </PrivateRoutesLayout>
    )
}
