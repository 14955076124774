import LubyLogoImg from "@/assets/luby-software.png";

import * as S from './styles'

export type LogoProps = {
    size: 'small' | 'medium' | 'large' | 'huge'
}

export function Logo ({ size }: LogoProps) {
    return (
        <S.Container size={size}>
            <img src={LubyLogoImg} alt="Luby Software Logo" />
        </S.Container>
    )
}
