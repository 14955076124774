import { ButtonHTMLAttributes, forwardRef, ForwardRefRenderFunction } from 'react'

import { Slot, Slottable } from '@radix-ui/react-slot'

import { Spinner } from '@/components/ui'

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>  & {
    backgroundColor?: string
    textColor?: string
    fullWidth?: boolean
    size?: 'small' | 'medium' | 'large'
    rounded?: boolean
    variant?: 'outline'
    loading?: boolean
    asChild?: boolean
}

const ForwardedButton: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (({
    children,
    asChild,
    loading,
    size,
    ...rest
}, ref) => {
    const props = { size, ...rest }

    const spinnerSize: Record<string, `${number}rem`> = {
        'small': '2rem',
        'medium': '2.4rem',
        'large': '2.8rem'
    }

    const Comp = asChild ? Slot : 'button'

    return (
        <Comp {...props} ref={ref}>
            {loading && (
                <Spinner
                    size={spinnerSize[size || 'small']}
                    color='white'
                    bgColor='white'
                />
            )}
            <Slottable>{children}</Slottable>
        </Comp>
    )
})

export const Button = forwardRef(ForwardedButton)





