import { styled, css } from '@/lib'

type WrapperProps = {
    maxHeight: number
}

export const Wrapper = styled('div')<WrapperProps>`
    ${({ maxHeight, theme }) => css`
        height: ${`${maxHeight/10}rem`};

        border: 1px solid ${theme.colors.gray[100]};

        position: relative;

        overflow-x: auto;

        table {
            border: 0;
        }
    `}
`
