import ReactDatePicker from 'react-date-picker'

import { useTheme } from '@/lib'

import { BsFillCalendarDateFill as CalendarIcon } from 'react-icons/bs'

import * as S from './styles'

type DatePickerProps = {
    value?: Date
    onChange?: (value: Date) => void
}

export function DatePicker (props: DatePickerProps) {
    const { colors } = useTheme()

    return (
        <S.Wrapper classTypeDate='date-picker'>
            <ReactDatePicker
                {...props}
                className='custom-styles'
                calendarIcon={<CalendarIcon size={18} color={colors.gray[200]}/>}
                format='dd/MM/yyyy'
            />
        </S.Wrapper>
    )
}
