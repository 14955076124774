import styled, { css, keyframes } from 'styled-components'

import { Trigger, Arrow } from '@radix-ui/react-popover'

export const StyledTrigger = styled(Trigger)`
    border: 0;
    background-color: transparent;
`

const slideUpAndDFade = keyframes`
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`

export const Wrapper = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.colors.white};
        box-shadow: 0px 24px 112px -69px rgba(0,0,0,0.75);
        border: 1px solid ${theme.colors.gray[200]};
    `}

    padding: 1.2rem 0.8rem;
    border-radius: 4px;
    min-width: 240px;

    animation: ${slideUpAndDFade} 400ms;
`

export const Text = styled.p`
    font-size: 1.4rem;
`

export const ResultSearchListWrapper = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.6rem;
    max-height: 250px;
    overflow-x: auto;
`

export const ResultSearchList = styled.ul`
    width:100%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
`

export const ResultSearchListItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;

    button {
        width:100%;
        border:0;
        background-color: transparent;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.2rem;

        padding: 0.8rem;

        transition: 300ms ease-in;

        cursor: pointer;

        will-change: background-coclor;
        &:hover {
            background-color: ${({ theme }) => theme.colors.gray[50]};
            transition: 300ms ease-in;
        }

        svg {
            color: ${({ theme }) => theme.colors.brand.blue};
        }
    }
`

export const StyledArrow = styled(Arrow)`
    fill: ${({ theme }) => theme.colors.brand.blue};
`
