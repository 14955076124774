import { forwardRef, ForwardRefRenderFunction, InputHTMLAttributes, useState } from 'react';

import { BiHide, BiShow } from 'react-icons/bi'

import { InputItem } from './styles'

import * as S from './styles'

type InputProps = InputHTMLAttributes<HTMLInputElement>

const Component:ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({
    type,
    ...props
}, ref) => {
    const typeInputRef = type
    const [state, setState] = useState(type)

    const togglePasswordShow = () => {
        setState(oldState => oldState === 'password' ? 'text' : 'password')
    }

    return (
        <S.Wrapper>
            <InputItem {...props} type={state}  ref={ref} />
            {typeInputRef === 'password' && (
                <S.HideShowPassword onClick={togglePasswordShow}>
                    {state === 'password'
                        ? <BiHide size={20} />
                        : <BiShow size={20} />
                    }
                </S.HideShowPassword>
            )}
        </S.Wrapper>
    )
}

export const Input = forwardRef(Component)
