import {
    createContext,
    ReactNode,
    useState,
    useMemo,
    useCallback,
    useContext,
    useEffect,
} from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'

import api from '@/api'

import { AuthService, UserService } from '@/services'

import { User, UserLogin } from '@/resources'

import { toast } from 'react-toastify'

import mitt from 'mitt'
import { AuthError, ValidationError } from '@/utils'

type AuthContextType = {
    user: User | null
    isAuthenticated: boolean,
    isAuthenticating: boolean,
    linkedInLoginLoading: boolean,
    login: (loginData: UserLogin) => Promise<void>
    linkedInLogin: (code: string) => Promise<void>
    logout: () => Promise<void>
}

type AuthProviderType = {
    children: ReactNode
}

type AuthContextEvents = {
    unauthorized?: undefined
}

export const AuthContextEmitter = mitt<AuthContextEvents>()

const AuthContext = createContext<AuthContextType | null>(null)

const setToken = (token: string) => {
    localStorage.setItem("tokenUser", token);
    api.defaults.headers.common.Authorization = `Bearer ${token}`
}

export function AuthProvider({ children }: AuthProviderType) {
    const isToken = !!localStorage.getItem('tokenUser')

    const [user, setUser] = useState<AuthContextType['user']>(null)
    const [isAuthenticated, setIsAuthenticated] = useState(isToken)

    // const [isAuthenticating, setIsAuthenticating] = useState(false)

    // useEffect(() => {
    //     if(!isToken) return

    //     const getUserProfile = async () =>  {
    //         setIsAuthenticated(true);

    //         try {
    //            const userProfile = await UserService.getProfile()
    //            setUser(userProfile)
    //         } catch (error) {
    //             toast.error('Ocorreu uma falha')
    //         }
    //     }

    //     getUserProfile()
    // }, [isToken])

    useQuery(['userProfile'], UserService.getProfile, {
        refetchOnWindowFocus: false,
        enabled: !!isToken,
        onSuccess(user) { setUser(user) },
        onError(err) {
            if (err instanceof AuthError) {
                toast.error(err.message)
            }
        },
    })

    const handleSetUserProfile = async (token: string) => {
        setToken(token)

        const userProfile = await UserService.getProfile()

        setUser(userProfile)
        setIsAuthenticated(true)

        return userProfile
    }

    const { mutateAsync: loginMutation, isLoading: isAuthenticating } = useMutation(
        ['login'],
        AuthService.login,
        {
            async onSuccess(data) {
                const { token } = data

                const userProfile = await handleSetUserProfile(token)

                toast.success(`Seja bem vindo, ${userProfile.name}`)
            },
            onError(error) {
                if (error instanceof ValidationError) {
                    toast.error(error.message)
                }
            },
        }
    )

    const { mutateAsync: linkedInLoginMutation, isLoading: linkedInLoginLoading } = useMutation(AuthService.linkedInLogin, {
        async onSuccess(data) {
            const { token } = data

            const userProfile = await handleSetUserProfile(token)

            toast.success(`Seja bem vindo, ${userProfile.name}`)
        },
        onError() {
            toast.error('Ocorreu um inesperado ao realizar o login com o LinkedIn')
        }
    })

    useEffect(() => {
        const handleUnauthorized = () => {
            setIsAuthenticated(false)
            setUser(null)
            localStorage.removeItem('tokenUser')
        }

        AuthContextEmitter.on('unauthorized', handleUnauthorized)

        return () => AuthContextEmitter.off('unauthorized', handleUnauthorized)
    }, [])

    const login: AuthContextType['login'] = useCallback(async ({
        email, password
    }) => { loginMutation({ email, password }) }, [loginMutation])

    const linkedInLogin: AuthContextType['linkedInLogin'] = useCallback(async (code) => {
        linkedInLoginMutation(code)
    }, [linkedInLoginMutation])

    const logout: AuthContextType['logout'] = useCallback(async () => {
        await AuthService.logout()
        setUser(null);
        setIsAuthenticated(false)
    }, [])

    const value = useMemo(() => ({
        user,
        isAuthenticated,
        isAuthenticating,
        linkedInLoginLoading,
        login,
        linkedInLogin,
        logout
    }), [user, login, linkedInLogin, logout, isAuthenticated, isAuthenticating, linkedInLoginLoading])

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    const context = useContext(AuthContext)

    if (!context) {
        throw new Error('Você precisa usar o AuthProvider')
    }

    return context
}
