import { createPortal } from 'react-dom'

import * as S from './styles'

const containerElement = document.querySelector('[data-js=overlays]') as HTMLElement

if (!containerElement) {
    throw new Error ('Container element para overlays não encontrado.')
}

export function LoadingOverlay () {
    return createPortal((
        <S.Overlay>
            <S.Loading />
        </S.Overlay>
    ), containerElement)
}
