import { useState, useEffect, useCallback } from "react"

import  { useLocation, useNavigate } from 'react-router-dom'

import { useMutation, useQuery } from "@tanstack/react-query"

import { usePagination } from "@/hooks"

import { ProfileSyncedService } from "@/services"

import { ValidationError } from "@/utils"

type Platform = 'Github' | 'Bitbucket' | 'AzureDevOps' | 'GitLab'

function isValidationError (error: unknown): error is ValidationError {
    return error instanceof ValidationError
}

export function useConfig () {
    const { currentPage, rowsPerPage, handleRowsPerPage, handlePageChange } = usePagination(1)
    const [profileSelectedId, setProfileSelectedId] = useState('')
    const [platform, setPlatform] = useState<Platform>('Github')

    const location = useLocation()
    const navigate = useNavigate()

    const { data, isLoading, refetch } = useQuery(
        ['profilesSynced'],
        async () =>  ProfileSyncedService.getProfiles(currentPage, rowsPerPage),
        { refetchOnWindowFocus: false }
    )

    const synchronizeGithubMutation = useMutation(ProfileSyncedService.synchronizeGithubProfile, {
        async onSuccess() { await refetch() }
    })

    const updateStatusMutation = useMutation(ProfileSyncedService.updateStatus, {
        async onSuccess() { await refetch() }
    })

    const syncronizeBitBucketMutation = useMutation(ProfileSyncedService.synchronizeBitBucketProfile, {
        async onSuccess() { await refetch() }
    })

     const syncronizeGitLabMutation = useMutation(ProfileSyncedService.synchronizeGitLabProfile, {
        async onSuccess() { await refetch() }
    })

     const syncronizeAzureMutation = useMutation(ProfileSyncedService.synchronizeAzureProfile, {
        async onSuccess() { await refetch() }
    })

    const payloadInitialMutation = useMutation(ProfileSyncedService.startPayloadInitial, {
        async onSuccess() { await refetch() }
    })


    const handleCloseAlert = () => {
        synchronizeGithubMutation.reset()
        syncronizeBitBucketMutation.reset()
        syncronizeGitLabMutation.reset()
        syncronizeAzureMutation.reset()
    }

    const handleToggleStatus = (id: string, active: boolean) => async () => {
        setProfileSelectedId(id)
        await updateStatusMutation.mutateAsync({ id, active })
    }

    const handleRequestAuthorizeGithub = () => {
        const scopes = 'scope=user admin:org repo admin:org_hook'
        const CLIENT_ID = process.env.REACT_APP_GITHUB_OAUTH_CLIENT_ID
        const REDIRECT_URI = process.env.REACT_APP_GITHUB_OAUTH_REDIRECT_URI

        window.location.href =
            `https://github.com/login/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&${scopes}`
    }

    const handleRequestAuthorizeBitBucket = () => {
        window.location.href = `https://bitbucket.org/site/oauth2/authorize?client_id=${process.env.REACT_APP_BITBUCKET_OAUTH_CLIENT_ID}&response_type=code`
    }

    const handleRequestGitLabAuthorize = () => {
        const CLIENT_ID = process.env.REACT_APP_GITLAB_OAUTH_APP_ID
        const REDIRECT_URI = process.env.REACT_APP_GITLAB_OAUTH_REDIRECT_URI
        const SCOPES = 'profile+email+api+read_api+read_user+read_repository+read_registry'
        window.location.href=`https://gitlab.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPES}`
    }

    const handleRequestAzureAuthorize = () => {
        const CLIENT_ID = `client_id=${process.env.REACT_APP_AZURE_OAUTH_CLIENT_ID}`
        const REDIRECT_URI = `&redirect_uri=${process.env.REACT_APP_AZURE_OAUTH_REDIRECT_URI}`
        const SCOPES = '&scope=vso.build vso.code_full vso.dashboards vso.environment_manage vso.extension vso.extension.data vso.identity vso.machinegroup_manage vso.memberentitlementmanagement vso.notification_diagnostics vso.project vso.release_execute vso.security_manage vso.test vso.threads_full vso.tokenadministration vso.wiki vso.work'

        window.location.href=`https://app.vssps.visualstudio.com/oauth2/authorize?${CLIENT_ID}&response_type=Assertion&state=AzureDevOps${SCOPES}${REDIRECT_URI}`
    }

    const containsPlatformParam = location.search.includes('platform')
    const containsStateParam = location.search.includes('state')
    const params = new URLSearchParams(location.search)
    const platformParam = params.get('platform')
    const code = params.get('code')

    useEffect(() => {
        function syncGithub () {
            if (!containsPlatformParam) return

            if(platformParam !== 'github')  return
            if(!code) return

            setPlatform('Github')

            synchronizeGithubMutation.mutate(code)
        }

        syncGithub()
    }, [synchronizeGithubMutation, containsPlatformParam, platformParam, code])

    useEffect(() => {
        if (containsPlatformParam || containsStateParam) return

        if(!code) return

        setPlatform('Bitbucket')

        syncronizeBitBucketMutation.mutate(code)
    }, [syncronizeBitBucketMutation, containsPlatformParam, containsStateParam, code])

    useEffect(() => {
        if (!containsPlatformParam) return

        if(platformParam !== 'gitlab')  return
        if(!code) return

        setPlatform('GitLab')

        syncronizeGitLabMutation.mutate(code)
    }, [syncronizeGitLabMutation, containsPlatformParam, platformParam, code])

    useEffect(() => {
        if (!containsStateParam) return

        if(!code) return

        setPlatform('AzureDevOps')

        syncronizeAzureMutation.mutate(code)
    },  [syncronizeAzureMutation, containsStateParam, code])

    useEffect(() => {
         navigate({ search: new URLSearchParams({}).toString()}, { replace: true })
    }, [navigate])


    const handleStartPayloadInitial = useCallback((token: string) => async () => {
        if(!token) return

        await payloadInitialMutation.mutateAsync(token)
    }, [payloadInitialMutation])

    const synchronizeMappingSuccess = {
        'Github': synchronizeGithubMutation.isSuccess,
        'Bitbucket': syncronizeBitBucketMutation.isSuccess,
        'GitLab': syncronizeGitLabMutation.isSuccess,
        'AzureDevOps': syncronizeAzureMutation.isSuccess,
    }

    const synchronizeMappingError = {
        'Github': synchronizeGithubMutation.isError,
        'Bitbucket': syncronizeBitBucketMutation.isError,
        'GitLab': syncronizeGitLabMutation.isError,
        'AzureDevOps': syncronizeAzureMutation.isError,
    }

    return {
        profiles: data?.profiles,
        pagination: data?.pagination,
        isLoading,
        isSynchronizeGithubLoading: synchronizeGithubMutation.isLoading,
        isSynchronizeBitBucketLoading: syncronizeBitBucketMutation.isLoading,
        isSynchronizeGitLabLoading: syncronizeGitLabMutation.isLoading,
        isSynchronizeAzureLoading: syncronizeAzureMutation.isLoading,
        isSynchronizeSuccess: synchronizeMappingSuccess[platform],
        errorMessage: synchronizeMappingError[platform]
                ?  isValidationError(synchronizeGithubMutation.error)
                    || isValidationError(syncronizeBitBucketMutation.error)
                    || isValidationError(syncronizeGitLabMutation.error)
                    || isValidationError(syncronizeAzureMutation.error)
                    ? `Opa! Esse perfil do ${platform} já está sincronizado no sistema. Se deseja sincronizar com um perfil diferente, deslogue a conta atual no ${platform}.`
                    : `Ocorreu um erro inesperado ao sincronizar com o ${platform}`
                : null,
        handlePageChange,
        handleRowsPerPage,
        handleCloseAlert,
        profileSelectedId,
        isTogglingStatus: updateStatusMutation.isLoading,
        handleToggleStatus,
        handleRequestAuthorizeGithub,
        handleRequestAuthorizeBitBucket,
        handleRequestGitLabAuthorize,
        handleRequestAzureAuthorize,
        loadingStartPayloadInitial: payloadInitialMutation.isLoading,
        handleStartPayloadInitial
    }
}
