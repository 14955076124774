import { ChangeEvent, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { useDebounce } from '@/hooks'

import { ReposService } from "@/services";

export function useSearchRepoByName () {
    const [repoName, setRepoName] = useState('')
    const repoNameDebounced = useDebounce(repoName)

    const result = useQuery(
        ['searchedRepo', repoNameDebounced],
        async () => ReposService.getReposWithoutProject(repoNameDebounced),
        {
            enabled: !!repoNameDebounced,
            refetchOnWindowFocus: false,
        }
    )

    const handleRepoNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setRepoName(e.target.value)
    }

    const cleanRepoName = () => setRepoName('')

    return {
        result,
        isLoadingRepoSearch: result.isLoading && !!repoNameDebounced,
        repoSearchError: !result.data && result.status === 'success'
            ? 'Repositório não encontrado' : '',
        repoName,
        handleRepoNameChange,
        cleanRepoName
    }
}
