import { DependencyList, EffectCallback, useEffect } from 'react'

import { useIsFirstRender } from '../use-is-first-render'

export function useUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
  const isFirst = useIsFirstRender()

  useEffect(() => {
    if (!isFirst) {
      return effect()
    }

    return () => undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
