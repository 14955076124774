export const colors = {
    brand: {
        blue: '#02a4ef',
        orangeRed: '#f35123',
        green: '#7fb927',
        yellowMedium: '#fdb733',
    },

    bg: '#f0f0f5',
    black: '#000',
    white: '#fff',

    gold: '#fbe192',
    silver: '#c3cfd9',
    bronze: '#f3c19d',

    gray: {
        50: '#f4f4f4',
        100: '#e3e3e3',
        200: '#ccc',
        700: '#2d3748',
        900: '#2e2e2e'
    },

    red: {
        200: '#ffd3d6',
        500: '#e53e3e'
    },

    green: {
        200: '#c6f6d5',
        500: '#00ad67'
    }
}
