import { styled, css, keyframes } from '@/lib'

import { SpinnerProps } from '.'

const rotate = keyframes`
    100% {
        transform: rotate(360deg)
    }
`

const dash = keyframes`
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0
    }
    50% {
        stroke-dasharray: 90, 150;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124
    }
`

type SVGWrapperProps = Pick<SpinnerProps, 'size'>

export const SVGWrapper = styled('svg')<SVGWrapperProps>`
    ${({ size }) => css`
        width: ${size};
        height: ${size};
        animation: ${rotate} 1.5s linear infinite;
    `}
`

type CircleProps = Pick<SpinnerProps, 'color' | 'bgColor'>

export const Circle = styled('circle')<CircleProps>`
    ${({ theme, color }) => css`
        stroke: ${color || theme.colors.brand.blue};
        animation: ${dash} 1.3s ease-in-out infinite;
    `}
`

export const CircleBg = styled('circle')<CircleProps>`
    ${({ theme, bgColor }) => css`
        stroke: ${bgColor || theme.colors.gray[200]};
        opacity: 0.3;
    `}
`
