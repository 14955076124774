import { ReactNode } from "react"

import { Stack, Typography } from "@/components"

import * as S from './styles'

export type ListProps = {
    children: ReactNode
    maxWidth?: number | string
    maxHeight?: number
}

export type ItemProps = {
    children: ReactNode
}

export type ItemBoxProps = {
    children: ReactNode
    label?: string
    first?: boolean
    last?: boolean
}

export function List ({ children, maxWidth, ...props }: ListProps) {
    return (
        <S.ListContainer maxWidth={maxWidth} {...props}>
            {children}
        </S.ListContainer>
    )
}

export function Item ({ children, ...props }: ItemProps) {
    return (
        <S.ListItemContainer as='li' {...props}>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={[6, 10]}
                justifyContent='space-between'
                alignItems='center'
            >
                {children}
            </Stack>
        </S.ListItemContainer>
    )
}

function ItemBox ({ children, label, first, last }: ItemBoxProps) {
    return (
        <Stack
            flex={1}
            spacing={2}
            alignItems={!last && !first ? 'center' : first ? 'flex-start' : 'flex-end'}
        >
            {label
                ? <>
                    <S.ListBoxLabel>{label}</S.ListBoxLabel>
                    <Typography tag='p' fontSize='md' maxWidth={250} ellipsis>
                        {children}
                    </Typography>
                </>
            : children
            }
        </Stack>
    )
}

List.Item = Item
List.ItemBox = ItemBox
