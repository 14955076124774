export function makeDebounce (time: number) {
    let id: ReturnType<typeof setTimeout>

    return <T>(fn: () => T): Promise<T> => {
      clearTimeout(id)
      return new Promise((resolve, reject) => {
        id = setTimeout(async () => {
          try {
            const res = await fn()
            resolve(res)
          } catch (error) {
            reject(error)
            throw error
          }
        }, time)
      })
    }
}
