import { useState, useCallback } from 'react'

type Direction = 'asc' | 'desc'

type FieldProjectName = {
    name: Direction
}

type FieldLastCommit = {
    updated_at: Direction
}

export type SortFieldsProjects =
    | FieldProjectName
    | FieldLastCommit

export function useSortProjects () {
    const [sort, setSort] = useState<SortFieldsProjects>({ updated_at: 'asc' })

    const handleSort = useCallback((field: string | undefined, direction: Direction) => {
        if (!field) return

        switch (field) {
            case 'projectName':
                setSort({ name: direction })
                break
            case 'lastCommit':
                setSort({ updated_at: direction })
                break
            default:
                throw new Error('')
        }
    }, [])

    return { sort, handleSort }
}
