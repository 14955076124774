import { ReactNode, ButtonHTMLAttributes, ChangeEvent } from 'react'

import { Input, Spinner } from '@/components';

import * as Popover from '@radix-ui/react-popover';

import { IoAddOutline as AddIcon } from 'react-icons/io5'

import * as S from './styles'

type SearchPopoverProps = {
    children: ReactNode
    trigger: ReactNode
    side?: "right" | "top" | "bottom" | "left" | undefined
    align?: 'start' | 'center' | 'end'
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    loading?: boolean
    placeholder?: string
    value?: string
    noData?: boolean
}

export function SearchPopover ({
    children,
    trigger,
    side = 'right',
    align = 'end',
    onChange = () => undefined,
    loading = false,
    value,
    placeholder = 'Search',
    noData
}: SearchPopoverProps) {
    return (
        <Popover.Root>
            <Popover.Trigger asChild>{trigger}</Popover.Trigger>
            <Popover.Portal>
                <Popover.Content asChild sideOffset={10} side={side} align={align}>
                    <S.Wrapper>
                        <Input value={value} placeholder={placeholder} onChange={onChange} />
                        <S.ResultSearchListWrapper>
                            {loading
                                ? <Spinner />
                                :  noData
                                    ? <S.Text>Sem resultados</S.Text>
                                    : <S.ResultSearchList>{children}</S.ResultSearchList>
                            }
                        </S.ResultSearchListWrapper>
                        <S.StyledArrow  />
                    </S.Wrapper>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    )
}

type ResultSearchItemProps = {
    children: ReactNode
} & ButtonHTMLAttributes<HTMLButtonElement>

SearchPopover.Item = ({ children, ...props }: ResultSearchItemProps) => {
    return (
        <S.ResultSearchListItem>
            <button type='button' {...props}>
                {children} <AddIcon size={16} />
            </button>
        </S.ResultSearchListItem>
    )
}
