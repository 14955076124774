import api from "@/api"

import { PaginationResponseData, Commit, Platform } from "@/resources/types"

import { SortFieldsCommits } from '@/hooks/use-sort-commits'

import { filterParameters } from '@/utils'

type GetCommitsResponse = {
    commits: Commit[]
} & PaginationResponseData

export const CommitsService = {
    async getCommits (
        platforms: Platform[],
        member= '',
        date='',
        shaCommit='',
        order: SortFieldsCommits = { commited_at: 'desc' },
        page = 1,
        limit = 10
    ): Promise<GetCommitsResponse> {
        const params = filterParameters({ page, limit, member, date, sha: shaCommit, order, platforms: platforms.join(',') })
        const { data: { data, ...pagination } } = await api.get('/commits', { params })

        return { commits: data,  ...pagination }
    },

    async getCommitsByRepoId (
        repoId: string | undefined,
        page: number,
        limit: number,
        order: SortFieldsCommits = { commited_at: 'desc' },
        member = '',
        date = '',
        sha = '',
    ): Promise<{commits: Commit[], pagination: PaginationResponseData}> {
        const { data: { data, ...pagination } } = await api.get(`/commits/repository/${repoId}`, { params: {
            page,
            limit,
            member,
            date,
            sha,
            order
        }})

        return { commits: data, pagination }
    },

    async getCommitsByMemberId (id: string): Promise<Commit[]> {
        const { data } = await api.get('/commits/member/some', { params: { id }})
        return data
    }
}
