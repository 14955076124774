import axios, { AxiosError } from "axios";

import { AuthError, ValidationError } from "@/utils";

import { AuthContextEmitter } from '@/contexts/auth'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

const getAuthTokenLocalStorage = (): string | null => {
    const storagedToken = localStorage.getItem('tokenUser')
    return storagedToken || null
}

api.interceptors.request.use(config => {
    const token = getAuthTokenLocalStorage()

    if (!token) {
        return config
    }

    if (config.headers) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
})

api.interceptors.response.use(
    response => response,
    (error) => {
        if (error instanceof AxiosError) {
            const statusCode  = error.response?.status

            if(typeof statusCode !== 'undefined' && statusCode === 401) {
                AuthContextEmitter.emit('unauthorized')

                throw new AuthError('Token expirado!')
            }

            if(typeof statusCode !== 'undefined' && statusCode === 400) {
                throw new ValidationError(error.response?.data.message)
            }

            return Promise.reject(error)
        }
    }
)

export default api
