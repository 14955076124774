import { InputHTMLAttributes, ReactElement } from "react";

import { FiSearch as SearchIcon } from 'react-icons/fi'
import {  IoIosClose as ResetIcon } from 'react-icons/io'

import { Input } from "../Input";

import * as S from './styles'

type InputSearchProps = {
    icon?: ReactElement
    onResetInputSearch?: () => void
    error?: string
    loading?: boolean
} & InputHTMLAttributes<HTMLInputElement>

export function InputSearch ({
    value,
    icon,
    error,
    onResetInputSearch,
    loading = false,
    ...props
}: InputSearchProps) {
    return (
        <S.MainWrapper>
            <S.InputWrapper>
                <Input {...props} value={value} />
                <S.IconWrapper>
                    {value
                        ? <ResetIcon
                        size={26}
                        className='reset-icon'
                        onClick={onResetInputSearch}
                        />
                        : icon || <SearchIcon size={24} />
                    }
                </S.IconWrapper>
            </S.InputWrapper>
            {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
            {loading && <S.Message>Loading...</S.Message>}
        </S.MainWrapper>
    )
}
