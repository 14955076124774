import styled, { css } from 'styled-components'

import { progress } from '@/lib/theme'

import type { ProgressBarProps } from '.'

export const Wrapper = styled.div`
    ${({ theme }) => css`
        width: 100%;
        background-color: ${theme.colors.gray[100]};
        height: 35px;
        border-radius: 5px;
        overflow: hidden;

        position: relative;

        span {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: bold;
        }
    `}
`

export const ProgressBar = styled.div<Omit<ProgressBarProps, 'children'>>`
    ${({ theme, percent, color }) => css`
        width: ${percent}%;
        background-color: ${color ?? theme.colors.brand.green};
        height: 100%;
        color: ${theme.colors.black};
        animation: ${progress(percent)} 1.5s ease-in-out;
    `}
`
