import { useCallback, useReducer } from "react"

import { Member, ProductOwner, Repo } from "@/resources"

import { toast } from "react-toastify"

import { reducer, initialState } from "./reducer"

export function useMembersRepos () {
    const [state, dispatch] = useReducer(reducer, initialState)

    const handleAddMember = (member: Member) => () => {
        const existsMember = state.members.find((item) => (
            member.id === item.id
        ))

        if(existsMember) {
            toast.error('Desenvolvedor já adicionado!')
            return
        }

        dispatch({ type: 'ADD_MEMBER', member})
    }

    const handleAddProductOwner = (productOwner: ProductOwner) => () => {
        const existsMember = state.members.find((item) => (
            productOwner.id === item.id
        ))

        if(existsMember) {
            toast.error('ProductOwner já adicionado.')
            return
        }

        dispatch({ type: 'ADD_PRODUCT_OWNER', productOwner})
    }

    const setData = useCallback((members: Member[], repos: Repo[]) => {
        dispatch({ type: 'SET_DATA', members, repos })
    }, [])

    const handleRemoveMember = (id: string) => () => {
        dispatch({ type: 'REMOVE_MEMBER', id })
    }

    const handleRemoveProductOwner = (id: string) => () => {
        dispatch({ type: 'REMOVE_PRODUCT_OWNER', id })
    }

    const handleAddRepo = (repo: Repo) => () => {
        const existsRepo = state.repos.find(item => ( repo.id === item.id ))

        if(existsRepo) {
            toast.error('Esse repositório já foi adicionado')
            return
        }

        dispatch({ type: 'ADD_REPO', repo })
    }

    const handleRemoveRepo = (id: string) => () => {
        dispatch({ type: 'REMOVE_REPO', id })
    }

    return {
        ...state,
        handleAddProductOwner,
        handleRemoveProductOwner,
        handleAddMember,
        handleRemoveMember,
        handleAddRepo,
        handleRemoveRepo,
        setData,
    }
}
