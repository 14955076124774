import api from "@/api";
import { ProfileSynced, PaginationResponseData } from "@/resources/types";
import { makeDebounce } from "@/utils";

type GetProfilesSyncedReponseData = {
    profiles: ProfileSynced[],
    pagination: PaginationResponseData
}

const debounce = makeDebounce(300)

export const ProfileSyncedService = {
    async getProfiles (page = 1, limit = 10): Promise<GetProfilesSyncedReponseData> {
        const { data: { data, ...pagination } } = await api.get('/gitAuth', { params: { page, limit }})

        return { profiles: data, pagination }
    },

    async synchronizeGithubProfile (code: string) {
        return debounce(() => api.post(`/gitAuth/token`, { code }))
    },

    async synchronizeBitBucketProfile (code: string) {
        return debounce(() => api.post(`/gitAuth/bucket`, { code }))
    },

    async synchronizeGitLabProfile (code: string) {
        return debounce(() => api.post(`/gitAuth/gitlab`, { code }))
    },

    async synchronizeAzureProfile (code: string) {
        return debounce(() => api.post('/gitAuth/azure', { code }))
    },

    async updateStatus({id = '', active = false}): Promise<void> {
         await api.post('/gitAuth/toogleStatus', { id, active })
    },

    async startPayloadInitial(token: string) {
        return api.post('/gitAuth/github/payload-initial', { token })
    }
}
