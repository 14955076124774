import { Suspense, useEffect } from 'react'

import {
    Routes,
    Route,
    Navigate,
    matchPath,
    useLocation
} from 'react-router-dom'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import { useAuth } from "@/contexts";

import { Login, RecoverPassword, ResetPassword } from "@/pages";

import { PrivateRoutes } from "./private-routes";

NProgress.configure({ easing: 'ease', speed: 800 })
NProgress.configure({ showSpinner: true })

function NProgressLoading () {
  useEffect(() => {
    NProgress.start()

    return () => {
      NProgress.done()
    }
  })

  return null
}

export function AppRoutes() {
    const { isAuthenticated } = useAuth()
    const { pathname } = useLocation()

    const isPublicRoute =
        !!matchPath('/login', pathname) ||
        !!matchPath('/recover-password', pathname) ||
        !!matchPath('/reset-password', pathname)

    if (isPublicRoute && isAuthenticated) {
        return <Navigate replace to='/' />
    }

    return (
        <Suspense fallback={<NProgressLoading />}>
            <Routes>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route path="/login" element={<Login />} />
                <Route path="/recover-password" element={<RecoverPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
        </Suspense>
    );
}

