import { ChangeEvent, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { DashboardService } from "@/services";

import { Intervals } from "@/resources";

type onSelectChangeEvent = ChangeEvent<HTMLSelectElement> & {
    target: {
        value: Intervals
    }
}

export function useDashboard () {
    const [chartInterval, setChartInterval] = useState<Intervals>('7days')

    const {data: averageCommits, isLoading: isAverageCommitsLoading } =
        useQuery(['commits-average'], DashboardService.getAverageCommits, { refetchOnWindowFocus: false })

    const resultChart = useQuery(
        ['chart', chartInterval],
        async () => DashboardService.chart(chartInterval),
        { refetchOnWindowFocus: false }
    )

    const resultActiveMembers = useQuery(['membersActives'], DashboardService.getActiveMembers, { refetchOnWindowFocus: false })

    const handleChartChange = (e: onSelectChangeEvent) => {
        setChartInterval(e.target.value)
    }

    return {
        statusChart: resultChart.status,
        chart: resultChart.data,
        chartInterval,
        handleChartChange,

        activeMembers: {
            status: resultActiveMembers.status,
            count: resultActiveMembers.data?.count
        },

        isAverageCommitsLoading,
        averageCommits,
    }
}
