import ReactModal from 'react-modal'

import { Button, Navigation } from '@/components'

import { AiOutlineClose as CloseIcon } from "react-icons/ai"

import * as S from './styles'

type DrawerProps = {
    isOpen: boolean
    onClose: () => void
    onLogout: () => void
}

export function Drawer ({ isOpen, onClose, onLogout }: DrawerProps) {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName='react-modal-overlay-drawer'
            className='react-modal-content-drawer'
        >
            <S.CloseIconWrapper onClick={onClose}>
                <CloseIcon size={20} />
            </S.CloseIconWrapper>

            <Navigation />
            <S.GoOutButtonWrapper>
                <Button fullWidth onClick={onLogout}>
                    Sair
                </Button>
            </S.GoOutButtonWrapper>
        </ReactModal>
    )
}
