export const font = {
    family: "Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",

    weights: {
        thin: 100,
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
        black: 900,
    },

    fontSizes: {
        "3xs": "0.72rem",
        "2xs": "1rem",
        xs: "1.2rem",
        sm: "1.4rem",
        md: "1.6rem",
        lg: "1.8rem",
        xl: "2rem",
        "2xl": "2.4rem",
        "3xl": "3rem",
        "4xl": "3.6rem",
        "5xl": "4.8rem",
        "6xl": "6rem",
        "7xl": "7.2rem",
        "8xl": "9.6rem",
        "9xl": "12.8rem",
    },
}

export type ThemeFontSizes = keyof typeof font.fontSizes
export type ThemeFontWeights = keyof typeof font.weights
