import { styled } from '@/lib'

export const Wrapper = styled('div')`
    width: 150px;

    display: flex;
    align-items: center;

    @media only screen and (max-width: 768px) {
        svg {
            pointer-events: none;

            .text {
                display: none;
            }
        }
    }

`
