import { ReactElement, ReactNode, MouseEvent } from 'react'

import { Root as PrimitiveRoot, Portal } from '@radix-ui/react-popover'

import * as S from './styles'

type DropdownProps = {
    children: ReactNode
    trigger: ReactElement
}

function Root ({ children, trigger }: DropdownProps) {
    const stopPropagation = (e: MouseEvent<HTMLElement>) => e.stopPropagation()

    return (
        <PrimitiveRoot>
            <S.Trigger onClick={stopPropagation}>{trigger}</S.Trigger>
            <Portal>
                <S.Content onClick={stopPropagation} sideOffset={6}>
                    {children}  <S.Arrow />
                </S.Content>
            </Portal>
        </PrimitiveRoot>
    )
}

type DropdownItemProps = {
    children: ReactNode
}

function Item ({ children }: DropdownItemProps) {
    return (
        <div>{children}</div>
    )
}

export const Dropdown = {
    Root,
    Item
}
