import styled, { css } from 'styled-components'

import { transparentize } from 'polished'
import {RankCardProps} from '.'

type WrapperProps = Pick<RankCardProps, 'active'>

export const AvatarWrapper = styled.div`
    position: relative;
    width: max-content;
    margin-bottom: 1.6rem;
`

type PositionBadgeProps = {
    position: number
}

export const ClassificationBadge = styled.span<PositionBadgeProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: bold;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    bottom: 0.2rem;
    right: 0.2rem;

    background-color: ${({ theme }) => theme.colors.gray[50]};

    ${({ position, theme }) => css`
        ${position === 1 && css`background-color: ${theme.colors.gold};`}
        ${position === 2 && css`background-color: ${theme.colors.silver};`}
        ${position === 3 && css`background-color: ${theme.colors.bronze};`}
    `}

    span {
        margin-left: 3.5px;
    }
`

export const Name = styled.p`
    font-size: 1.6rem;
    font-weight: 500;
`

export const Separator = styled.span`
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray[100]};
`

export const Commits = styled.ul`
    width: 100%;
    list-style: none;
    margin-top: 1.6rem;

    display: flex;
    gap: 0.8rem;

    padding-bottom: 0.8rem;

    overflow-x: auto;

    & li {
        flex: 100px 1 0;
    }
`

export const Commit = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    font-weight: 500;
`

export const CommitTitle = styled.p`
    font-size: 1.4rem;
`

export const CommitAmount = styled.p`
    font-size: 1.6rem;
`

export const IconWrapperCommit = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    svg {
        color: ${({theme}) => transparentize(0.5, theme.colors.black)};
    }
`

export const ScoreInfo = styled.p`
    ${({ theme }) => css`
        color: ${theme.colors.brand.blue};
        background-color: ${transparentize(0.9, theme.colors.brand.blue)};
        border: 1px solid ${transparentize(0.5, theme.colors.brand.blue)};
    `}

    padding: 1.2rem 1.8rem;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 500;
`

export const Wrapper = styled.div<WrapperProps>`
    ${({ theme }) => css`
        background-color: ${theme.colors.white};
        border: 1px solid ${theme.colors.gray[100]};
    `}

    ${({ active }) => css`
        ${!active && css`
            ${AvatarWrapper}, ${Name}, ${ScoreInfo}, ${Commits} {
                opacity: 0.3
            }
        `}
    `}

    border-radius: 14px;
    padding: 1.6rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;

    transition: transform 0.2s, box-shadow 0.2s;

    will-change: transform, box-shadow;

    &:hover {
        transition: transform 0.2s, box-shadow 0.2s;
        transform: translateY(-5px);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.175);
    }
`

