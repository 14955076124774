import { ReactNode } from 'react'

import { Platform } from '@/resources/types'

import { theme } from '@/lib/theme'

import { BsGithub as GithubIcon} from 'react-icons/bs'
import { IoLogoBitbucket as BitBucketIcon } from 'react-icons/io'
import { SiAzuredevops as AzureIcon } from 'react-icons/si'
import { AiFillGitlab as GitLabIcon } from 'react-icons/ai'

import { Stack, Box } from '@/components'

import { Link } from 'react-router-dom'

type PlatformComponentProps = {
    platform: Platform
    pathLink?: string
    text?: string
    externalLink?: boolean
}

const platformIcons: Record<Platform, ReactNode> = {
    'Github': <GithubIcon size={22} />,
    'Bitbucket':  <BitBucketIcon size={22} color={theme.colors.brand.blue} />,
    'AzureDevOps': <AzureIcon size={22} color={theme.colors.brand.blue} />,
    'GitLab': <GitLabIcon size={22} color={theme.colors.brand.orangeRed} />
}

export function PlatformComponent ({ platform, pathLink = '', text, externalLink = false }: PlatformComponentProps) {
    return (
        <Stack
            flexDirection='row'
            gap={2}
            alignItems='center'
            color={platform === 'GitLab' ? 'orangeRed' : platform === 'Github' ? 'gray.900' : 'brand.blue'}
            sx={{
                "& > a ": {
                    textDecoration: 'none',
                    color: 'currentcolor',
                    '&:hover': { textDecoration: 'underline' }
                }
            }}
        >
            <Box flexGrow={0}>{platformIcons[platform]}</Box>
            {externalLink && <a href={pathLink} target='_blank' rel='noreferrer'>{text}</a>}
            {(!externalLink && pathLink) && <Link to={pathLink}>{text}</Link>}
        </Stack>
    )
}
