import { ReactNode } from "react"
import { CommitKeyPercent } from "@/resources"

import * as S from './styles'

export type ProgressBarProps = {
    children: ReactNode
    percent: number
    commit?: CommitKeyPercent
    color?: string
}

export function ProgressBar ({ children, ...props }: ProgressBarProps) {
    return (
        <S.Wrapper>
            <S.ProgressBar {...props} />
            <span>{children}</span>
        </S.Wrapper>
    )
}
