import styled, { css } from 'styled-components'

type WrapperProps = {
    classTypeDate: 'date-picker' | 'daterange-picker'
}

export const Wrapper = styled.div<WrapperProps>`
    ${({ theme, classTypeDate  }) => css`
        .custom-styles {
            height: 100%;
            width: 100%;

            .react-daterange-picker__inputGroup {
                flex-grow: 0 !important;
            }

            .react-daterange-picker__clear-button.react-daterange-picker__button {
                margin-left: auto;
            }

            .react-${classTypeDate}__wrapper {
                padding: 12px;
                border: 1px solid;
                border-color: #e3e3e3;
                font-size: 14px;
                border-radius: 12px;

                display: flex;
                gap: 1.6rem;

                & > * {
                    padding: 0px
                }

                button.react-date-picker__clear-button {
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }

                button.react-date-picker__calendar-button {
                    &:hover {
                        svg {
                            transition: all 0.3ms ease-in;
                            fill: ${theme.colors.brand.blue};
                        }
                    }
                }
            }
        }
    `}
`
