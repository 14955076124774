import { styled } from '@/lib'

export const Wrapper = styled('button')`
    border: 0;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.gray[50]};
    padding: 0.8rem 1rem;
    border: 1px solid ${({ theme }) => theme.colors.gray[100]};

    font-size: 1.6rem;

    width: max-content;
    white-space: nowrap;

    display: flex;
    align-items: center;
    gap: 0.8rem;

    cursor: pointer;

    transition: 400ms border;
    will-change: border;

    &:hover {
        transition: 400ms border;
        border: 1px solid ${({ theme }) => theme.colors.brand.blue};

        svg {
            transition: 400ms color;
            will-change: color;
            color: ${({ theme }) => theme.colors.brand.blue};
        }
    }
`
