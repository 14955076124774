import ContentLoader from 'react-content-loader'

import { defaultProps } from "../default-props";

export function ButtonContentLoader ({ fullWidth = false, width = 110, height = 35 }) {
    return (
        <div style={{ width: fullWidth ? '100%' : `${width}px`, height: `${height}px` }}>
            <ContentLoader {...defaultProps}>
                <rect
                    x="0"
                    y="0"
                    rx="10"
                    ry="10"
                    width={fullWidth ? '100%' : width}
                    height={height}
                />
            </ContentLoader>
        </div>
    )
}
