import api from '@/api'

import { SortFieldsMembers } from '@/hooks/use-sort-members'

import { Platform } from '@/resources'

import type {
    CreateMemberData,
    Member,
    MemberWithOrgs,
    PaginationResponseData,
    Position,
    ProductOwner,
    UpdateMemberData,
    Administrator
} from '@/resources/types'

interface AdministratorsResponseData {
    admins: Administrator[]
    pagination: PaginationResponseData
}

interface MembersResponseData {
    members: Member[]
    pagination: PaginationResponseData
}

interface ProductOwnersResponseData {
    productOwners: ProductOwner[]
    pagination: PaginationResponseData
}

export const MembersService = {
    async getMembers (
        position: Position | '',
        platforms: Platform[],
        search = '',
        page = 1,
        limit = 10,
        order: SortFieldsMembers = { active: 'desc' }
    ): Promise<MembersResponseData> {
         const { data: { data, ...pagination }} = await api.get('/members', {
            params: {
                position,
                platforms: platforms.join(','),
                search,
                page,
                limit,
                order
            }
        })

        return { members: data, pagination }
    },

    async getAdministrators(
        search = '',
        page = 1,
        limit = 10,
        order: SortFieldsMembers = { active: 'desc' }
    ): Promise<AdministratorsResponseData> {
        const { data: { data, ...pagination } } = await api.get('/members/administrators', {
            params: {
                search,
                page,
                limit,
                order
            }
        })

        return { admins: data, pagination }
    },

    async getProductOwners(
        search = '',
        page = 1,
        limit = 10,
        order: SortFieldsMembers = { active: 'desc' }
    ): Promise<ProductOwnersResponseData> {
        const { data: { data, ...pagination } } = await api.get('/members/productowners', {
            params: {
                search,
                page,
                limit,
                order
            }
        })

        return { productOwners: data, pagination }
    },

    async getProductOwnerById(id: string): Promise<ProductOwner> {
        const { data } = await api.get(`/members/productowners/${id}`)

        const { projects, ...rest } = data

        type ProductOwnerProject = {
            Repository: object
        }

        const productOwner = {
            ...rest,
            projects: projects.map(({ Repository, ...rest }: ProductOwnerProject) => ({
                ...rest,
                repositories: Repository
            }))
        }

        return productOwner
    },

    async updateProductOwner(
        { id, name, active }: { id: string, name: string, active: boolean }
   ) {
      return api.patch(`/members/productowners/${id}`, {
            name,
            active
        })
    },

    async getMemberById(id: string): Promise<MemberWithOrgs | null> {
        const { data } = await api.get(`/members/details/${id}`)
        return data
    },

    async getMemberByName(name = ''): Promise<MemberWithOrgs[] | null> {
        const searchParam = name ? `&search=${name}` : ''

        const { data: { data } } = await api.get(`/members?page=1${searchParam}`)

        return data.length === 0 ? null : data
    },

    async createMember(values: CreateMemberData) {
        const position = values.position.toLowerCase().split(' ').join('')
        const res = await api.post(`/members/${position}/create`, { ...values })

        if (res.data.statusCode === 406) {
            throw new Error(res.data.message);
        }
    },

    async updateMember(member: UpdateMemberData) {
        return api.patch(`/members/${member.id}`, {
            ...member
        })
    },

    async deleteMember(id: string) {
        return api.delete(`/members/${id}`)
    },

    async getMembersByOrgId (
        orgId: string,
        page: number,
        limit: number,
        order: SortFieldsMembers = { active: 'asc' }
    ): Promise<MembersResponseData> {
        const { data: { data, ...pagination} } = await api.get(`/organizations/${orgId}/members`, { params: {
            page,
            limit,
            order
        }})

        return { members: data, pagination }
    }
}
