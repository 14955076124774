import { ReactElement, ReactNode } from "react"

import {
    Provider,
    Root,
    Portal,
} from '@radix-ui/react-tooltip'

import * as S from './styles'

type TooltipProps = {
    trigger: ReactElement
    children: ReactNode
}

export function Tooltip ({ trigger, children }: TooltipProps) {
    return (
        <Provider>
            <Root delayDuration={300}>
                <S.Trigger asChild>{trigger}</S.Trigger>
                <Portal>
                    <S.Content sideOffset={6}>
                        {children}
                        <S.Arrow />
                    </S.Content>
                </Portal>
            </Root>
        </Provider>
    )
}
