import { styled, css } from '@/lib'

import { LogoProps } from '.'

const LogoModifiers = {
    small: () => css`
        width: 5rem;
        height: 5rem;
    `,
    medium: () => css`
        width: 10rem;
        height: 10rem;
    `,
    large: () => css`
        width: 15rem;
        height: 15rem;
    `,
    huge: () => css`
        width: 20rem;
        height: 20rem;
    `,
}

export const Container = styled('div')<LogoProps>`
    ${({ size }) => css`
        img {
            display: block;
            max-width: 100%;
        }

        ${!!size && LogoModifiers[size]()};
    `}
`
