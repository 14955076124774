import { CommitKeyPercent } from "../commit"
import { Member } from "../member"
import { Project } from "../project"

export type RankingCommonData = {
    id: string
    rank: number
    feat: number
    fix: number
    refactor: number
    chore: number
    conv: number
    other: number
    percents: Record<CommitKeyPercent, number>
    score: number
    total: number
    comportamentalMedian: number
    commitMedian: number
}

export type RankingMemberData = RankingCommonData & {
    countProjects?: number
    member: Member & {
        totalProjects: number
    }
}

export type MemberData = Member & RankingCommonData

export type RankingProjectData = RankingCommonData & {
    project: Omit<Project, 'members' | 'repositories'>
    totalMembers: number
}

export type SelectedProjectDetailsData = {
    totalMembers: number
    developers: MemberData[]
    pos: MemberData[]
}

export function isRankingProject (data: unknown): data is RankingProjectData {
    return typeof data !== 'undefined'
        && !!data
        && typeof data === 'object'
        && 'project' in data
}

export function isMemberData (data: unknown): data is MemberData {
    return typeof data !== 'undefined'
        && !!data
        && typeof data === 'object'
        && 'name' in data
}
