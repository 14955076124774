import { styled, css, keyframes } from '@/lib'

import { transparentize } from 'polished'

import { Content, Arrow } from '@radix-ui/react-hover-card'

const scaleIn = keyframes({
    '0%': { opacity: 0, transform: 'scale(0)' },
    '100%': { opacity: 1, transform: 'scale(1)' },
  });

export const StyledContent = styled(Content)`
    ${({ theme }) => css`
        background-color: ${theme.colors.white};
        box-shadow: 0px 0px 0px 1px ${transparentize(0.5, theme.colors.brand.blue)};
        border-radius: 8px;
        padding: 1.6rem;
        min-width: 17.5rem;
        transformOrigin: var(--radix-hover-card-content-transform-origin);
        animation: ${scaleIn} 0.2s ease-out;
    `}
`

export const StyledArrow = styled(Arrow)`
    fill: ${({ theme }) => theme.colors.brand.blue};
`
