import { useState, useCallback } from 'react'

type Direction = 'asc' | 'desc'

type FieldRepoName = {
    repository: {
        name: Direction;
    };
};

type FieldMemberName = {
    member: {
        name: Direction;
    };
};

type FieldCommitDate = {
    commited_at: Direction;
};

type FieldShaCommit = {
    sha: Direction;
};

type FieldIsConventionalCommit = {
    conventional: Direction
}

export type SortFieldsCommits =
    | FieldRepoName
    | FieldMemberName
    | FieldCommitDate
    | FieldShaCommit
    | FieldIsConventionalCommit

export function useSortCommits() {
    const [sort, setSort] = useState<SortFieldsCommits>({ commited_at: 'desc' })

    const handleSort = useCallback((field:string | undefined, direction: Direction) => {
        if(!field) return

        switch(field) {
            case 'repoName':
                setSort({ repository: { name: direction }})
                break
            case 'memberName':
                setSort({ member: { name: direction }})
                break
            case 'commitDate':
                setSort({ commited_at: direction })
                break
            case 'shaCommit':
                setSort({ sha: direction })
                break
            case 'isConventionalCommit':
                setSort({ conventional: direction })
                break
            default:
                throw new Error('')
        }
    }, [])

    return { sort, handleSort }
}
