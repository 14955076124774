import { Direction, MemberWithCommits, Platform, Repo } from '@/resources/types'

export type Commit = {
    id: string
    sha: string
    platform: Platform
    repositoryFullName: string
    repository_name: string
    html_url: string
    repository: Repo
    member: MemberWithCommits
    commit_date: string
    message: string
    isConventionalCommit: boolean
}

type CommitKey = 'feat' | 'refactor' | 'fix' | 'chore' | 'conv' | 'other'

export type CommitKeyPercent =
    | 'feat'
    | 'refactor'
    | 'fix'
    | 'chore'
    | 'conventional'
    | 'others'

export const commitKeys: CommitKeyPercent[] = ['feat', 'fix', 'refactor', 'chore', 'others']

export const commitKeyMapping: Record<CommitKeyPercent, CommitKey> = {
    feat: 'feat',
    refactor: 'refactor',
    fix: 'fix',
    chore: 'chore',
    conventional: 'conv',
    others: 'other'
}

type FieldAverageCommits = {
    average: Direction
}

type FieldTotalCommits = {
    totalCommits: Direction
}

export type SortFieldsAverageCommitsOfDevs =
 | FieldAverageCommits
 | FieldTotalCommits
