import { ReactElement, ReactNode } from 'react'

import { Root,Trigger, Portal,  } from '@radix-ui/react-hover-card'

import { StyledContent, StyledArrow } from './styles'

type HoverCardProps = {
    children: ReactNode
    trigger: ReactElement
}

export function HoverCard ({ children, trigger }: HoverCardProps) {
    return (
        <Root openDelay={250} closeDelay={250}>
            <Trigger asChild>{trigger}</Trigger>
            <Portal>
                <StyledContent side='top' align='start' sideOffset={6}>
                    {children} <StyledArrow />
                </StyledContent>
            </Portal>
        </Root>
    )
}
