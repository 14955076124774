import { useState, useCallback } from 'react'

export type Direction = 'asc' | 'desc'

type FieldMemberName = {
    name: Direction
}

type FieldMemberLogin = {
    login: Direction
}

type FieldMemberNameLogin = {
    name: Direction
    login: Direction
}

type FieldMemberEmail = {
    email: Direction
}

type FieldMemberStatus = {
    active: Direction
}

type FieldMemberUpdateAt = {
    updated_at: Direction
}

type FieldMemberCreatedAt = {
    created_at: Direction
}

export type SortFieldsMembers =
    | FieldMemberName
    | FieldMemberLogin
    | FieldMemberNameLogin
    | FieldMemberEmail
    | FieldMemberStatus
    | FieldMemberCreatedAt
    | FieldMemberUpdateAt

export function useSortMembers () {
    const [sort, setSort] = useState<SortFieldsMembers>({ active: 'desc' })

    const handleSort = useCallback((field: string | undefined, direction: Direction) => {
        if (!field) return

        switch (field) {
            case 'memberName':
                setSort({ name: direction })
                break
            case 'memberLogin':
                setSort({ login: direction })
                break
            case 'memberNameLogin':
                setSort({ name: direction, login: direction })
                break
            case 'memberEmail':
                setSort({ email: direction })
                break
            case 'memberStatus':
                setSort({ active: direction })
                break
            case 'memberUpdatedAt':
                setSort({ updated_at: direction })
                break
            case 'memberCreatedAt':
                setSort({ created_at: direction })
                break
            default:
                throw new Error('')
        }
    }, [])

    return { sort, handleSort }
}
