import { ReactNode } from 'react'

import { useMediaQuery } from '@/hooks'

import styled from 'styled-components'

type HiddenProps = {
    media: 'desktop' | 'mobile'
    mode?: 'css' | 'js'
    children: ReactNode
}

const HiddenCss = styled.span`
    &[data-hidden="true"] {
        display: none;
    }
`

const matchs = {
    desktop: '(min-width: 768px)',
    mobile: '(max-width: 768px)'
}

export function Hidden({ children, mode = 'js', media }: HiddenProps) {
    const matchMedia = useMediaQuery(matchs[media])

    if (mode === 'js') {
        return matchMedia ? null : <>{children}</>
    }

    return <HiddenCss data-hidden={`${matchMedia}`}>{children}</HiddenCss>
}
