import { useState, useCallback } from 'react'

import type { SortFieldsRankingMembers, SortDirection } from '@/resources/types'

export function useSortRankingMembers (sortDefault: SortFieldsRankingMembers = { rank: 'asc' }) {
    const [sortRankingMembers, setSortRankingMembers] = useState<SortFieldsRankingMembers>(sortDefault)

    const handleSortRankingMembers = useCallback((field: string | undefined, dir: SortDirection) => {
        if (!field) return;

        switch (field) {
            case 'rankPosition':
                setSortRankingMembers({ rank: dir })
                break
            case 'devName':
                setSortRankingMembers({ member: { login: dir } })
                break
            case 'memberName':
                setSortRankingMembers({ member: { name: dir } })
                break
            case 'productOwnerName':
                setSortRankingMembers({ productOwner: { name: dir }})
                break
            case 'totalCommits':
                setSortRankingMembers({ total: dir })
                break
            case 'conventional':
                setSortRankingMembers({ percentConv: dir })
                break
            case 'commitFeat':
                setSortRankingMembers({ percentFeat: dir })
                break
            case 'commitRefactor':
                setSortRankingMembers({ percentRefactor: dir })
                break
            case 'commitFix':
                setSortRankingMembers({ percentFix: dir })
                break
            case 'commitChore':
                setSortRankingMembers({ percentChore: dir })
                break
            case 'commitsOthers':
                setSortRankingMembers({ percentOther: dir })
                break
            case 'commitMedian':
                setSortRankingMembers({ commitMedian: dir })
                break
            case 'comportamentalMedian':
                setSortRankingMembers({ comportamentalMedian: dir })
                break;
            case 'score':
                setSortRankingMembers({ score: dir })
                break
            default:
                return null
        }
    }, [])

    return {
        sortRankingMembers,
        handleSortRankingMembers
    }
}
