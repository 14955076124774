import styled, { css } from 'styled-components'

export const Select = styled.select`
    ${({ theme }) => css`
        border: 1px solid;
        border-color: ${theme.colors.gray[100]};
        font-size: 14px;
        padding: 12px;
        border-radius: 12px;
        width: 100%;
        background-color: ${theme.colors.white};
    `}
`
